//import { useDebouncedCallback } from 'use-debounce';
import useAxios from 'axios-hooks';
import { isEqual } from 'lodash';
import * as React from 'react';
import Select, { SelectProps } from 'shared/Form/Select/Select';
import { useDebouncedCallback } from 'use-debounce/lib';
import { Merge } from 'utils/typeMerge';

export interface DictionarySelectProps
  extends Merge<SelectProps, { value: any }> {
  url: string;
  is_archived: boolean;
  labelSelector: (any) => any;
  valueSelector: (any) => any;
  defaultSearch: string;
  searchParameter: string;
}

const dummyOption = {
  id: 'dummyOption'
};

export const DictionarySelect: React.FC<Partial<DictionarySelectProps>> = ({
  url,
  defaultSearch = '',
  value,
  onChange,
  labelSelector = x => x?.title,
  valueSelector = x => x?.id,
  searchParameter = 'search',
  ...other
}) => {
  const [search, setSearch] = React.useState(defaultSearch);

  const [{ data }, loadCampains] = useAxios(url, {
    manual: true
  });

  React.useEffect(() => {
    const params = {
      [searchParameter]: search
    };
    if (valueSelector(dummyOption) === dummyOption.id) params.id = value;
    loadCampains({
      params
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const [getData] = useDebouncedCallback(() => {
    loadCampains({
      params: {
        [searchParameter]: search
      }
    });
  }, 1000);

  React.useEffect(() => {
    if (search !== null || search !== undefined) getData();
  }, [search, getData]);

  const handleSearchChange = React.useCallback(
    value => {
      setSearch(value);
    },
    [setSearch]
  );

  const handleChange = React.useCallback(
    value => {
      onChange(valueSelector(value));
    },
    [onChange, valueSelector]
  );

  const getOptionLabel = React.useCallback(value => labelSelector(value), [
    labelSelector
  ]);

  const getOptionValue = React.useCallback(value => valueSelector(value), [
    valueSelector
  ]);

  const options = data?.results || [];

  return (
    <Select
      options={options}
      value={options.find(x => isEqual(valueSelector(x), value))}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      onInputChange={handleSearchChange}
      onChange={handleChange}
      {...other}
    />
  );
};

export default DictionarySelect;
