import cx from 'classnames';
import * as React from 'react';

import styles from './Group.module.scss';

export const GroupRow: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  className,
  ...other
}) => <div className={cx(styles.group_row, className)} {...other} />;

export const GroupColumn: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  className,
  ...other
}) => <div className={cx(styles.group_column, className)} {...other} />;

export const Group: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  className,
  ...other
}) => <div className={cx(styles.group, className)} {...other} />;

export default Group;
