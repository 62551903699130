import { IGlobalState } from 'core/reducers/interface';

export const surveySelector = (state: IGlobalState) => state.survey;

//all data
export const componentsSelector = (state: IGlobalState) =>
  surveySelector(state).surveyComponents.surveyData;

export const currentComponentSelector = (state: IGlobalState) =>
  surveySelector(state).surveyComponents.currentComponent;

export const currentComponentFailureSelector = (state: IGlobalState) =>
  surveySelector(state).surveyComponents.failure;

export const conductorsSelector = (state: IGlobalState) =>
  surveySelector(state).surveyConductors.surveyData;

export const currentConductorSelector = (state: IGlobalState) =>
  surveySelector(state).surveyConductors.currentConductor;

export const currentConductorFailureSelector = (state: IGlobalState) =>
  surveySelector(state).surveyConductors.failure;

export const risersSelector = (state: IGlobalState) =>
  surveySelector(state).surveyRisers.surveyData;

export const currentRiserSelector = (state: IGlobalState) =>
  surveySelector(state).surveyRisers.currentRiser;

export const riserClampsSelector = (state: IGlobalState) =>
  surveySelector(state).surveyRiserClamps.surveyData;

export const currentRiserClampSelector = (state: IGlobalState) =>
  surveySelector(state).surveyRiserClamps.currentRiserClamp;

export const cathodicProtectionSelector = (state: IGlobalState) =>
  surveySelector(state).surveyCathodicProtection.surveyData;

export const currentCPItemSelector = (state: IGlobalState) =>
  surveySelector(state).surveyCathodicProtection.currentCPItem;

export const CPCalibrationSelector = (state: IGlobalState) =>
  surveySelector(state).surveyCPCalibration.surveyData;

export const currentCPCItemSelector = (state: IGlobalState) =>
  surveySelector(state).surveyCPCalibration.currentCPCItem;

export const listISIMSSelector = (state: IGlobalState) =>
  surveySelector(state).surveyISIMS.surveyData;

export const currentISIMSSelector = (state: IGlobalState) =>
  surveySelector(state).surveyISIMS.currentISIMS;

export const isLoadingSelector = (state: IGlobalState) =>
  surveySelector(state).serveyIsLoading;

export const platformPhotosListSelector = (state: IGlobalState) =>
  surveySelector(state).surveyPlatfromPhotos.data;

export const currentPlatfromPhotoSelector = (state: IGlobalState) =>
  surveySelector(state).surveyPlatfromPhotos.current;

export const wellheadsSelector = (state: IGlobalState) =>
  surveySelector(state).surveyWellheads.surveyData;

export const currentWellheadSelector = (state: IGlobalState) =>
  surveySelector(state).surveyWellheads.currentWellhead;

export const currentWellheadFailureSelector = (state: IGlobalState) =>
  surveySelector(state).surveyWellheads.failure;
