// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PasswordField_eye__109Mu {\n  width: 22px;\n  fill: gray;\n}\n.PasswordField_eye_active__6Ujq3 {\n  fill: #328757;\n}", "",{"version":3,"sources":["webpack://src/shared/Form/TextBox/PasswordField.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,UAAA;AACF;AACE;EACE,aAAA;AACJ","sourcesContent":[".eye {\n  width: 22px;\n  fill: gray;\n\n  &_active {\n    fill: #328757;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eye": "PasswordField_eye__109Mu",
	"eye_active": "PasswordField_eye_active__6Ujq3"
};
export default ___CSS_LOADER_EXPORT___;
