import { IGlobalState } from 'core/reducers/interface';

export const assignmentSelector = (state: IGlobalState) => state.assignments;

export const isLoadingSelector = (state: IGlobalState) =>
  assignmentSelector(state).assignmentsIsLoading;

export const assignmentsDataSelector = (state: IGlobalState) =>
  assignmentSelector(state).assignmentsReducer.assignmentsData;

export const assignmentsSelector = (state: IGlobalState) =>
  assignmentSelector(state).assignmentsReducer;
