import { PayloadedAction } from 'core/interfaces/PayloadedAction';
import { combineReducers } from 'redux';
import { createAction } from 'redux-actions';
import { promiseAction } from 'utils/sagaUtils';

import * as constants from './constants';
import { IDirectoriesReducers, IListTypes } from './interfaces';

export const actions = {
  clearDirectories: createAction(constants.CLEAR_DIRECTORIES),
  getTypes: createAction(constants.GET_TYPES),
  getTypesSuccess: createAction(constants.GET_TYPES_SUCCESS),
  getTypesFailure: createAction(constants.GET_TYPES_FAILURE),
  addType: createAction(constants.ADD_TYPE),
  addTypeSuccess: createAction(constants.ADD_TYPE_SUCCESS),
  addTypeFailure: createAction(constants.ADD_TYPE_FAILURE),
  editType: promiseAction(constants.EDIT_TYPE),
  editTypeSuccess: createAction(constants.EDIT_TYPE_SUCCESS),
  editTypeFailure: createAction(constants.EDIT_TYPE_FAILURE),
  deleteType: createAction(constants.DELETE_TYPE),
  deleteTypeSuccess: createAction(constants.DELETE_TYPE_SUCCESS),
  deleteTypeFailure: createAction(constants.DELETE_TYPE_FAILURE)
};

const directoriesIsLoading = (
  state = false,
  action: PayloadedAction
): boolean => {
  switch (action.type) {
    case constants.GET_TYPES:
      return true;
    case constants.GET_TYPES_SUCCESS:
    case constants.GET_TYPES_FAILURE:
      return false;
    default:
      return state;
  }
};

const initialStateDirectories = {
  data: {
    count: 0,
    offset: 0,
    limit: null,
    next: '',
    previous: '',
    results: []
  },
  failure: null,
  loader: false
};

export const directoriesReducer = (
  state = initialStateDirectories,
  action: PayloadedAction
): IListTypes => {
  switch (action.type) {
    case constants.ADD_TYPE:
    case constants.EDIT_TYPE:
      return {
        ...state,
        loader: true
      };
    case constants.GET_TYPES_SUCCESS:
      return {
        ...state,
        data: action.payload
      };
    case constants.ADD_TYPE_SUCCESS:
    case constants.EDIT_TYPE_SUCCESS:
      return {
        ...state,
        loader: false
      };
    case constants.GET_TYPES_FAILURE:
    case constants.EDIT_TYPE_FAILURE:
    case constants.ADD_TYPE_FAILURE:
      return {
        ...state,
        failure: action.payload,
        loader: false
      };
    case constants.CLEAR_DIRECTORIES:
      return {
        ...state,
        data: {
          count: 0,
          offset: 0,
          limit: null,
          next: '',
          previous: '',
          results: []
        },
        failure: null,
        loader: false
      };
    default:
      return state;
  }
};

const directoriesReducers = combineReducers<IDirectoriesReducers>({
  directoriesIsLoading,
  directoriesReducer
});

export default directoriesReducers;
