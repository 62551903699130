// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_header__2xE15 {\n  height: 80px;\n  display: flex;\n  flex-shrink: 0;\n  flex-grow: 0;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 34px;\n  background: linear-gradient(179.06deg, #357450 -21.99%, #3eae6e 177.01%);\n}\n\n.styles_logo__9YfB- {\n  width: 64px;\n}", "",{"version":3,"sources":["webpack://src/app/components/Header/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,cAAA;EACA,YAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,wEAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".header {\n  height: 80px;\n  display: flex;\n  flex-shrink: 0;\n  flex-grow: 0;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 34px;\n  background: linear-gradient(179.06deg, #357450 -21.99%, #3eae6e 177.01%);\n}\n\n.logo {\n  width: 64px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "styles_header__2xE15",
	"logo": "styles_logo__9YfB-"
};
export default ___CSS_LOADER_EXPORT___;
