const STORE_TYPE = 'assignments';

export const GET_ASSIGNMENTS = `${STORE_TYPE}/GET_ASSIGNMENTS`;
export const GET_ASSIGNMENTS_SUCCESS = `${STORE_TYPE}/GET_ASSIGNMENTS_SUCCESS`;
export const GET_ASSIGNMENTS_FAILURE = `${STORE_TYPE}/GET_ASSIGNMENTS_FAILURE`;

export const CLEAR_ASSIGNMENTS = `${STORE_TYPE}/CLEAR_ASSIGNMENTS`;
export const CLEAR_ASSIGNMENT = `${STORE_TYPE}/CLEAR_ASSIGNMENT`;

export const GET_ASSIGNMENT = `${STORE_TYPE}/GET_ASSIGNMENT`;
export const GET_ASSIGNMENT_SUCCESS = `${STORE_TYPE}/GET_ASSIGNMENT_SUCCESS`;
export const GET_ASSIGNMENT_FAILURE = `${STORE_TYPE}/GET_ASSIGNMENT_FAILURE`;

export const EDIT_ASSIGNMENT = `${STORE_TYPE}/EDIT_ASSIGNMENT`;
export const EDIT_ASSIGNMENT_SUCCESS = `${STORE_TYPE}/EDIT_ASSIGNMENT_SUCCESS`;
export const EDIT_ASSIGNMENT_FAILURE = `${STORE_TYPE}/EDIT_ASSIGNMENT_FAILURE`;

export const ADD_ASSIGNMENT = `${STORE_TYPE}/ADD_ASSIGNMENT`;
export const ADD_ASSIGNMENT_SUCCESS = `${STORE_TYPE}/ADD_ASSIGNMENT_SUCCESS`;
export const ADD_ASSIGNMENT_FAILURE = `${STORE_TYPE}/ADD_ASSIGNMENT_FAILURE`;

export const DELETE_ASSIGNMENT = `${STORE_TYPE}/DELETE_ASSIGNMENT`;
export const DELETE_ASSIGNMENT_SUCCESS = `${STORE_TYPE}/DELETE_ASSIGNMENT_SUCCESS`;
export const DELETE_ASSIGNMENT_FAILURE = `${STORE_TYPE}/DELETE_ASSIGNMENT_FAILURE`;
