import { IGlobalState } from 'core/reducers/interface';

export const platformsSelector = (state: IGlobalState) => state.platforms;

//platforms
export const listPatformsSelector = (state: IGlobalState) =>
  platformsSelector(state).platformsReducer.platformsData;

export const PatformSelector = (state: IGlobalState) =>
  platformsSelector(state).platformReducer;

//edit platform
export const editPatformSelector = (state: IGlobalState) =>
  platformsSelector(state).editPlatformReducer;

// loader
export const isLoadingPlatformsSelector = (state: IGlobalState) =>
  platformsSelector(state).platformsIsLoading;

//pipelines
export const pipelinesSelector = (state: IGlobalState) =>
  platformsSelector(state).pipelinesReducer;

export const pipelineSelector = (state: IGlobalState) =>
  platformsSelector(state).pipelinesReducer.pipelineData;

//wells
export const wellSelector = (state: IGlobalState) =>
  platformsSelector(state).wellsReducer;

export const wellsSelector = (state: IGlobalState) =>
  platformsSelector(state).wellsReducer.wellsData;

//wells

export const workOrdersSelector = (state: IGlobalState) =>
  platformsSelector(state).workOrders;

export const workOrdersListSelector = (state: IGlobalState) =>
  workOrdersSelector(state).list;

export const workOrderSelector = (state: IGlobalState) =>
  workOrdersSelector(state).current;

//well slots
export const wellSlotSelector = (state: IGlobalState) =>
  platformsSelector(state).wellSlotsReducer;

export const wellSlotsSelector = (state: IGlobalState) =>
  platformsSelector(state).wellSlotsReducer.wellSlotsData;

//reports
export const reportsSelector = (state: IGlobalState) =>
  platformsSelector(state).reports;

export const reportsListSelector = (state: IGlobalState) =>
  reportsSelector(state).list;

export const reportSelector = (state: IGlobalState) =>
  reportsSelector(state).current;

//ratings
export const ratingsSelector = (state: IGlobalState) =>
  platformsSelector(state).ratingsReducer;

export const ratingsDataSelector = (state: IGlobalState) =>
  ratingsSelector(state);

export const platformRatingLoaderSelector = (state: IGlobalState) =>
  ratingsSelector(state).loader;

//analytics
export const analyticsSelector = (state: IGlobalState) =>
  platformsSelector(state).analyticsReducer;

// Platform statuses
export const platformStatusesSelector = (state: IGlobalState) =>
  platformsSelector(state).platformStatusesReducer;

export const platformStatusesDataSelector = (state: IGlobalState) =>
  platformStatusesSelector(state);

export const platformStatusesLoaderSelector = (state: IGlobalState) =>
  platformStatusesSelector(state).loader;
