import { PayloadedAction } from 'core/interfaces/PayloadedAction';
import { combineReducers } from 'redux';
import { createAction } from 'redux-actions';

import * as constants from './constants';
import {
  IAppReducer,
  ILoginReducer,
  IPopUpReducer,
  IRecoveryReducer
} from './interface';

export const actions = {
  fetchMe: createAction(constants.FETCH_ME),
  fetchMeSuccess: createAction(constants.FETCH_ME_SUCCESS),
  fetchMeFailure: createAction(constants.FETCH_ME_FAILURE),
  logIn: createAction(constants.LOG_IN),
  logInSuccess: createAction(constants.LOG_IN_SUCCESS),
  logInFailure: createAction(constants.LOG_IN_FAILURE),
  logOut: createAction(constants.LOG_OUT),
  showRecovery: createAction(constants.SHOW_RECOVERY),
  getRecoveryCode: createAction(constants.GET_RECOVERY_CODE),
  getRecoveryCodeSuccess: createAction(constants.GET_RECOVERY_CODE_SUCCESS),
  getRecoveryCodeFailure: createAction(constants.GET_RECOVERY_CODE_FAILURE),
  sendRecoveryCode: createAction(constants.SEND_RECOVERY_CODE),
  sendRecoveryCodeSuccess: createAction(constants.SEND_RECOVERY_CODE_SUCCESS),
  sendRecoveryCodeFailure: createAction(constants.SEND_RECOVERY_CODE_FAILURE),
  finishRecovery: createAction(constants.FINISH_RECOVERY),
  finishRecoverySuccess: createAction(constants.FINISH_RECOVERY_SUCCESS),
  finishRecoveryFailure: createAction(constants.FINISH_RECOVERY_FAILURE),
  backToLogin: createAction(constants.BACK_TO_LOGIN),
  toggleFilterPopup: createAction(constants.TOGGLE_FILTER_POPUP)
};

const initialStatePopUp = {
  isOpen: false
};

export const popUpReducer = (
  state = initialStatePopUp,
  action: PayloadedAction
): IPopUpReducer => {
  switch (action.type) {
    case constants.TOGGLE_FILTER_POPUP:
      return {
        ...state,
        isOpen: action.payload
      };
    default:
      return state;
  }
};

const initialStateLogin = {
  isAuth: null,
  loader: false,
  success: {
    refresh: '',
    access: '',
    user: null
  },
  failure: null
};

export const loginReducer = (
  state = initialStateLogin,
  action: PayloadedAction
): ILoginReducer => {
  switch (action.type) {
    case constants.FETCH_ME_SUCCESS:
      return {
        ...state,
        isAuth: true,
        success: { ...state.success, user: action.payload }
      };
    case constants.FETCH_ME_FAILURE:
      localStorage.removeItem('GIRToken');
      return { ...state, isAuth: false };
    case constants.LOG_IN:
      return {
        ...state,
        loader: true
      };
    case constants.LOG_IN_SUCCESS:
      localStorage.setItem('GIRToken', action.payload.access);
      localStorage.setItem('GIRRefreshToken', action.payload.refresh);
      return {
        ...state,
        isAuth: true,
        success: action.payload,
        loader: false,
        failure: null
      };

    case constants.LOG_IN_FAILURE:
      return {
        ...state,
        failure: action.payload,
        loader: false
      };

    case constants.BACK_TO_LOGIN:
      return {
        ...state,
        failure: null
      };
    case constants.LOG_OUT:
      localStorage.removeItem('GIRToken');
      localStorage.removeItem('GIRRefreshToken');

      return {
        ...state,
        isAuth: false
      };
    default:
      return state;
  }
};

const initialStateRecovery = {
  loader: false,
  email: null,
  successGetCodeRecovery: null,
  failureGetCodeRecovery: null,
  successSendCodeRecovery: null,
  failureSendCodeRecovery: null,
  successFinishRecovery: null,
  failureFinishRecovery: null,
  recoveryStep: 1,
  recoveryView: false
};

export const recoveryReducer = (
  state = initialStateRecovery,
  action: PayloadedAction
): IRecoveryReducer => {
  switch (action.type) {
    case constants.SHOW_RECOVERY:
      return {
        ...state,
        recoveryView: true,
        recoveryStep: 1,
        failureGetCodeRecovery: null,
        failureSendCodeRecovery: null,
        failureFinishRecovery: null
      };
    case constants.BACK_TO_LOGIN:
      return {
        ...state,
        recoveryView: false
      };

    case constants.GET_RECOVERY_CODE:
      return {
        ...state,
        loader: true,
        email: action.payload.email
      };
    case constants.GET_RECOVERY_CODE_SUCCESS:
      return {
        ...state,
        successGetCodeRecovery: action.payload,
        loader: false,
        recoveryStep: 2,
        failureGetCodeRecovery: null
      };

    case constants.GET_RECOVERY_CODE_FAILURE:
      return {
        ...state,
        failureGetCodeRecovery: action.payload,
        loader: false,
        recoveryStep: 1
      };

    case constants.SEND_RECOVERY_CODE:
      return {
        ...state,
        loader: true
      };
    case constants.SEND_RECOVERY_CODE_SUCCESS:
      return {
        ...state,
        successSendCodeRecovery: action.payload,
        loader: false,
        recoveryStep: 3,
        failureSendCodeRecovery: null
      };

    case constants.SEND_RECOVERY_CODE_FAILURE:
      return {
        ...state,
        failureSendCodeRecovery: action.payload,
        loader: false,
        recoveryStep: 2
      };

    case constants.FINISH_RECOVERY:
      return {
        ...state,
        loader: true
      };
    case constants.FINISH_RECOVERY_SUCCESS:
      return {
        ...state,
        successFinishRecovery: action.payload,
        loader: false,
        recoveryStep: 1,
        recoveryView: false,
        failureFinishRecovery: null,
        successSendCodeRecovery: null,
        email: null
      };

    case constants.FINISH_RECOVERY_FAILURE:
      return {
        ...state,
        failureFinishRecovery: action.payload,
        loader: false
      };

    default:
      return state;
  }
};

const appReducers = combineReducers<IAppReducer>({
  loginReducer,
  recoveryReducer,
  popUpReducer
});

export default appReducers;
