const surveys = [
  {
    id: 1,
    name: 'Components',
    path: '/surveys/components',
    idSlug: 'surveys_components'
  },
  {
    id: 2,
    name: 'Conductors',
    path: '/surveys/conductors',
    idSlug: 'surveys_conductors'
  },
  {
    id: 3,
    name: 'Wellheads',
    path: '/surveys/wellheads',
    idSlug: 'surveys_wellheads'
  },
  { id: 4, name: 'Risers', path: '/surveys/risers', idSlug: 'surveys_risers' },
  {
    id: 5,
    name: 'Riser Clamps',
    path: '/surveys/riserClamps',
    idSlug: 'surveys_riserClamps'
  },
  {
    id: 6,
    name: 'Cathodic Protection',
    path: '/surveys/cathodicProtection',
    idSlug: 'surveys_cathodicProtection'
  },
  {
    id: 7,
    name: 'CP Calibration',
    path: '/surveys/CPCalibration',
    idSlug: 'surveys_CPCalibration'
  },
  { id: 8, name: 'ISIMS', path: '/surveys/ISIMS', idSlug: 'surveys_ISIMS' },
  {
    id: 9,
    name: 'Platform photos',
    path: '/surveys/platformPhotos',
    idSlug: 'surveys_platformPhotos'
  }
];

const customers = [
  {
    id: 1,
    name: 'Active',
    path: '/customers/active',
    idSlug: 'customers_active'
  },
  {
    id: 2,
    name: 'Archive',
    path: '/customers/archive',

    idSlug: 'customers_archive'
  }
];

const platforms = [
  {
    id: 1,
    name: 'Analytics',
    path: '/platforms/analytics',
    idSlug: 'platforms_analytics'
  },

  {
    id: 2,
    name: 'Active',
    path: '/platforms/active',
    idSlug: 'platforms_active'
  },
  {
    id: 3,
    name: 'Archive',
    path: '/platforms/archive',
    idSlug: 'platforms_archive'
  }
];

const users = [
  {
    id: 1,
    name: 'Active',
    path: '/users/active',
    idSlug: 'users_active'
  },
  { id: 2, name: 'Archive', path: '/users/archive', idSlug: 'users_archive' }
];

const campaigns = [
  {
    id: 1,
    name: 'Active',
    path: '/inspectionCampaigns/active',
    idSlug: 'inspectionCampaigns_active'
  },
  {
    id: 2,
    name: 'Archive',
    path: '/inspectionCampaigns/archive',
    idSlug: 'inspectionCampaigns_archive'
  }
];

export const menu = [
  {
    id: 1,
    name: 'Platforms',
    subUl: platforms,
    path: '/platforms',
    idSlug: 'platforms'
  },
  {
    id: 2,
    name: 'Surveys',
    subUl: surveys,
    path: '/surveys',
    idSlug: 'surveys'
  },
  {
    id: 3,
    name: 'Inspection campaigns',
    subUl: campaigns,
    path: '/inspectionCampaigns',
    idSlug: 'inspectionCampaigns',
    roles: ['admin']
  },
  {
    id: 4,
    name: 'Customers',
    subUl: customers,
    path: '/customers',
    idSlug: 'customers',
    roles: ['admin', 'engineer']
  },
  {
    id: 5,
    name: 'Assignments',
    subUl: null,
    path: '/assignments',
    idSlug: 'assignments',
    roles: ['admin']
  },
  {
    id: 6,
    name: 'Directories',
    subUl: null,
    path: '/directories',
    idSlug: 'directories',
    roles: ['admin']
  },
  {
    id: 7,
    name: 'Users',
    subUl: users,
    path: '/users',
    idSlug: 'users',
    roles: ['admin']
  }
];
