import cx from 'classnames';
import * as React from 'react';

import styles from './styles.module.scss';

export interface IProps {
  is_archived: boolean;
  togglePopup: (value: boolean) => any;
  activateUser: () => void;
  archiveUser: () => void;
}

class UsersPopUp extends React.Component<IProps> {
  handleClick = () => {
    const { togglePopup } = this.props;
    togglePopup(false);
  };

  render() {
    const { is_archived, activateUser, archiveUser } = this.props;

    return (
      <div className={styles.modalContent}>
        <div className={styles.title}>
          {is_archived ? 'Activate' : 'Archive'}
        </div>
        <div className={styles.subTitle}>
          Are you sure you want to {is_archived ? 'activate' : 'archive'} this
          user?
        </div>

        <div className={styles.buttonsBlock}>
          <div className={styles.cancelButton} onClick={this.handleClick}>
            CANCEL
          </div>
          <div
            className={cx(styles.submitButton, {
              [styles.submitButton_active]: is_archived
            })}
            onClick={is_archived ? activateUser : archiveUser}
          >
            {is_archived ? 'ACTIVATE' : 'ARCHIVE'}
          </div>
        </div>
      </div>
    );
  }
}

export default UsersPopUp;
