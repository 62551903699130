import { userInfoSelector } from 'app/containers/App/store/selectors';
import { push } from 'connected-react-router';
import { api } from 'core/config/api';
import ReportDownloadAction from 'platforms/containers/PlatformComponent/containers/PlatformReports/ReportDownloadAction';
import WorkOrderDownloadAction from 'platforms/containers/PlatformComponent/containers/PlatformWorkOrders/WorkOrderDownloadAction';
import * as React from 'react';
import { toastr } from 'react-redux-toastr';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { catchError } from 'utils/sagaUtils';

import * as constants from './constants';
import { actions } from './duck';
import {
  pipelineSelector,
  listPatformsSelector,
  wellSlotsSelector,
  wellsSelector,
  workOrdersSelector,
} from './selectors';

//Ratings
export function* fetchRatingsWorker(action) {
  const { id } = action.payload;

  try {
    const fetchGetratings = () =>
      api
        .get(`platforms/ratings/?platform=${id}`, { params: { limit: 9999 } })
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchGetratings);

    yield put(actions.getRatingsSuccess(result));
  } catch (error) {
    yield put(actions.getRatingsFailure(error));
  }
}

//edit rating
export function* fetchEditRatingsWorker(action) {
  const {
    payload: { id, data },
    meta: { resolve, reject },
  } = action;

  const newData = data?.map((obj) => {
    const year = obj.year;
    obj.year =
      year && typeof year === 'number'
        ? year
        : year && year.isValid()
        ? Number(year.format('YYYY'))
        : null;
    obj.platform = id;
    return obj;
  });

  try {
    const fetchEditType = () =>
      api
        .post(`platforms/ratings/`, {
          data: newData,
        })
        .then((response) => response.data)
        .catch((error) => {
          if (error.message === 'Network Error') {
            throw new Error('No internet connection');
          }
          switch (error.response.status) {
            case 400:
            case 401:
            case 403:
            case 429:
              throw { data: error.response.data?.data || error.response.data };
            case 503:
            case 500:
              throw { detail: 'Server error.' };
            default:
              break;
          }
        });

    const result = yield call(fetchEditType);

    yield put(actions.editRatingsSuccess(result));

    toastr.success('', 'Data has been changed');
    yield call(resolve);

    yield put(actions.getRatings({ id }));
  } catch (error) {
    yield put(actions.editRatingsFailure(error));
    yield call(reject, error);
  }
}

// Edit platform statuses
export function* editStatusesWorker(action) {
  const {
    payload: { id, data },
    meta: { resolve, reject },
  } = action;

  const newData = data?.map((obj) => {
    obj.platform = id;
    return obj;
  });

  try {
    const editStatusesApi = () =>
      api
        .post(`platforms/progress_statuses/`, {
          data: newData,
        })
        .then((response) => response.data)
        .catch((error) => {
          if (error.message === 'Network Error') {
            throw new Error('No internet connection');
          }
          switch (error.response.status) {
            case 400:
            case 401:
            case 403:
            case 429:
              throw { data: error.response.data?.data || error.response.data };
            case 503:
            case 500:
              throw { detail: 'Server error.' };
            default:
              break;
          }
        });

    const result = yield call(editStatusesApi);

    yield put(actions.editStatusesSuccess(result));

    toastr.success('', 'Data has been changed');
    yield call(resolve);

    yield put(actions.getPlatformStatuses({ id }));
  } catch (error) {
    yield put(actions.editStatusesFailure(error));
    yield call(reject, error);
  }
}

// Platform Statuses
export function* fetchStatusesWorker(action) {
  const { id } = action.payload;

  try {
    const fetchStatuses = () =>
      api
        .get(`platforms/progress_statuses/?platform=${id}`, {
          params: { limit: 9999 },
        })
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchStatuses);

    yield put(actions.getPlatformStatusesSuccess(result));
  } catch (error) {
    yield put(actions.getPlatformStatusesFailure(error));
  }
}

//platforms
export function* fetchPlatformsWorker(action) {
  const {
    sortBy,
    search,
    limit,
    filter,
    offset,
    customer,
    is_archived,
    campaign,
    geo_area,
  } = action.payload;

  const {
    search: prevSearch,
    sortBy: prevSortBy,
    limit: prevLimit,
    offset: prevOffet,
    filter: prevFilter,
  } = yield select(listPatformsSelector);

  const { installed, ...other_filters } = filter ?? prevFilter ?? {};

  let queryParams = {
    geo_area,
    is_archived,
    customer: customer ?? null,
    campaign: campaign ?? null,
    search: search ?? prevSearch,
    orderBy: sortBy ?? prevSortBy,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0,
    installed__range: installed
      ? `${installed.format('YYYY-01-01')},${installed.format('YYYY-12-31')}`
      : '',
    ...other_filters,
  };

  try {
    const fetchGetPlatforms = () =>
      api
        .get('/platforms/', {
          params: { ...queryParams },
        })
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchGetPlatforms);

    yield put(
      actions.getPlatformsSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        search: queryParams.search,
        sortBy: queryParams.orderBy,
        filter: filter ?? prevFilter ?? {},
      })
    );
  } catch (error) {
    yield put(actions.getPlatformsFailure(error));
  }
}

//platform
function* fetchPlatformWorker(action) {
  const {
    payload: { id },
  } = action;

  try {
    const fetchPlatform = () =>
      api
        .get(`/platforms/${id}/`)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchPlatform);

    yield put(actions.getPlatformSuccess(result));
  } catch (error) {
    yield put(actions.getPlatformFailure(error));
  }
}

//edit platform informations
export function* fetchEditPlatformInformationWorker(action) {
  const {
    payload: { id, url, scheme, bsee_report, frontend_report, ...other },
    meta: { resolve, reject },
  } = action;

  let body = other;

  if (!scheme && !bsee_report && !frontend_report) {
    body.scheme = null;
    body.bsee_report = null;
    body.frontend_report = null;
  } else {
    const formData = new FormData();

    const setFormValue = (name: string, value: any) => {
      if (typeof value !== 'string') {
        if (typeof value === 'undefined' || value === null) {
          formData.set(name, '');
        } else {
          formData.set(name, value);
        }
      }
    };

    setFormValue('scheme', scheme);
    setFormValue('bsee_report', bsee_report);
    setFormValue('frontend_report', frontend_report);

    body = formData as any;
  }

  try {
    const fetchEditPlatformInformation = () =>
      api
        .patch(`/platforms/${id}/`, body)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchEditPlatformInformation);

    yield put(actions.editPlatformInformationSuccess(result));
    yield call(resolve);

    toastr.success('', 'Data has been changed');
    yield put(push(url));

    yield put(actions.getPlatform({ id }));
    yield put(actions.getPlatforms({}));
  } catch (error) {
    yield put(actions.editPlatformInformationFailure(error));
    yield call(reject, error);
  }
}

//add platform
export function* fetchAddPlatformWorker(action) {
  const {
    payload: { ...values },
  } = action;

  try {
    const fetchAddPlatform = () =>
      api
        .post(`/platforms/`, {
          ...values,
        })
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchAddPlatform);

    yield put(actions.addPlatformSuccess(result));

    toastr.success('', 'Data has been changed');
    yield put(push(`/platforms/active`));
  } catch (error) {
    yield put(actions.addPlatformFailure(error));
  }
}

//Pipelines
export function* fetchPipelinesWorker(action) {
  const { search, limit, offset, platform } = action.payload;

  const {
    search: prevSearch,
    limit: prevLimit,
    offset: prevOffet,
  } = yield select(pipelineSelector);

  let queryParams = {
    platform,
    search: search ?? prevSearch,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0,
  };

  try {
    const fetchGetPipelines = () =>
      api
        .get('/platforms/pipelines/', {
          params: { ...queryParams },
        })
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchGetPipelines);

    yield put(
      actions.getPipelinesSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        search: queryParams.search,
      })
    );
  } catch (error) {
    yield put(actions.getPipelinesFailure(error));
  }
}

//Pipeline
function* fetchPipelineWorker(action) {
  const {
    payload: { id },
  } = action;

  try {
    const fetchPipeline = () =>
      api
        .get(`/platforms/pipelines/${id}/`)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchPipeline);

    yield put(actions.getPipelineSuccess(result));
  } catch (error) {
    yield put(actions.getPipelineFailure(error));
  }
}

//edit pipeline
export function* fetchEditPipelineWorker(action) {
  const {
    payload: { id, idPlatform, ...other },
  } = action;

  try {
    const fetchEditPipeline = () =>
      api
        .patch(`/platforms/pipelines/${id}/`, {
          ...other,
        })
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchEditPipeline);

    yield put(actions.editPipelineSuccess(result));

    toastr.success('', 'Data has been changed');

    yield put(push(`/platforms/active/${idPlatform}/pipelines/`));
  } catch (error) {
    yield put(actions.editPipelineFailure(error));
  }
}

//add pipeline
export function* fetchAddPipelineWorker(action) {
  const {
    payload: { idPlatform, ...values },
  } = action;

  try {
    const fetchAddPipeline = () =>
      api
        .post(`/platforms/pipelines/`, {
          ...values,
        })
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchAddPipeline);

    yield put(actions.addPipelineSuccess(result));

    toastr.success('', 'Data has been changed');
    yield put(push(`/platforms/active/${idPlatform}/pipelines/`));
  } catch (error) {
    yield put(actions.addPipelineFailure(error));
  }
}

//Wells
export function* fetchWellsWorker(action) {
  const { search, limit, offset, platform } = action.payload;

  const {
    search: prevSearch,
    limit: prevLimit,
    offset: prevOffet,
  } = yield select(wellsSelector);

  let queryParams = {
    platform,
    search: search ?? prevSearch,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0,
  };

  try {
    const fetchGetWells = () =>
      api
        .get('/platforms/wells/', {
          params: { ...queryParams },
        })
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchGetWells);

    yield put(
      actions.getWellsSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        search: queryParams.search,
      })
    );
  } catch (error) {
    yield put(actions.getWellsFailure(error));
  }
}

//Well
function* fetchWellWorker(action) {
  const {
    payload: { id },
  } = action;

  try {
    const fetchWell = () =>
      api
        .get(`/platforms/wells/${id}/`)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchWell);

    yield put(actions.getWellSuccess(result));
  } catch (error) {
    yield put(actions.getWellFailure(error));
  }
}

//edit well
export function* fetchEditWellWorker(action) {
  const {
    payload: { id, idPlatform, ...other },
  } = action;

  try {
    const fetchEditWell = () =>
      api
        .patch(`/platforms/wells/${id}/`, {
          ...other,
        })
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchEditWell);

    yield put(actions.editWellSuccess(result));

    toastr.success('', 'Data has been changed');

    yield put(push(`/platforms/active/${idPlatform}/wells/`));
  } catch (error) {
    yield put(actions.editWellFailure(error));
  }
}

//add well
export function* fetchAddWellWorker(action) {
  const {
    payload: { idPlatform, ...values },
  } = action;

  try {
    const fetchAddWell = () =>
      api
        .post(`/platforms/wells/`, {
          ...values,
        })
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchAddWell);

    yield put(actions.addWellSuccess(result));

    toastr.success('', 'Data has been changed');
    yield put(push(`/platforms/active/${idPlatform}/wells/`));
  } catch (error) {
    yield put(actions.addWellFailure(error));
  }
}

//WorkOrders
export function* fetchWorkOrdersWorker(action) {
  const { search, limit, offset, platform } = action.payload;

  const {
    search: prevSearch,
    limit: prevLimit,
    offset: prevOffet,
  } = yield select(workOrdersSelector);

  let queryParams = {
    platform,
    search: search ?? prevSearch,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0,
  };

  try {
    const fetch = () =>
      api
        .get('/reports/work_orders/', {
          params: { ...queryParams },
        })
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetch);

    yield put(
      actions.getWorkOrdersSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        search: queryParams.search,
      })
    );
  } catch (error) {
    yield put(actions.getWorkOrdersFailure(error));
  }
}

export const pdfSettings = {
  scale: 1,
  format: 'letter',
  margin: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
};

export function* createWorkOrderWorker(action) {
  const {
    payload: { report_data, title, platform, file = null },
    meta: { resolve, reject },
  } = action;

  const userInfo = yield select(userInfoSelector);

  let body = {
    report_data,
    url: `${window.location.origin}/reports/work_orders`,
    access_token: localStorage.getItem('GIRToken').replace(/"/g, ''),
    creator: userInfo.id,
    platform,
    title,
    ...pdfSettings,
  };

  const { report_data: rep_data, scale, margin, format, ...restBody } = body;

  if (file) {
    const formData = new FormData();
    Object.entries(restBody).forEach(([key, value]) =>
      formData.append(
        key,
        typeof value == 'string' ? value : JSON.stringify(value)
      )
    );
    formData.append('file', file);
    body = formData as any;
  }

  try {
    const create = () =>
      api
        .post(`/reports/work_orders/`, body)
        .then((response) => response.data)
        .catch(catchError);

    const edit = (id: number) =>
      api
        .patch(`/reports/work_orders/${id}/`, body)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(create);

    yield put(actions.createWorkOrderSuccess(result));

    if (file) {
      const res = yield call(() => edit(result.id));
      yield put(actions.editWorkOrderSuccess(res));
    }

    yield call(resolve);
    yield put(push(`/platforms/active/${platform}/orders`));
    toastr.success('', 'The work order has been created.', {
      component: <WorkOrderDownloadAction {...result} />,
    });
  } catch (error) {
    yield put(actions.createWorkOrderFailure(error));
    yield call(reject, error);
  }
}

export function* editWorkOrderWorker(action) {
  const {
    payload: {
      data: { file, ...data },
      report_data,
    },
    meta: { resolve, reject },
  } = action;

  try {
    const edit = () =>
      api
        .patch(`/reports/work_orders/${data.id}/`, {
          ...data,
          report_data,
          url: `${window.location.origin}/reports/work_orders`,
          access_token: localStorage.getItem('GIRToken').replace(/"/g, ''),
          title: report_data.title,
          creator: data.creator?.id,
          ...pdfSettings,
        })
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(edit);

    yield put(actions.editWorkOrderSuccess(result));
    yield put(actions.getWorkOrder({ id: data.id }));
    yield call(resolve);

    yield put(
      push(
        `/platforms/active/${
          data?.report_data?.platform?.id || report_data?.platform?.id
        }/orders`
      )
    );

    toastr.success('', 'The work order has been saved.', {
      component: <WorkOrderDownloadAction {...result} />,
    });
  } catch (error) {
    yield put(actions.editWorkOrderFailure(error));
    yield call(reject, error);
  }
}

export function* getWorkOrderWorker(action) {
  const {
    payload: { id },
  } = action;

  try {
    const fetch = () =>
      api
        .get(`/reports/work_orders/${id}/`)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetch);

    yield put(actions.getWorkOrderSuccess(result));
  } catch (error) {
    yield put(actions.getWorkOrderFailure(error));
  }
}

//Well slots
export function* fetchWellSlotsWorker(action) {
  const { search, limit, offset, platform } = action.payload;

  const {
    search: prevSearch,
    limit: prevLimit,
    offset: prevOffet,
  } = yield select(wellSlotsSelector);

  let queryParams = {
    platform,
    search: search ?? prevSearch,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0,
  };

  try {
    const fetchGetWellSlots = () =>
      api
        .get('/platforms/well_slots/', {
          params: { ...queryParams },
        })
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchGetWellSlots);

    yield put(
      actions.getWellSlotsSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        search: queryParams.search,
      })
    );
  } catch (error) {
    yield put(actions.getWellSlotsFailure(error));
  }
}

//Well slot
function* fetchWellSlotWorker(action) {
  const {
    payload: { id },
  } = action;

  try {
    const fetchWellSlot = () =>
      api
        .get(`/platforms/well_slots/${id}/`)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchWellSlot);

    yield put(actions.getWellSlotSuccess(result));
  } catch (error) {
    yield put(actions.getWellSlotFailure(error));
  }
}

//edit well slot
export function* fetchEditWellSlotWorker(action) {
  const {
    payload: { id, idPlatform, ...other },
  } = action;

  try {
    const fetchEditWellSlot = () =>
      api
        .patch(`/platforms/well_slots/${id}/`, {
          ...other,
        })
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchEditWellSlot);

    yield put(actions.editWellSlotSuccess(result));

    toastr.success('', 'Data has been changed');

    yield put(push(`/platforms/active/${idPlatform}/well_slots/`));
  } catch (error) {
    yield put(actions.editWellSlotFailure(error));
  }
}

//add well slot
export function* fetchAddWellSlotWorker(action) {
  const {
    payload: { idPlatform, ...values },
  } = action;

  try {
    const fetchAddWellSlot = () =>
      api
        .post(`/platforms/well_slots/`, {
          ...values,
        })
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchAddWellSlot);

    yield put(actions.addWellSlotSuccess(result));

    toastr.success('', 'Data has been changed');
    yield put(push(`/platforms/active/${idPlatform}/well_slots/`));
  } catch (error) {
    yield put(actions.addWellSlotFailure(error));
  }
}

//Reports
export function* fetchReportsWorker(action) {
  const { search, limit, offset, platform } = action.payload;

  const {
    search: prevSearch,
    limit: prevLimit,
    offset: prevOffet,
  } = yield select(workOrdersSelector);

  let queryParams = {
    platform,
    search: search ?? prevSearch,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0,
  };

  try {
    const fetch = () =>
      api
        .get('/reports/reports/', {
          params: { ...queryParams },
        })
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetch);

    yield put(
      actions.getReportsSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        search: queryParams.search,
      })
    );
  } catch (error) {
    yield put(actions.getReportsFailure(error));
  }
}

export function* getReportWorker(action) {
  const {
    payload: { id },
  } = action;

  try {
    const fetch = () =>
      api
        .get(`/reports/reports/${id}/`)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetch);

    yield put(actions.getReportSuccess(result));
  } catch (error) {
    yield put(actions.getReportFailure(error));
  }
}

export function* createReportWorker(action) {
  const {
    payload: { scheme, schemePreview, file, ...report },
    meta: { resolve, reject },
  } = action;
  const userInfo = yield select(userInfoSelector);

  let body = {};

  if (file) {
    const formData = new FormData();
    const {
      date_created,
      title,
      campaign,
      platform: { id },
    } = report;
    body = {
      title,
      campaign,
      platform: id,
      date_created,
      creator: userInfo.id,
      url: `${window.location.origin}/reports/reports`,
      access_token: localStorage.getItem('GIRToken').replace(/"/g, ''),
    };
    Object.entries(body).forEach(([key, value]) =>
      formData.append(
        key,
        typeof value == 'string' ? value : JSON.stringify(value)
      )
    );
    formData.append('file', file);
    body = formData as any;
  } else {
    body = {
      report_data: report,
      url: `${window.location.origin}/reports/reports`,
      access_token: localStorage.getItem('GIRToken').replace(/"/g, ''),
      creator: userInfo.id,
      platform: report.platform.id,
      title: report.title,
      campaign: report.campaignId,
      ...pdfSettings,
      format: 'letter',
    };

    if (scheme?.name != null) {
      const formData = new FormData();
      Object.entries(body).forEach(([key, value]) =>
        formData.append(
          key,
          typeof value == 'string' ? value : JSON.stringify(value)
        )
      );
      formData.append('scheme', scheme);
      body = formData as any;
    }
  }

  try {
    const create = () =>
      api
        .post(`/reports/reports/`, body)
        .then((response) => response.data)
        .catch(catchError);

    const edit = (id: number) =>
      api
        .patch(`/reports/reports/${id}/`, body)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(create);

    yield put(actions.createWorkOrderSuccess(result));

    if (file) {
      const res = yield call(() => edit(result.id));
      yield put(actions.editReportSuccess(res));
    }

    yield call(resolve);
    yield put(push(`/platforms/active/${report.platform.id}/reports`));

    toastr.success('', 'The report has been created.', {
      component: <ReportDownloadAction {...result} />,
    });
  } catch (error) {
    yield put(actions.createWorkOrderFailure(error));
    yield call(reject, error);
  }
}

export function* deleteReportWorker(action) {
  const {
    payload: {
      id,
      platform: { id: platformId },
    },
  } = action;
  const { search, limit, offset } = yield select(workOrdersSelector);
  const deleteReport = () =>
    api
      .delete(`reports/reports/${id}/`)
      .then((response) => response.data)
      .catch(catchError);

  try {
    yield call(deleteReport);
    yield put(actions.deleteReportSuccess());
    toastr.success('', 'Report deleted successfully.');
    yield put(
      actions.getReports({ search, limit, offset, platform: platformId })
    );
  } catch (error) {
    yield put(actions.deleteReportFailure());
    toastr.error('', 'Error deleting error.');
  }
}

export function* editReportWorker(action) {
  const {
    payload: {
      data: { scheme: originalScheme, file, ...data },
      report_data: { scheme, schemePreview, ...report },
    },
    meta: { resolve, reject },
  } = action;

  try {
    let body = {
      ...data,
      report_data: report,
      url: window.location.origin + `/reports/reports/`,
      access_token: localStorage.getItem('GIRToken').replace(/"/g, ''),
      title: report.title,
      creator: data?.creator?.id,
      platform: report.platform.id,
      campaign: report.campaignId,
      ...pdfSettings,
    };

    if (scheme?.name != null) {
      const formData = new FormData();
      Object.entries(body).forEach(([key, value]) =>
        formData.append(
          key,
          typeof value == 'string' ? value : JSON.stringify(value)
        )
      );
      formData.append('scheme', scheme);
      body = formData;
    }

    const edit = () =>
      api
        .patch(`/reports/reports/${data.id}/`, body)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(edit);

    yield put(actions.editReportSuccess(result));
    yield put(actions.getReport({ id: data.id }));
    yield call(resolve);

    yield put(
      push(
        `/platforms/active/${
          data?.report_data?.platform?.id || report?.platform?.id
        }/reports`
      )
    );

    toastr.success('', 'The report has been saved.', {
      component: <ReportDownloadAction {...result} />,
    });
  } catch (error) {
    yield put(actions.editReportFailure(error));
    yield call(reject, error);
  }
}

//Analytics
function* fetchAnalyticsWorker(action) {
  const {
    payload: { year },
  } = action;

  try {
    const fetchAnalytics = () =>
      api
        .get(`/platforms/analytical_data?year=${year}`)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(fetchAnalytics);

    yield put(actions.getAnalyticsSuccess(result));
  } catch (error) {
    yield put(actions.getAnalyticsFailure(error));
  }
}

export default function* platformsSaga() {
  yield takeLatest(constants.GET_PLATFORMS, fetchPlatformsWorker);
  yield takeLatest(constants.GET_PLATFORM, fetchPlatformWorker);
  yield takeLatest(
    constants.EDIT_PLATFORM_INFORMATION,
    fetchEditPlatformInformationWorker
  );
  yield takeLatest(constants.ADD_PLATFORM, fetchAddPlatformWorker);
  yield takeLatest(constants.GET_PIPELINES, fetchPipelinesWorker);
  yield takeLatest(constants.GET_PIPELINE, fetchPipelineWorker);
  yield takeLatest(constants.EDIT_PIPELINE, fetchEditPipelineWorker);
  yield takeLatest(constants.ADD_PIPELINE, fetchAddPipelineWorker);
  yield takeLatest(constants.GET_WELLS, fetchWellsWorker);
  yield takeLatest(constants.GET_WELL, fetchWellWorker);
  yield takeLatest(constants.EDIT_WELL, fetchEditWellWorker);
  yield takeLatest(constants.ADD_WELL, fetchAddWellWorker);

  yield takeLatest(constants.GET_WORK_ORDERS, fetchWorkOrdersWorker);
  yield takeLatest(constants.CREATE_WORK_ORDER, createWorkOrderWorker);
  yield takeLatest(constants.EDIT_WORK_ORDER, editWorkOrderWorker);
  yield takeLatest(constants.GET_WORK_ORDER, getWorkOrderWorker);

  yield takeLatest(constants.GET_WELL_SLOTS, fetchWellSlotsWorker);
  yield takeLatest(constants.GET_WELL_SLOT, fetchWellSlotWorker);
  yield takeLatest(constants.EDIT_WELL_SLOT, fetchEditWellSlotWorker);
  yield takeLatest(constants.ADD_WELL_SLOT, fetchAddWellSlotWorker);

  yield takeLatest(constants.GET_REPORTS, fetchReportsWorker);
  yield takeLatest(constants.CREATE_REPORT, createReportWorker);
  yield takeLatest(constants.EDIT_REPORT, editReportWorker);
  yield takeLatest(constants.GET_REPORT, getReportWorker);
  yield takeLatest(constants.GET_RETINGS, fetchRatingsWorker);
  yield takeLatest(constants.GET_STATUSES, fetchStatusesWorker);
  yield takeLatest(constants.EDIT_RETINGS, fetchEditRatingsWorker);
  yield takeLatest(constants.EDIT_STATUSES, editStatusesWorker);
  yield takeLatest(constants.GET_ANALYTICS, fetchAnalyticsWorker);
  yield takeLatest(constants.DELETE_REPORT, deleteReportWorker);
}
