// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconButton_icon_button__2h9vw {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-shrink: 0;\n  flex-grow: 0;\n  padding: 10px;\n  border-radius: 50%;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://src/shared/Button/IconButton.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,cAAA;EACA,YAAA;EAEA,aAAA;EACA,kBAAA;EAEA,eAAA;AAFF","sourcesContent":[".icon_button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  flex-shrink: 0;\n  flex-grow: 0;\n\n  padding: 10px;\n  border-radius: 50%;\n\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon_button": "IconButton_icon_button__2h9vw"
};
export default ___CSS_LOADER_EXPORT___;
