import cx from 'classnames';
import * as React from 'react';

import styles from './Table.module.scss';

interface ITableContext {
  bodyRef: React.MutableRefObject<HTMLDivElement>;
  setBody: (bodyRef: React.MutableRefObject<HTMLDivElement>) => void;
}

interface Props {
  children: any;
}

const TableContext = React.createContext<ITableContext>(null);

export const Table: React.FC<Props> = ({ children }) => {
  const [bodyRef, setBody] = React.useState(null);

  return (
    <TableContext.Provider
      value={{
        bodyRef,
        setBody
      }}
    >
      <div className={styles.table} >
        {children}
      </div>
    </TableContext.Provider>
  );
};

export const TableHeader: React.FC<Props>  = ({ children }) => {
  const headerRef = React.useRef<HTMLDivElement>();
  const context = React.useContext(TableContext);

  const onScroll = React.useCallback(() => {
    headerRef.current.style.width = context.bodyRef.current.clientWidth + 'px';
    headerRef.current.scrollLeft = context.bodyRef.current.scrollLeft;
  }, [headerRef, context.bodyRef]);

  React.useEffect(() => {
    context.bodyRef?.current.addEventListener('scroll', onScroll);
    return () => {
      context.bodyRef?.current.removeEventListener('scroll', onScroll);
    };
  }, [headerRef, context.bodyRef, onScroll]);

  return (
    <div className={styles.table_header}>
      <div
        ref={headerRef}
        className={styles.table_header_container}

      >
        {children}
      </div>
    </div>
  );
};

export interface TableHeaderCellProps extends TableCellProps {}

export const TableHeaderCell: React.FC<Partial<
  TableHeaderCellProps
>> = props => (
  <TableCell component="div" className={styles.table_header_cell} {...props} />
);

export const TableBody: React.FC<Props> = ({ children }) => {
  const bodyRef = React.useRef();
  const context = React.useContext(TableContext);

  React.useEffect(() => {
    context.setBody(bodyRef);
    return () => {};
  }, [context]);

  return <div ref={bodyRef} className={styles.table_body}>
    {children}
  </div>;
};

export const TablePlaceholder: React.FC<Props> = ({ children }) => (
  <div className={styles.table_placeholder}>
    {children}
  </div>
);

export interface TableRowProps
  extends React.HTMLAttributes<React.TdHTMLAttributes<any>> {
  checked: boolean;
  component: any;
  className: string;
  onClick?: (any) => void;
  to?: any;
}

export interface TableCellProps {
  variant: string;
  style: any;
  checked: boolean;
  component: any;
  className: string;
  children: any
}

export const TableCell: React.FC<Partial<TableCellProps>> = ({
  className,
  children,
  variant = null,
  checked = null,
  style = null,
  component: Component = 'div',
  ...other
}) => {
  return (
    <Component
      style={style}
      className={cx(
        {
          [styles.table_row_cell]: true,
          [styles['table_row_cell_' + variant]]: variant,
          [styles.table_row_cell_checked]: checked
        },
        className
      )}
      {...other}
    >
      {variant === 'large' ? (
        <div className={styles.largeCell} {...other} >
          {children}
        </div>
      ) : (
        <div className={styles.mainCell} {...other}>
          {children}
        </div>
      )}
    </Component>
  );
};

export const TableRow: React.FC<Partial<TableRowProps>> = ({
  className,
  checked = null,
  component: Component = 'div',
  ...other
}) => {
  return (
    <Component
      className={cx(
        {
          [styles.table_row]: true,
          [styles.table_row_checked]: checked
        },
        className
      )}
      {...other}
    />
  );
};
