import { IGlobalState } from 'core/reducers/interface';

export const customersSelector = (state: IGlobalState) => state.customers;

// loader
export const isLoadingCustomersSelector = (state: IGlobalState) =>
  customersSelector(state).customersIsLoading;

// customers
export const listCustomersSelector = (state: IGlobalState) =>
  customersSelector(state).customersListReducer.customersData;

export const currentCustomerSelector = (state: IGlobalState) =>
  customersSelector(state).customerReducer;

export const isLoadingButtonSelector = (state: IGlobalState) =>
  customersSelector(state).editCustomerReducer.loader;

export const failureAddCustomerSelector = (state: IGlobalState) =>
  customersSelector(state).editCustomerReducer.failureAddCustomer;

export const failureChangeCustomerSelector = (state: IGlobalState) =>
  customersSelector(state).editCustomerReducer.failureChangeCustomer;

export const customerIdSelector = (state: IGlobalState) =>
  customersSelector(state).customerId;
