import { PayloadedAction } from 'core/interfaces/PayloadedAction';
import { combineReducers } from 'redux';
import { createAction } from 'redux-actions';
import { promiseAction } from 'utils/sagaUtils';

import * as constants from './constants';
import {
  ICurrentCustomer,
  ICustomers,
  ICustomersReducer,
  IEditCustomer
} from './interfaces';

export const actions = {
  getCustomers: createAction(constants.GET_CUSTOMERS),
  getCustomersSuccess: createAction(constants.GET_CUSTOMERS_SUCCESS),
  getCustomersFailure: createAction(constants.GET_CUSTOMERS_FAILURE),
  getCustomer: createAction(constants.GET_CUSTOMER),
  getCustomerSuccess: createAction(constants.GET_CUSTOMER_SUCCESS),
  getCustomerFailure: createAction(constants.GET_CUSTOMER_FAILURE),
  clearCustomers: createAction(constants.CLEAR_CUSTOMERS),
  clearCustomer: createAction(constants.CLEAR_CUSTOMER),
  changeCustomer: promiseAction(constants.CHANGE_CUSTOMER),
  changeCustomerSuccess: createAction(constants.CHANGE_CUSTOMER_SUCCESS),
  changeCustomerFailure: createAction(constants.CHANGE_CUSTOMER_FAILURE),
  getLogo: createAction(constants.GET_LOGO),
  getLogoSuccess: createAction(constants.GET_LOGO_SUCCESS),
  getLogoFailure: createAction(constants.GET_LOGO_FAILURE),
  uploadLogo: createAction(constants.UPLOAD_LOGO),
  uploadLogoSuccess: createAction(constants.UPLOAD_LOGO_SUCCESS),
  uploadLogoFailure: createAction(constants.UPLOAD_LOGO_FAILURE),
  deleteLogo: createAction(constants.DELETE_LOGO),
  deleteLogoSuccess: createAction(constants.DELETE_LOGO_SUCCESS),
  deleteLogoFailure: createAction(constants.DELETE_LOGO_FAILURE),
  addCustomer: createAction(constants.ADD_CUSTOMER),
  addCustomerSuccess: createAction(constants.ADD_CUSTOMER_SUCCESS),
  addCustomerFailure: createAction(constants.ADD_CUSTOMER_FAILURE),
  addCustomerId: createAction(constants.ADD_CUSTOMER_ID),
  clearCustomerId: createAction(constants.CLEAR_CUSTOMER_ID)
};

const customersIsLoading = (
  state = false,
  action: PayloadedAction
): boolean => {
  switch (action.type) {
    case constants.GET_CUSTOMERS:
    case constants.GET_CUSTOMER:
      return true;
    case constants.GET_CUSTOMERS_SUCCESS:
    case constants.GET_CUSTOMERS_FAILURE:
    case constants.GET_CUSTOMER_SUCCESS:
    case constants.GET_CUSTOMER_FAILURE:
      return false;
    default:
      return state;
  }
};

const customerId = (state = null, action: PayloadedAction): number => {
  switch (action.type) {
    case constants.ADD_CUSTOMER_ID:
      return action.payload;
    case constants.CLEAR_CUSTOMER_ID:
      return null;
    default:
      return state;
  }
};

const initialStateCustomers = {
  customersData: {
    count: 0,
    offset: 0,
    limit: null,
    next: '',
    sortBy: '',
    search: '',
    previous: '',
    results: []
  },
  currentCustomer: null
};

export const customersListReducer = (
  state = initialStateCustomers,
  action: PayloadedAction
): ICustomers => {
  switch (action.type) {
    case constants.GET_CUSTOMERS_SUCCESS:
      return { ...state, customersData: action.payload };

    case constants.CLEAR_CUSTOMERS:
      return {
        ...state,
        customersData: {
          count: 0,
          offset: 0,
          limit: null,
          next: '',
          sortBy: '',
          search: '',
          previous: '',
          results: []
        }
      };
    default:
      return state;
  }
};

export const customerReducer = (
  state = null,
  action: PayloadedAction
): ICurrentCustomer => {
  switch (action.type) {
    case constants.GET_CUSTOMER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const initialStateEditCustomer = {
  loader: false,
  successChangeCustomer: null,
  failureChangeCustomer: null,
  successGetLogo: null,
  failureGetLogo: null,
  successUploadLogo: null,
  failureUploadLogo: null,
  successDeleteLogo: null,
  failureDeleteLogo: null,
  successAddCustomer: null,
  failureAddCustomer: null
};

export const editCustomerReducer = (
  state = initialStateEditCustomer,
  action: PayloadedAction
): IEditCustomer => {
  switch (action.type) {
    case constants.CLEAR_CUSTOMER:
      return {
        ...state,
        failureChangeCustomer: null,
        failureGetLogo: null,
        failureUploadLogo: null,
        failureDeleteLogo: null,
        failureAddCustomer: null
      };
    case constants.CHANGE_CUSTOMER:
      return {
        ...state,
        loader: true
      };
    case constants.CHANGE_CUSTOMER_SUCCESS:
      return {
        ...state,
        successChangeCustomer: action.payload,
        loader: false
      };

    case constants.CHANGE_CUSTOMER_FAILURE:
      return {
        ...state,
        failureChangeCustomer: action.payload,
        loader: false
      };
    case constants.GET_LOGO_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          successGetLogo: action.payload?.upload_link
        };
      }
      break;
    case constants.GET_LOGO_FAILURE:
      return {
        ...state,
        failureGetLogo: action.payload
      };

    case constants.UPLOAD_LOGO_SUCCESS:
      return {
        ...state,
        successUploadLogo: action.payload
      };

    case constants.UPLOAD_LOGO_FAILURE:
      return {
        ...state,
        failureUploadLogo: action.payload
      };

    case constants.DELETE_LOGO_SUCCESS:
      return {
        ...state,
        successDeleteLogo: action.payload
      };

    case constants.DELETE_LOGO_FAILURE:
      return {
        ...state,
        failureDeleteLogo: action.payload
      };
    case constants.ADD_CUSTOMER:
      return {
        ...state,
        loader: true,
        failureAddCustomer: null
      };
    case constants.ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        successAddCustomer: action.payload,
        failureAddCustomer: null,
        loader: false
      };

    case constants.ADD_CUSTOMER_FAILURE:
      return {
        ...state,
        failureAddCustomer: action.payload,
        loader: false
      };
    default:
      return state;
  }
};

const customersReducers = combineReducers<ICustomersReducer>({
  customersIsLoading,
  customersListReducer,
  customerReducer,
  editCustomerReducer,
  customerId
});

export default customersReducers;
