import { IGlobalState } from 'core/reducers/interface';

export const campaignSelector = (state: IGlobalState) => state.campaigns;

export const isLoadingSelector = (state: IGlobalState) =>
  campaignSelector(state).campaignsIsLoading;

export const campaignsDataSelector = (state: IGlobalState) =>
  campaignSelector(state).campaignsReducer.campaignsData;

export const campaignsSelector = (state: IGlobalState) =>
  campaignSelector(state).campaignsReducer;
