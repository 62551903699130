import cx from 'classnames';
import React, { useState } from 'react';
import { IconButton } from 'shared/Button';

import { ReactComponent as EyeIcon } from './assets/img/eye.svg';
import styles from './PasswordField.module.scss';
import { TextBox, TextBoxProps } from './TextBox';

export interface PasswordFieldProps extends TextBoxProps {}

export const PasswordField: React.FC<Partial<PasswordFieldProps>> = props => {
  const [show, toggle] = useState(false);

  return (
    <TextBox
      type={show ? 'text' : 'password'}
      endAdornment={
        <IconButton component="a" onClick={() => toggle(!show)}>
          <EyeIcon
            className={cx(styles.eye, {
              [styles.eye_active]: show
            })}
          />
        </IconButton>
      }
      {...props}
    />
  );
};
