import { IGlobalState } from 'core/reducers/interface';

//userProfile
export const usersSelector = (state: IGlobalState) => state.users;

export const userInfoSelector = (state: IGlobalState) =>
  usersSelector(state).userProfileReducer.successGetUsersInfo;

export const editProfileSelector = (state: IGlobalState) =>
  usersSelector(state).editProfileReducer.editProfileView;

export const isLoadingSelector = (state: IGlobalState) =>
  usersSelector(state).editProfileReducer.loader;

export const editStepSelector = (state: IGlobalState) =>
  usersSelector(state).editProfileReducer.editStep;

export const failureChangeUserNameSelector = (state: IGlobalState) =>
  usersSelector(state).editProfileReducer.failureChangeUsersName;

export const failureSendPasswordCodeSelector = (state: IGlobalState) =>
  usersSelector(state).editProfileReducer.failureSendPasswordCode;

export const changePasswordTokenSelector = (state: IGlobalState) =>
  usersSelector(state).editProfileReducer.successSendPasswordCode;

export const failureFinishChangePasswordSelector = (state: IGlobalState) =>
  usersSelector(state).editProfileReducer.failureFinishChangePassword;

//users
export const listUsersSelector = (state: IGlobalState) =>
  usersSelector(state).usersReducer.usersData;

// loader
export const isLoadingUsersSelector = (state: IGlobalState) =>
  usersSelector(state).usersIsLoading;

//addUser

export const errorAddUserSelector = (state: IGlobalState) =>
  usersSelector(state).editProfileReducer.failureAddUser;

export const userIdSelector = (state: IGlobalState) =>
  usersSelector(state).userId;
