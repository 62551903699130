// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Label_field_label__17wxG {\n  font-family: Roboto;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 16px;\n  margin: 10px 0;\n  margin-top: 10px;\n  color: #9b9b9b;\n}", "",{"version":3,"sources":["webpack://src/shared/Form/Label/Label.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,cAAA;AACF","sourcesContent":[".field_label {\n  font-family: Roboto;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 16px;\n  margin: 10px 0;\n  margin-top: 10px;\n  color: #9b9b9b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field_label": "Label_field_label__17wxG"
};
export default ___CSS_LOADER_EXPORT___;
