import cx from 'classnames';
import React from 'react';

import styles from './IconButton.module.scss';

export interface IconButtonProps {
  component: any;
  className: string;
  onClick: (any) => void;
  children: any
}

export const IconButton: React.FC<Partial<IconButtonProps>> = ({
  component: Component = 'button',
  className,
  children,
  ...other
}) => <Component className={cx(styles.icon_button, className)} {...other} >
    {children}
  </Component>
  ;

export default IconButton;
