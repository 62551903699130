// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_recoveryForm__38SDI {\n  width: 348px;\n}\n\n.styles_back__3pm32 {\n  display: flex;\n  margin-bottom: 16px;\n}\n\n.styles_backImg__I2PKO {\n  cursor: pointer;\n}\n\n.styles_title__aE0p4 {\n  font-family: RobotoCondensed-Bold;\n  font-size: 36px;\n  line-height: 42px;\n  color: #3e3e3e;\n  margin-left: 20px;\n}\n\n.styles_text__2wh_d {\n  font-family: Roboto;\n  font-size: 16px;\n  line-height: 24px;\n  letter-spacing: 0.15px;\n  color: #9b9b9b;\n  margin-bottom: 38px;\n}\n\n.styles_error__30WRn {\n  font-family: Roboto;\n  font-size: 12px;\n  line-height: 14px;\n  text-align: center;\n  letter-spacing: 0.0357143em;\n  color: #db4437;\n  margin-bottom: 12px;\n}\n\n.styles_submitButton__1SQH_ {\n  margin-top: 36px;\n}", "",{"version":3,"sources":["webpack://src/app/containers/Login/containers/Recovery/components/RecoveryStep2/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,iCAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;AACF;;AAEA;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,cAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,2BAAA;EACA,cAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".recoveryForm {\n  width: 348px;\n}\n\n.back {\n  display: flex;\n  margin-bottom: 16px;\n}\n\n.backImg {\n  cursor: pointer;\n}\n\n.title {\n  font-family: RobotoCondensed-Bold;\n  font-size: 36px;\n  line-height: 42px;\n  color: #3e3e3e;\n  margin-left: 20px;\n}\n\n.text {\n  font-family: Roboto;\n  font-size: 16px;\n  line-height: 24px;\n  letter-spacing: 0.15px;\n  color: #9b9b9b;\n  margin-bottom: 38px;\n}\n\n.error {\n  font-family: Roboto;\n  font-size: 12px;\n  line-height: 14px;\n  text-align: center;\n  letter-spacing: 0.0357143em;\n  color: #db4437;\n  margin-bottom: 12px;\n}\n\n.submitButton {\n  margin-top: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recoveryForm": "styles_recoveryForm__38SDI",
	"back": "styles_back__3pm32",
	"backImg": "styles_backImg__I2PKO",
	"title": "styles_title__aE0p4",
	"text": "styles_text__2wh_d",
	"error": "styles_error__30WRn",
	"submitButton": "styles_submitButton__1SQH_"
};
export default ___CSS_LOADER_EXPORT___;
