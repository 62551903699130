import * as React from 'react';

import defaultImage from './img/avatar.svg';
import styles from './styles.module.scss';

export interface AvatarProps {
  src: string;
}

export const Avatar: React.FC<Partial<AvatarProps>> = ({ src, ...other }) => (
  <div className={styles.pic}>
    <img src={src || defaultImage} alt="pic" />
  </div>
);

export default Avatar;
