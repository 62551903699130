import cx from 'classnames';
import * as React from 'react';

import styles from './Form.module.scss';

export interface FormProps extends React.HTMLProps<HTMLFormElement> {
  className: string;
}

export const Form: React.FC<Partial<FormProps>> = ({ className, ...other }) => (
  <form className={cx(styles.form, className)} {...other}></form>
);

export default FormProps;
