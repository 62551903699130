import cx from 'classnames';
import * as React from 'react';

import styles from './Details.module.scss';

export const Details: React.FC<Partial<
  React.DetailsHTMLAttributes<HTMLElement>
>> = ({ className, ...other }) => {
  return <details className={cx(styles.details, className)} {...other} />;
};

export const Summary: React.FC<Partial<
  React.DetailsHTMLAttributes<HTMLElement>
>> = ({ className, ...other }) => {
  return (
    <summary className={cx(styles.details_summary, className)} {...other} />
  );
};

export const DetailsBody: React.FC<Partial<
  React.DetailsHTMLAttributes<HTMLElement>
>> = ({ className, ...other }) => {
  return <div className={cx(styles.details_body, className)} {...other} />;
};

export default Details;
