import { IGlobalState } from 'core/reducers/interface';

export const appSelector = (state: IGlobalState) => state.app;

export const isLoginSelector = (state: IGlobalState) =>
  appSelector(state).loginReducer.isAuth;

export const failureSelector = (state: IGlobalState) =>
  appSelector(state).loginReducer.failure;

export const isLoadingSelector = (state: IGlobalState) =>
  appSelector(state).loginReducer.loader;

export const recoveryViewSelector = (state: IGlobalState) =>
  appSelector(state).recoveryReducer.recoveryView;

export const isLoadingRecoverySelector = (state: IGlobalState) =>
  appSelector(state).recoveryReducer.loader;

export const failureGetRecoveryCodeSelector = (state: IGlobalState) =>
  appSelector(state).recoveryReducer.failureGetCodeRecovery &&
  appSelector(state).recoveryReducer.failureGetCodeRecovery;

export const failureSendRecoveryCodeSelector = (state: IGlobalState) =>
  appSelector(state).recoveryReducer.failureSendCodeRecovery &&
  appSelector(state).recoveryReducer.failureSendCodeRecovery;

export const failureFinishRecoverySelector = (state: IGlobalState) =>
  appSelector(state).recoveryReducer.failureFinishRecovery &&
  appSelector(state).recoveryReducer.failureFinishRecovery;

export const mailRecoverySelector = (state: IGlobalState) =>
  appSelector(state).recoveryReducer.email;

export const tokenRecoverySelector = (state: IGlobalState) =>
  appSelector(state).recoveryReducer.successSendCodeRecovery &&
  appSelector(state).recoveryReducer.successSendCodeRecovery['recovery_token'];

export const recoveryStepSelector = (state: IGlobalState) =>
  appSelector(state).recoveryReducer.recoveryStep;

export const refreshTokenSelector = (state: IGlobalState) =>
  appSelector(state).loginReducer.success.refresh;

export const accessTokenSelector = (state: IGlobalState) =>
  appSelector(state).loginReducer.success.access;

export const userInfoSelector = (state: IGlobalState) =>
  appSelector(state).loginReducer.success.user;

export const popUpSelector = (state: IGlobalState) =>
  appSelector(state).popUpReducer.isOpen;
