// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ColorPicker_popover__3gjPR {\n  position: absolute;\n  z-index: 2;\n}\n\n.ColorPicker_cover__2ytOx {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n}\n\n.ColorPicker_eye__o5b3q {\n  width: 26px;\n  height: 26px;\n  fill: rgb(90, 88, 88);\n}\n.ColorPicker_eye_active__m-k13 {\n  fill: #328757;\n}", "",{"version":3,"sources":["webpack://src/shared/Form/ColorPicker/ColorPicker.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;AACF;;AAEA;EACE,eAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,qBAAA;AACF;AACE;EACE,aAAA;AACJ","sourcesContent":[".popover {\n  position: absolute;\n  z-index: 2;\n}\n\n.cover {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n}\n\n.eye {\n  width: 26px;\n  height: 26px;\n  fill: rgb(90, 88, 88);\n\n  &_active {\n    fill: #328757;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": "ColorPicker_popover__3gjPR",
	"cover": "ColorPicker_cover__2ytOx",
	"eye": "ColorPicker_eye__o5b3q",
	"eye_active": "ColorPicker_eye_active__m-k13"
};
export default ___CSS_LOADER_EXPORT___;
