import { IWorkOrder } from 'platforms/store/interfaces';
import * as React from 'react';

import { saveWorkOrder } from './WorkOrderItem';

class WorkOrderDownloadAction extends React.Component<Partial<IWorkOrder>> {
  handleDownload = event => {
    const { file, title } = this.props;

    saveWorkOrder(event, file, title);
  };
  render() {
    return (
      <span
        onClick={this.handleDownload}
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
          fontWeight: 'bold',
          color: 'white'
        }}
      >
        DOWNLOAD
      </span>
    );
  }
}

export default WorkOrderDownloadAction;
