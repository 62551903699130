import * as React from 'react';

import styles from './PhotoControlContainer.module.scss';

const PhotoPreview: React.FC<React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>> = props => (
  <img className={styles.field_preview_container} {...props} alt="" />
);

export default PhotoPreview;
