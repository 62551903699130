import * as React from 'react';
import { NavLink } from 'react-router-dom';

import edit from './img/edit.png';
import styles from './styles.module.scss';

interface IProps {
  path: string;
}

class ButtonEdit extends React.Component<IProps> {
  render() {
    const { path } = this.props;
    return (
      <NavLink to={{ pathname: path }}>
        <div className={styles.editButton}>
          <img src={edit} alt="add" />
        </div>
      </NavLink>
    );
  }
}

export default ButtonEdit;
