const STORE_TYPE = 'campaign';

export const GET_CAMPAIGNS = `${STORE_TYPE}/GET_CAMPAIGNS`;
export const GET_CAMPAIGNS_SUCCESS = `${STORE_TYPE}/GET_CAMPAIGNS_SUCCESS`;
export const GET_CAMPAIGNS_FAILURE = `${STORE_TYPE}/GET_CAMPAIGNS_FAILURE`;

export const CLEAR_CAMPAIGNS = `${STORE_TYPE}/CLEAR_CAMPAIGNS`;
export const CLEAR_CAMPAIGN = `${STORE_TYPE}/CLEAR_CAMPAIGN`;

export const GET_CAMPAIGN = `${STORE_TYPE}/GET_CAMPAIGN`;
export const GET_CAMPAIGN_SUCCESS = `${STORE_TYPE}/GET_CAMPAIGN_SUCCESS`;
export const GET_CAMPAIGN_FAILURE = `${STORE_TYPE}/GET_CAMPAIGN_FAILURE`;

export const ADD_CAMPAIGN = `${STORE_TYPE}/ADD_CAMPAIGN`;
export const ADD_CAMPAIGN_SUCCESS = `${STORE_TYPE}/ADD_CAMPAIGN_SUCCESS`;
export const ADD_CAMPAIGN_FAILURE = `${STORE_TYPE}/ADD_CAMPAIGN_FAILURE`;

export const EDIT_CAMPAIGN = `${STORE_TYPE}/EDIT_CAMPAIGN`;
export const EDIT_CAMPAIGN_SUCCESS = `${STORE_TYPE}/EDIT_CAMPAIGN_SUCCESS`;
export const EDIT_CAMPAIGN_FAILURE = `${STORE_TYPE}/EDIT_CAMPAIGN_FAILURE`;

export const DELETE_CAMPAIGN = `${STORE_TYPE}/DELETE_CAMPAIGN`;
export const DELETE_CAMPAIGN_SUCCESS = `${STORE_TYPE}/DELETE_CAMPAIGN_SUCCESS`;
export const DELETE_CAMPAIGN_FAILURE = `${STORE_TYPE}/DELETE_CAMPAIGN_FAILURE`;
