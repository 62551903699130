const STORE_TYPE = 'app';

export const FETCH_ME = `${STORE_TYPE}/FETCH_ME`;
export const FETCH_ME_SUCCESS = `${STORE_TYPE}/FETCH_ME_SUCCESS`;
export const FETCH_ME_FAILURE = `${STORE_TYPE}/FETCH_ME_FAILURE`;

export const LOG_IN = `${STORE_TYPE}/LOG_IN`;
export const LOG_IN_SUCCESS = `${STORE_TYPE}/LOG_IN_SUCCESS`;
export const LOG_IN_FAILURE = `${STORE_TYPE}/LOG_IN_FAILURE`;
export const LOG_OUT = `${STORE_TYPE}/LOG_OUT`;

export const SHOW_RECOVERY = `${STORE_TYPE}/SHOW_RECOVERY`;

export const GET_RECOVERY_CODE = `${STORE_TYPE}/GET_RECOVERY_CODE`;
export const GET_RECOVERY_CODE_SUCCESS = `${STORE_TYPE}/GET_RECOVERY_CODE_SUCCESS`;
export const GET_RECOVERY_CODE_FAILURE = `${STORE_TYPE}/GET_RECOVERY_CODE_FAILURE`;

export const SEND_RECOVERY_CODE = `${STORE_TYPE}/SEND_RECOVERY_CODE`;
export const SEND_RECOVERY_CODE_SUCCESS = `${STORE_TYPE}/SEND_RECOVERY_CODE_SUCCESS`;
export const SEND_RECOVERY_CODE_FAILURE = `${STORE_TYPE}/SEND_RECOVERY_CODE_FAILURE`;

export const FINISH_RECOVERY = `${STORE_TYPE}/FINISH_RECOVERY`;
export const FINISH_RECOVERY_SUCCESS = `${STORE_TYPE}/FINISH_RECOVERY_SUCCESS`;
export const FINISH_RECOVERY_FAILURE = `${STORE_TYPE}/FINISH_RECOVERY_FAILURE`;

export const BACK_TO_LOGIN = `${STORE_TYPE}/BACK_TO_LOGIN`;

export const TOGGLE_FILTER_POPUP = `${STORE_TYPE}/TOGGLE_FILTER_POPUP`;
