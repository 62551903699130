import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Preloader from 'shared/Preloader';
import UserProfile from 'users/containers/UserProfile';
import EditProfile from 'users/containers/EditProfile';
const Surveys = React.lazy(() => import('surveys/index'));
const Campaigns = React.lazy(() => import('campaigns/index'));
const Customers = React.lazy(() => import('customers/index'));
const Platforms = React.lazy(() => import('platforms/index'));
const Assignments = React.lazy(() => import('assignments/index'));
const Directories = React.lazy(() => import('directories/index'));
const Users = React.lazy(() => import('users/index'));

export const waitComponent = Component => props => <Component {...props} />;

class AppRouter extends React.Component {
  render() {
    return (
      <React.Suspense
        fallback={
          <React.Fragment>
            <Preloader />
          </React.Fragment>
        }
      >
        <Switch>
          <Route path="/surveys" render={waitComponent(Surveys)} />
          <Route
            path="/inspectionCampaigns"
            render={waitComponent(Campaigns)}
          />
          <Route path="/customers" render={waitComponent(Customers)} />
          <Route path="/platforms" render={waitComponent(Platforms)} />
          <Route path="/assignments" render={waitComponent(Assignments)} />
          <Route path="/directories" render={waitComponent(Directories)} />
          <Route path="/users" render={waitComponent(Users)} />
          <Route path="/profile/:id/edit" component={EditProfile} />
          <Route path="/profile/:id" component={UserProfile} />
          <Redirect to="/platforms" />
        </Switch>
      </React.Suspense>
    );
  }
}

export default AppRouter;
