// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_field_text__3RpTB {\n  font-size: 14px;\n  line-height: 16px;\n  color: #3e3e3e;\n}", "",{"version":3,"sources":["webpack://src/shared/Profile/Text/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AACF","sourcesContent":[".field_text {\n  font-size: 14px;\n  line-height: 16px;\n  color: #3e3e3e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field_text": "styles_field_text__3RpTB"
};
export default ___CSS_LOADER_EXPORT___;
