// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_editButton__2XTAC {\n  cursor: pointer;\n  width: 56px;\n  height: 56px;\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  bottom: 70px;\n  right: 30px;\n  border-radius: 50%;\n  background: #469367;\n  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12);\n}\n\n.styles_editButton__2XTAC:hover {\n  background: #41b673;\n}", "",{"version":3,"sources":["webpack://src/shared/ButtonEdit/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,4EAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".editButton {\n  cursor: pointer;\n  width: 56px;\n  height: 56px;\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  bottom: 70px;\n  right: 30px;\n  border-radius: 50%;\n  background: #469367;\n  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12);\n}\n\n.editButton:hover {\n  background: #41b673;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editButton": "styles_editButton__2XTAC"
};
export default ___CSS_LOADER_EXPORT___;
