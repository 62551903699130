import * as React from 'react';
import styles from './styles.module.scss';
const errorImg = require('assets/icons/error.svg');

export const Failure = ({ ...other }) => (
  <div className={styles.errorBlock}>
    <p className={styles.errorText} {...other} />
    <img src={errorImg} alt="" className={styles.errorImg} />
  </div>
);

export default Failure;
