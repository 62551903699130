// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_modalContent__UsfB0 {\n  position: absolute;\n  top: calc(50% - 92px);\n  left: calc(50% - 145px);\n  width: 290px;\n  height: 184px;\n  background-color: white;\n  outline: none;\n  box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.3);\n  border-radius: 4px;\n  overflow: auto;\n  z-index: 1000;\n}\n.styles_modalContent_big__3zepc {\n  width: 390px;\n  height: 234px;\n}\n\n.styles_modalOverlay__1i7Cu {\n  z-index: 999;\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.4);\n}", "",{"version":3,"sources":["webpack://src/shared/ModalPopUp/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;EACA,4CAAA;EACA,kBAAA;EACA,cAAA;EACA,aAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;AACJ;;AAGA;EACE,YAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,oCAAA;AAAF","sourcesContent":[".modalContent {\n  position: absolute;\n  top: calc(50% - 92px);\n  left: calc(50% - 145px);\n  width: 290px;\n  height: 184px;\n  background-color: white;\n  outline: none;\n  box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.3);\n  border-radius: 4px;\n  overflow: auto;\n  z-index: 1000;\n\n  &_big {\n    width: 390px;\n    height: 234px;\n  }\n}\n\n.modalOverlay {\n  z-index: 999;\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": "styles_modalContent__UsfB0",
	"modalContent_big": "styles_modalContent_big__3zepc",
	"modalOverlay": "styles_modalOverlay__1i7Cu"
};
export default ___CSS_LOADER_EXPORT___;
