import * as React from 'react';

import styles from './UploadButton.module.scss';

const image = require('./assets/photo_add.svg');

interface IProps {
  name: string;
  onAdd: (any) => void;
}

class UploadButton extends React.Component<IProps> {
  handleChange = event => {
    const { onAdd } = this.props;

    for (var i = 0; i < event.target.files.length; i++) {
      const x = event.target.files[i];
      onAdd({
        file: x,
        preview: x.preview ?? URL.createObjectURL(x)
      });
    }

    event.target.value = '';
  };
  render() {
    const { name } = this.props;
    return (
      <div
        className={styles.container}
        style={{
          backgroundImage: `url(${image})`
        }}
      >
        <div className={styles.pic}>
          <input
            id={'photoUploadButton' + name}
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={this.handleChange}
            className={styles.addPhoto}
          />
          <label
            htmlFor={'photoUploadButton' + name}
            className={styles.avatarLabel}
          />
        </div>
      </div>
    );
  }
}

export default UploadButton;
