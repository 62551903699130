import cx from 'classnames';
import * as React from 'react';
import Select from 'react-select';

import pageLimitStyles from './PageLimitSelect.module.scss';
import styles from './TablePagination.module.scss';

//import { TextBox } from 'shared/Form';

import arrow from './assets/img/arrow.svg';
import arrowRight from './assets/img/arrow-right.svg';

const customStyles = {
  control: base => ({
    ...base,
    border: 0,
    boxShadow: 0
  })
};

interface PageLimitSelectProps {
  options: any[];
  onChange?: (value) => void;
  value: number;
}

class PageLimitSelect extends React.Component<PageLimitSelectProps> {
  setValue = () => {
    const { value, options } = this.props;
    const option = options.find(el => el.value === value);
    return option;
  };
  render() {
    const { onChange, options } = this.props;
    return (
      <Select
        options={options}
        menuPlacement="top"
        isSearchable={false}
        styles={customStyles}
        onChange={onChange}
        value={this.setValue()}
        classNamePrefix="combobox"
        className={pageLimitStyles.combobox}
      />
    );
  }
}

export interface TablePaginationProps {
  data: {
    count: number;
    limit: number;
    offset: number;
    next?: string;
    previous?: string;
  };
  options: any;
  changeLimit: (e) => void;
  loadData: (value) => void;
}

export const TablePagination: React.FC<TablePaginationProps> = props => {
  const {
    data: { count, limit, offset, next, previous },
    loadData,
    changeLimit,
    options
  } = props;

  const handleRowChange = selectedOption => {
    loadData({ limit: selectedOption.value, offset: 0 });
    changeLimit(selectedOption.value);
  };

  let totalPage = Math.ceil(count / limit);
  let currentPage = offset >= count ? -1 : Math.ceil(offset / limit) + 1;
  if (currentPage < 0) {
    currentPage = 0;
    totalPage = 0;
  }

  const loadDataHandler = (direction: string) => {
    if (direction === 'next') {
      if (next) return loadData({ offset: offset + limit, limit });
    } else {
      if (previous) return loadData({ offset: offset - limit, limit });
    }
  };
  return (
    <div className={styles.container}>
      <React.Fragment>
        <div className={styles.bottomBlock}>
          <div className={styles.pageCounter}>
            <button
              className={cx(styles.pageButton)}
              onClick={() => loadDataHandler('prev')}
            >
              <img src={arrow} alt="prev page" />
            </button>
            <div className={styles.pageNumbers}>
              <div className={styles.text}> {currentPage} </div>
              <span> / {totalPage} </span>
            </div>
            <button
              className={cx(styles.pageButton, styles.nextButton)}
              onClick={() => loadDataHandler('next')}
            >
              <img src={arrowRight} alt="next page" />
            </button>
          </div>
          <div className={styles.rowToggler}>
            <span className={styles.rowToggler__text}>Row per page:</span>
            <div className={styles.rowToggler__select}>
              <PageLimitSelect
                value={limit}
                options={options}
                onChange={handleRowChange}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default TablePagination;
