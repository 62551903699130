// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_list__189JJ {\n  display: flex;\n  flex-direction: column;\n}\n.styles_list__189JJ .styles_list_item__2P3Vk:nth-child(odd) {\n  background-color: #fcfcfc;\n}\n\n.styles_list_item__2P3Vk {\n  display: flex;\n  padding: 20px;\n  flex-shrink: 0;\n}\n\n.styles_list_item_title__1_jO9 {\n  width: 270px;\n  flex-shrink: 0;\n}\n\n.styles_list_item_text__2bWdl {\n  flex: 1 1;\n  flex-wrap: wrap;\n}", "",{"version":3,"sources":["webpack://src/shared/Containers/List/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,yBAAA;AACJ;;AAGA;EACE,aAAA;EACA,aAAA;EACA,cAAA;AAAF;;AAGA;EACE,YAAA;EACA,cAAA;AAAF;;AAGA;EACE,SAAA;EACA,eAAA;AAAF","sourcesContent":[".list {\n  display: flex;\n  flex-direction: column;\n\n  .list_item:nth-child(odd) {\n    background-color: #fcfcfc;\n  }\n}\n\n.list_item {\n  display: flex;\n  padding: 20px;\n  flex-shrink: 0;\n}\n\n.list_item_title {\n  width: 270px;\n  flex-shrink: 0;\n}\n\n.list_item_text {\n  flex: 1;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "styles_list__189JJ",
	"list_item": "styles_list_item__2P3Vk",
	"list_item_title": "styles_list_item_title__1_jO9",
	"list_item_text": "styles_list_item_text__2bWdl"
};
export default ___CSS_LOADER_EXPORT___;
