import { withFormik } from 'formik';
import path from 'path';
import { isEqual } from 'lodash';
import * as React from 'react';
import { Button } from 'shared/Button';
import { Toolbar, ToolbarBack } from 'shared/Containers';
import { Select, TextBox } from 'shared/Form';
import InputUploadImg from 'shared/InputUploadImg';
import PermissionsContainer from 'shared/Permissions/PermissionsContainer';
import Preloader from 'shared/Preloader';
import { IUser } from 'users/store/interfaces';
import styles from './styles.module.scss';

interface IProps {
  match: { params: { id: string }; url };
  initialValues: FormValues;
  touched?: any;
  failure?: any;
  userInfo: IUser;
  isLoading: boolean;
  fromUserView: boolean;
  errors: FormErrors;
  values: {
    first_name: string;
    last_name: string;
    email: string;
    role: string;
    userPhoto: string;
  };
  clearUser: () => void;
  handleBlur: () => void;
  handleChange: () => void;
  handleSubmit: () => void;
  backToProfile: () => void;
  getPasswordCode: () => void;
  getAvatar: (value: any) => void;
  setFieldValue: (value: any, value1: any) => void;
  changeUsersName: (value: any) => void;
}

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  role: any;
  userPhoto: string;
}

interface FormErrors {
  first_name?: string;
  last_name?: string;
  email?: string;
  role?: any;
  userPhoto?: string;
}

const formikEnhancer = withFormik<any, any>({
  validate: (values: FormValues) => {
    let errors: FormErrors = {};
    const { email, role, first_name, last_name } = values;

    if (!first_name) {
      errors.first_name = 'First name is empty';
    }
    if (!last_name) {
      errors.last_name = 'Last name is empty';
    }

    if (!email) {
      errors.email = 'Email is empty';
    }

    if (!role) {
      errors.role = 'Role is empty';
    }

    return errors;
  },
  mapPropsToValues: props => {
    const { userInfo } = props;
    return {
      first_name: userInfo?.first_name || '',
      last_name: userInfo?.last_name || '',
      email: userInfo?.email || '',
      role: userInfo?.role || '',
      userPhoto: userInfo?.avatar?.medium || ''
    };
  },
  handleSubmit: (values, bag: any) => {
    const {
      props: {
        match,
        changeUsersName,
        userInfo: { id, is_archived }
      },
      resetForm
    } = bag;
    const value = {
      id,
      is_archived,
      avatar: values.userPhoto,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      role: values.role.value
    };
    resetForm({
      initialValues: values,
      values: values
    });
    changeUsersName({ url: path.join(match.url, '../'), data: value });
  },
  displayName: 'EditProfileForm'
});

export const optionsRole = [
  { value: 'admin', label: 'Admin' },
  { value: 'inspector', label: 'Inspector' },
  { value: 'engineer', label: 'Engineer' },
  { value: 'client', label: 'Client' }
];

class EditProfileForm extends React.Component<IProps> {
  componentDidMount() {
    const { userInfo, getAvatar } = this.props;
    const id = userInfo && userInfo.id;
    id && getAvatar(id);
  }

  componentWillUnmount() {
    const { clearUser } = this.props;
    clearUser();
  }

  changePassword = () => {
    const { getPasswordCode } = this.props;
    getPasswordCode();
  };

  render() {
    const {
      match,
      values,
      errors,
      touched,
      isLoading,
      handleBlur,
      handleSubmit,
      setFieldValue,
      handleChange,
      fromUserView,
      initialValues,
      failure: serverError
    } = this.props;

    const failure = isEqual(values, initialValues) ? serverError : null;

    return (
      <form onSubmit={handleSubmit}>
        <Toolbar>
          <ToolbarBack
            path={path.join(match.url, '../')}
            text={`Edit profile`}
          />
          <Button type="submit" disabled={isLoading} variant="solid">
            {isLoading ? <Preloader size="button" /> : 'Save'}
          </Button>
        </Toolbar>

        {failure && failure.detail && (
          <p className={styles.error}>{failure.detail}</p>
        )}

        <div className={styles.profile}>
          <div className={styles.avatar}>
            <div className={styles.uploadImg}>
              <InputUploadImg
                image={values.userPhoto}
                name="userPhoto"
                onChange={setFieldValue}
              />
            </div>
          </div>

          <div className={styles.info}>
            <div className={styles.field}>
              <div className={styles.subtext}>
                <TextBox
                  key="first_name"
                  id="first_name"
                  label="First name"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    (errors?.first_name &&
                      touched.first_name &&
                      errors?.first_name) ??
                    failure?.first_name
                  }
                />
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.subtext}>
                <TextBox
                  key="last_name"
                  id="last_name"
                  label="Last name"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    (errors?.last_name &&
                      touched.last_name &&
                      errors?.last_name) ??
                    failure?.last_name
                  }
                />
              </div>
            </div>

            <PermissionsContainer roles={['admin']}>
              <div className={styles.field}>
                <div className={styles.subtext}>
                  <TextBox
                    key="email"
                    id="email"
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      (errors?.email && touched.email && errors?.email) ??
                      failure?.email?.email
                    }
                  />
                </div>
              </div>

              <div className={styles.field}>
                <p className={styles.text}>Role</p>
                <div className={styles.subtext}>
                  <Select
                    isClearable
                    findValue
                    placeholder="Role"
                    value={values.role}
                    onChange={value => setFieldValue('role', value)}
                    options={optionsRole}
                    error={
                      (errors?.role && touched.role && errors?.role) ??
                      failure?.role
                    }
                  />
                </div>
              </div>
            </PermissionsContainer>

            {fromUserView && (
              <p className={styles.link} onClick={this.changePassword}>
                CHANGE PASSWORD
              </p>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default formikEnhancer(EditProfileForm);
