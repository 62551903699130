import * as React from 'react';

import Label from '../Label/Label';
import { ReactComponent as IconActive } from './assets/active.svg';
import { ReactComponent as Icon } from './assets/inactive.svg';
import styles from './styles.module.scss';

export interface CheckboxProps extends React.HTMLAttributes<HTMLInputElement> {
  checked: boolean;
  id: string;
  name: string;
  label: string;
  error: string;
  value: string;
}

export const Checkbox: React.FC<Partial<CheckboxProps>> = ({
  id,
  checked,
  label,
  error,
  value = '',
  ...other
}) => {
  return (
    <div className={styles.checkboxContainer} {...other}>
      <input
        readOnly
        className={styles.hiddenCheckbox}
        type="checkbox"
        value={value}
        checked={checked}
      />
      <div className={styles.styledCheckbox}>
        {checked ? <IconActive /> : <Icon />}
      </div>
      {label && <Label htmlFor={id}>{label}</Label>}
    </div>
  );
};

export default Checkbox;

// className={cx(styles.styledCheckbox, {
//   [styles.checked]: checked
// })}
