import * as React from 'react';
import moment from 'moment';
import ModalPopUp from 'shared/ModalPopUp';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteButton.svg';
import { IReport, IPlatform } from 'platforms/store/interfaces';
import { NavLink } from 'react-router-dom';
import { TableCell, TableRow } from 'shared/Table';
import {ReportDelete} from './components/ReportDelete';
import { saveFile } from 'utils/photos';

import styles from '../../PlatformComponent.module.scss';
import PermissionsContainer from 'shared/Permissions/PermissionsContainer';

const defaultImage = require('surveys/assets/img/avatar.svg');

export async function saveReport(event, file, title) {
  event.stopPropagation();
  event.preventDefault();

  try {
    const response = await fetch(file, {
      method: 'GET',
      //mode: 'no-cors',
      cache: 'no-cache',
      headers: {
        Accept: 'application/pdf'
      }
    });

    saveFile(await response.arrayBuffer(), 'application/pdf', title + '.pdf');
  } catch (error) {
    console.log(error);
  }
}

export interface ReportsItemProps {
  dataPlatform: IPlatform;
  item: IReport;
  deleteReport: (values: {id: number, platform: number}) => void;
}

const ReportsItem: React.FC<ReportsItemProps> = ({ item, dataPlatform, deleteReport }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const toggleModal = (e: any, value: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    setModalIsOpen(value);
  }
  const deleteReportHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalIsOpen(false);
    deleteReport({id: item.id, platform: item.platform});
  }

  return (
    <TableRow
      component={NavLink}
      to={`/platforms/active/${dataPlatform.id}/reports/${item.id}`}
      key={item.id}
      className={styles.navLink}
      style={{ cursor: 'default' }}
    >
      <TableCell variant="large" className={styles.navLink}>
        {item.title}
      </TableCell>
      <TableCell variant="small" className={styles.navLink}>
        {moment(item.created_at).isValid()
          ? moment(item.created_at).format('MM-DD-YYYY')
          : ''}
      </TableCell>
      <TableCell variant="medium">
        <img src={defaultImage} alt="avatar" />
        <span>
          {item.creator.first_name} {item.creator.last_name}
        </span>
      </TableCell>
      <TableCell variant="small" className={styles.navLink}>
        {item.id}
      </TableCell>
      <TableCell variant="small" className={styles.navLink}>
        {item.file && (
          <div
            className={styles.edit}
            onClick={event => saveReport(event, item.file, item.title)}
          >
            <DownloadIcon />
          </div>
        )}
        {!dataPlatform?.is_archived && item?.is_verified === false && (
          <PermissionsContainer roles={['admin', 'engineer']}>
            <NavLink
              to={`/platforms/active/${dataPlatform?.id}/reports/${item.id}/edit`}
              className={styles.edit}
            >
              <EditIcon />
            </NavLink>
          </PermissionsContainer>
        )}
        <button
          onClick={(e) => toggleModal(e, true)}
          className={styles.edit}
        >
          <DeleteIcon />
        </button>
      </TableCell>
        <ModalPopUp isOpen={modalIsOpen} togglePopup={setModalIsOpen}>
          <ReportDelete
            togglePopup={toggleModal}
            deleteReport={deleteReportHandler}
          />
        </ModalPopUp>
    </TableRow>
  );
};

export default ReportsItem;
