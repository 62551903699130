import CampaignUndo from 'campaigns/components/CampaignUndo';
import { push } from 'connected-react-router';
import { api } from 'core/config/api';
import * as React from 'react';
import { toastr } from 'react-redux-toastr';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { catchError } from 'utils/sagaUtils';

import * as constants from './constants';
import { actions } from './duck';
import * as selectors from './selectors';

//Campaigns
export function* fetchCampaignsWorker(action) {
  const {
    search,
    is_archived,
    sortBy,
    limit,
    offset,
    filter,
    platform
  } = action.payload;

  const {
    search: prevSearch,
    sortBy: prevSortBy,
    limit: prevLimit,
    offset: prevOffet,
    filter: prevFilter
  } = yield select(selectors.campaignsDataSelector);

  const { campaign_year, ...other_filters } = filter ?? prevFilter ?? {};

  let queryParams = {
    is_archived,
    platform: platform || '',
    search: search ?? prevSearch,
    orderBy: sortBy ?? prevSortBy,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0,
    start_date__range: campaign_year
      ? `${campaign_year}-01-01,${campaign_year}-12-31`
      : '',
    ...other_filters
  };

  try {
    const fetchGetCampaigns = () =>
      api
        .get('/campaigns/', {
          params: { ...queryParams }
        })
        .then(response => response.data)
        .catch(catchError);
    const result = yield call(fetchGetCampaigns);

    yield put(
      actions.getCampaignsSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        filter: filter ?? prevFilter,
        sortBy: queryParams.orderBy,
        search: queryParams.search
      })
    );
  } catch (error) {
    yield put(actions.getCampaignsFailure(error));
  }
}

//Campaign
function* fetchCampaignWorker(action) {
  const {
    payload: { id }
  } = action;

  try {
    const fetchCampaign = () =>
      api
        .get(`/campaigns/${id}/`)
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchCampaign);

    yield put(actions.getCampaignSuccess(result));
  } catch (error) {
    yield put(actions.getCampaignFailure(error));
  }
}

//add Campaign
export function* fetchAddCampaignWorker(action) {
  const {
    payload: { ...values }
  } = action;

  try {
    const fetchAddCampaign = () =>
      api
        .post(`/campaigns/`, {
          ...values
        })
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchAddCampaign);

    yield put(actions.addCampaignSuccess(result));

    toastr.success('', 'Data has been changed');

    yield put(push(`/inspectionCampaigns/active`));

    yield put(actions.getCampaigns({}));
  } catch (error) {
    yield put(actions.addCampaignFailure(error));
  }
}

//edit Campaign
export function* fetchEditCampaignWorker(action) {
  const {
    payload: { id, url, ...other },
    meta: { resolve, reject }
  } = action;

  try {
    const fetchEditCampaign = () =>
      api
        .patch(`/campaigns/${id}/`, {
          ...other
        })
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchEditCampaign);

    yield put(actions.editCampaignSuccess(result));
    yield call(resolve);

    toastr.success('', 'Data has been changed');

    yield put(actions.getCampaign({ id }));

    yield put(push(url));

    yield put(actions.getCampaigns({}));
  } catch (error) {
    yield put(actions.editCampaignFailure(error));
    yield call(reject, error);
  }
}

//delete Campaign
export function* fetchDeleteCampaignWorker(action) {
  const {
    payload: { id, title, start_date, end_date }
  } = action;

  try {
    const fetchDeleteCampaign = () =>
      api
        .delete(`/campaigns/${id}/`)
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchDeleteCampaign);

    yield put(actions.deleteCampaignSuccess(result));

    const toastrOptions = {
      component: (
        <CampaignUndo
          title={title}
          start_date={start_date}
          end_date={end_date}
        />
      )
    };

    toastr.success(
      '',
      'The Inspection campaign has been deleted.',
      toastrOptions
    );

    yield put(actions.getCampaigns({}));
  } catch (error) {
    yield put(actions.deleteCampaignFailure(error));
  }
}

export default function* campaignsSaga() {
  yield takeLatest(constants.GET_CAMPAIGNS, fetchCampaignsWorker);
  yield takeLatest(constants.GET_CAMPAIGN, fetchCampaignWorker);
  yield takeLatest(constants.ADD_CAMPAIGN, fetchAddCampaignWorker);
  yield takeLatest(constants.EDIT_CAMPAIGN, fetchEditCampaignWorker);
  yield takeLatest(constants.DELETE_CAMPAIGN, fetchDeleteCampaignWorker);
}
