import { PayloadedAction } from 'core/interfaces/PayloadedAction';
import { combineReducers } from 'redux';
import { createAction } from 'redux-actions';
import { promiseAction } from 'utils/sagaUtils';

import * as constants from './constants';
import {
  ISurveyCathodicProtection,
  ISurveyComponents,
  ISurveyConductors,
  ISurveyCPCalibration,
  ISurveyISIMS,
  ISurveyPlatformPhotosRoot,
  ISurveyReducer,
  ISurveyRiserClamps,
  ISurveyRisers,
  ISurveyWellheads
} from './interfaces';

export const actions = {
  getComponents: createAction(constants.GET_COMPONENTS),
  getComponentsSuccess: createAction(constants.GET_COMPONENTS_SUCCESS),
  getComponentsFailure: createAction(constants.GET_COMPONENTS_FAILURE),
  getComponent: createAction(constants.GET_COMPONENT),
  getComponentSuccess: createAction(constants.GET_COMPONENT_SUCCESS),
  getComponentFailure: createAction(constants.GET_COMPONENT_FAILURE),
  clearComponent: createAction(constants.CLEAR_COMPONENT),
  editComponent: promiseAction(constants.EDIT_COMPONENT),
  editComponentSuccess: createAction(constants.EDIT_COMPONENT_SUCCESS),
  editComponentFailure: createAction(constants.EDIT_COMPONENT_FAILURE),
  removeComponent: createAction(constants.REMOVE_COMPONENT),
  removeComponentSuccess: createAction(constants.REMOVE_COMPONENT_SUCCESS),
  removeComponentFailure: createAction(constants.REMOVE_COMPONENT_FAILURE),

  getConductors: createAction(constants.GET_CONDUCTORS),
  getConductorsSuccess: createAction(constants.GET_CONDUCTORS_SUCCESS),
  getConductorsFailure: createAction(constants.GET_CONDUCTORS_FAILURE),
  getConductor: createAction(constants.GET_CONDUCTOR),
  getConductorSuccess: createAction(constants.GET_CONDUCTOR_SUCCESS),
  getConductorFailure: createAction(constants.GET_CONDUCTOR_FAILURE),
  editConductor: promiseAction(constants.EDIT_CONDUCTOR),
  editConductorSuccess: createAction(constants.EDIT_CONDUCTOR_SUCCESS),
  editConductorFailure: createAction(constants.EDIT_CONDUCTOR_FAILURE),
  removeConductor: createAction(constants.REMOVE_CONDUCTOR),
  removeConductorSuccess: createAction(constants.REMOVE_CONDUCTOR_SUCCESS),
  removeConductorFailure: createAction(constants.REMOVE_CONDUCTOR_FAILURE),

  getRisers: createAction(constants.GET_RISERS),
  getRisersSuccess: createAction(constants.GET_RISERS_SUCCESS),
  getRisersFailure: createAction(constants.GET_RISERS_FAILURE),
  getRiser: createAction(constants.GET_RISER),
  getRiserSuccess: createAction(constants.GET_RISER_SUCCESS),
  getRiserFailure: createAction(constants.GET_RISER_FAILURE),
  editRiser: promiseAction(constants.EDIT_RISER),
  editRiserSuccess: createAction(constants.EDIT_RISER_SUCCESS),
  editRiserFailure: createAction(constants.EDIT_RISER_FAILURE),
  removeRiser: createAction(constants.REMOVE_RISER),
  removeRiserSuccess: createAction(constants.REMOVE_RISER_SUCCESS),
  removeRiserFailure: createAction(constants.REMOVE_RISER_FAILURE),

  getRiserClamps: createAction(constants.GET_RISER_CLAMPS),
  getRiserClampsSuccess: createAction(constants.GET_RISER_CLAMPS_SUCCESS),
  getRiserClampsFailure: createAction(constants.GET_RISER_CLAMPS_FAILURE),
  getRiserClamp: createAction(constants.GET_RISER_CLAMP),
  getRiserClampSuccess: createAction(constants.GET_RISER_CLAMP_SUCCESS),
  getRiserClampFailure: createAction(constants.GET_RISER_CLAMP_FAILURE),
  editRiserClamp: promiseAction(constants.EDIT_RISER_CLAMP),
  editRiserClampSuccess: createAction(constants.EDIT_RISER_CLAMP_SUCCESS),
  editRiserClampFailure: createAction(constants.EDIT_RISER_CLAMP_FAILURE),
  removeRiserClamp: createAction(constants.REMOVE_RISER_CLAMP),
  removeRiserClampSuccess: createAction(constants.REMOVE_RISER_CLAMP_SUCCESS),
  removeRiserClampFailure: createAction(constants.REMOVE_RISER_CLAMP_FAILURE),

  getCathodicProtection: createAction(constants.GET_CATHODIC_PROTECTION),
  getCathodicProtectionSuccess: createAction(
    constants.GET_CATHODIC_PROTECTION_SUCCESS
  ),
  getCathodicProtectionFailure: createAction(
    constants.GET_CATHODIC_PROTECTION_FAILURE
  ),
  getCPItem: createAction(constants.GET_CP_ITEM),
  getCPItemSuccess: createAction(constants.GET_CP_ITEM_SUCCESS),
  getCPItemFailure: createAction(constants.GET_CP_ITEM_FAILURE),
  editCPItem: promiseAction(constants.EDIT_CP_ITEM),
  editCPItemSuccess: createAction(constants.EDIT_CP_ITEM_SUCCESS),
  editCPItemFailure: createAction(constants.EDIT_CP_ITEM_FAILURE),
  removeCPItem: createAction(constants.REMOVE_CP_ITEM),
  removeCPItemSuccess: createAction(constants.REMOVE_CP_ITEM_SUCCESS),
  removeCPItemFailure: createAction(constants.REMOVE_CP_ITEM_FAILURE),

  getCPCalibration: createAction(constants.GET_CP_CALIBRATION),
  getCPCalibrationSuccess: createAction(constants.GET_CP_CALIBRATION_SUCCESS),
  getCPCalibrationFailure: createAction(constants.GET_CP_CALIBRATION_FAILURE),
  getCPCItem: createAction(constants.GET_CPC_ITEM),
  getCPCItemSuccess: createAction(constants.GET_CPC_ITEM_SUCCESS),
  getCPCItemFailure: createAction(constants.GET_CPC_ITEM_FAILURE),
  editCPCItem: promiseAction(constants.EDIT_CPC_ITEM),
  editCPCItemSuccess: createAction(constants.EDIT_CPC_ITEM_SUCCESS),
  editCPCItemFailure: createAction(constants.EDIT_CPC_ITEM_FAILURE),
  removeCPCItem: createAction(constants.REMOVE_CPC_ITEM),
  removeCPCItemSuccess: createAction(constants.REMOVE_CPC_ITEM_SUCCESS),
  removeCPCItemFailure: createAction(constants.REMOVE_CPC_ITEM_FAILURE),

  getISIMS: createAction(constants.GET_ISIMS),
  getISIMSSuccess: createAction(constants.GET_ISIMS_SUCCESS),
  getISIMSFailure: createAction(constants.GET_ISIMS_FAILURE),
  getISIMSItem: createAction(constants.GET_ISIMS_ITEM),
  getISIMSItemSuccess: createAction(constants.GET_ISIMS_ITEM_SUCCESS),
  getISIMSItemFailure: createAction(constants.GET_ISIMS_ITEM_FAILURE),
  editISIMSItem: promiseAction(constants.EDIT_ISIMS_ITEM),
  editISIMSItemSuccess: createAction(constants.EDIT_ISIMS_ITEM_SUCCESS),
  editISIMSItemFailure: createAction(constants.EDIT_ISIMS_ITEM_FAILURE),
  removeISIMS: createAction(constants.REMOVE_ISIMS_ITEM),
  removeISIMSSuccess: createAction(constants.REMOVE_ISIMS_ITEM_SUCCESS),
  removeISIMSFailure: createAction(constants.REMOVE_ISIMS_ITEM_FAILURE),

  getPlatformPhotosList: createAction(constants.GET_PLATFORM_PHOTOS_LIST),
  getPlatformPhotosListSuccess: createAction(
    constants.GET_PLATFORM_PHOTOS_LIST_SUCCESS
  ),
  getPlatformPhotosListFailure: createAction(
    constants.GET_PLATFORM_PHOTOS_LIST_FAILURE
  ),
  getPlatformPhotos: createAction(constants.GET_PLATFORM_PHOTOS),
  getPlatformPhotosSuccess: createAction(constants.GET_PLATFORM_PHOTOS_SUCCESS),
  getPlatformPhotosFailure: createAction(constants.GET_PLATFORM_PHOTOS_FAILURE),
  clearPlatformPhotos: createAction(constants.CLEAR_PLATFORM_PHOTOS),
  editPlatformPhotos: promiseAction(constants.EDIT_PLATFORM_PHOTOS),
  editPlatformPhotosSuccess: createAction(
    constants.EDIT_PLATFORM_PHOTOS_SUCCESS
  ),
  editPlatformPhotosFailure: createAction(
    constants.EDIT_PLATFORM_PHOTOS_FAILURE
  ),
  removePlatformPhotos: createAction(constants.REMOVE_PLATFORM_PHOTOS),
  removePlatformPhotosSuccess: createAction(constants.REMOVE_PLATFORM_PHOTOS_SUCCESS),
  removePlatformPhotosFailure: createAction(constants.REMOVE_PLATFORM_PHOTOS_FAILURE),

  getWellheads: createAction(constants.GET_WELLHEADS),
  getWellheadsSuccess: createAction(constants.GET_WELLHEADS_SUCCESS),
  getWellheadsFailure: createAction(constants.GET_WELLHEADS_FAILURE),
  getWellhead: createAction(constants.GET_WELLHEAD),
  getWellheadSuccess: createAction(constants.GET_WELLHEAD_SUCCESS),
  getWellheadFailure: createAction(constants.GET_WELLHEAD_FAILURE),
  editWellhead: promiseAction(constants.EDIT_WELLHEAD),
  editWellheadSuccess: createAction(constants.EDIT_WELLHEAD_SUCCESS),
  editWellheadFailure: createAction(constants.EDIT_WELLHEAD_FAILURE),
  removeWellhead: createAction(constants.REMOVE_WELLHEAD),
  removeWellheadSuccess: createAction(constants.REMOVE_WELLHEAD_SUCCESS),
  removeWellheadFailure: createAction(constants.REMOVE_WELLHEAD_FAILURE),


  clearList: createAction(constants.CLEAR_LIST)
};

const serveyIsLoading = (state = false, action: PayloadedAction): boolean => {
  switch (action.type) {
    case constants.GET_ISIMS:
    case constants.GET_RISERS:
    case constants.GET_CONDUCTORS:
    case constants.GET_COMPONENTS:
    case constants.GET_RISER_CLAMPS:
    case constants.GET_CP_CALIBRATION:
    case constants.GET_CATHODIC_PROTECTION:
    case constants.GET_PLATFORM_PHOTOS_LIST:
    case constants.GET_WELLHEADS:
      return true;
    case constants.GET_ISIMS_SUCCESS:
    case constants.GET_ISIMS_FAILURE:
    case constants.GET_RISERS_SUCCESS:
    case constants.GET_RISERS_FAILURE:
    case constants.GET_COMPONENTS_SUCCESS:
    case constants.GET_COMPONENTS_FAILURE:
    case constants.GET_CONDUCTORS_SUCCESS:
    case constants.GET_CONDUCTORS_FAILURE:
    case constants.GET_RISER_CLAMPS_SUCCESS:
    case constants.GET_RISER_CLAMPS_FAILURE:
    case constants.GET_CP_CALIBRATION_SUCCESS:
    case constants.GET_CP_CALIBRATION_FAILURE:
    case constants.GET_CATHODIC_PROTECTION_FAILURE:
    case constants.GET_CATHODIC_PROTECTION_SUCCESS:
    case constants.GET_PLATFORM_PHOTOS_LIST_SUCCESS:
    case constants.GET_PLATFORM_PHOTOS_LIST_FAILURE:
    case constants.GET_WELLHEADS_SUCCESS:
    case constants.GET_WELLHEADS_FAILURE:
      return false;
    default:
      return state;
  }
};

// Components
const initialStateComponents = {
  surveyData: {
    count: 0,
    offset: 0,
    limit: null,
    filter: {},
    next: '',
    sortBy: '',
    search: '',
    previous: '',
    results: []
  },
  failure: null,
  currentComponent: null
};

export const surveyComponents = (
  state = initialStateComponents,
  action: PayloadedAction
): ISurveyComponents => {
  switch (action.type) {
    case constants.GET_COMPONENTS_SUCCESS:
      return {
        ...state,
        surveyData: action.payload
      };
    case constants.GET_COMPONENTS_FAILURE:
      return {
        ...state,
        failure: action.payload
      };
    case constants.EDIT_COMPONENT_SUCCESS:
    case constants.GET_COMPONENT_SUCCESS: {
      return { ...state, currentComponent: action.payload };
    }
    case constants.REMOVE_COMPONENT_SUCCESS:
      return {
        ...state, surveyData: {
          ...state.surveyData,
          results: state.surveyData.results.filter(result => result.id !== action.payload.id)
        }
      }
    case constants.REMOVE_COMPONENT_FAILURE:
      return { ...state, failure: action.payload }
    case constants.CLEAR_COMPONENT:
      return { ...state, currentComponent: null };
    case constants.CLEAR_LIST:
      return {
        ...state,
        surveyData: {
          count: 0,
          offset: 0,
          limit: null,
          filter: {},
          next: '',
          sortBy: '',
          search: '',
          previous: '',
          results: []
        },
        failure: null
      };
    default:
      return state;
  }
};

// Conductors
const initialStateConductors = {
  surveyData: {
    count: 0,
    offset: 0,
    limit: null,
    filter: {},
    next: '',
    sortBy: '',
    search: '',
    previous: '',
    results: []
  },
  currentConductor: null,
  failure: null
};

export const surveyConductors = (
  state = initialStateConductors,
  action: PayloadedAction
): ISurveyConductors => {
  switch (action.type) {
    case constants.GET_CONDUCTORS_SUCCESS:
      return {
        ...state,
        surveyData: action.payload
      };
    case constants.GET_CONDUCTORS_FAILURE:
      return {
        ...state,
        failure: action.payload
      };
    case constants.EDIT_CONDUCTOR_SUCCESS:
    case constants.GET_CONDUCTOR_SUCCESS:
      return { ...state, currentConductor: action.payload };
    case constants.REMOVE_CONDUCTOR_SUCCESS:
      return {
        ...state, surveyData: {
          ...state.surveyData,
          results: state.surveyData.results.filter(result => result.id !== action.payload.id)
        }
      }
    case constants.REMOVE_CONDUCTOR_FAILURE:
      return { ...state, failure: action.payload }
    case constants.CLEAR_LIST:
      return {
        ...state,
        surveyData: {
          count: 0,
          offset: 0,
          limit: null,
          filter: {},
          next: '',
          sortBy: '',
          search: '',
          previous: '',
          results: []
        },
        failure: null
      };
    default:
      return state;
  }
};

// Risers
const initialStateRisers = {
  surveyData: {
    count: 0,
    offset: 0,
    limit: null,
    filter: {},
    next: '',
    sortBy: '',
    search: '',
    previous: '',
    results: []
  },
  currentRiser: null,
  failure: null
};

export const surveyRisers = (
  state = initialStateRisers,
  action: PayloadedAction
): ISurveyRisers => {
  switch (action.type) {
    case constants.GET_RISERS_SUCCESS:
      return {
        ...state,
        surveyData: action.payload
      };
    case constants.GET_RISERS_FAILURE:
      return {
        ...state,
        failure: action.payload
      };
    case constants.EDIT_RISER_SUCCESS:
    case constants.GET_RISER_SUCCESS:
      return { ...state, currentRiser: action.payload };
    case constants.REMOVE_RISER_SUCCESS:
      const { payload: { id } } = action
      return {
        ...state, surveyData: {
          ...state.surveyData,
          results: state.surveyData.results.filter(result => result.id !== id)
        }
      }
    case constants.REMOVE_RISER_FAILURE:
      return {
        ...state,
        failure: action.payload
      }
    case constants.CLEAR_LIST:
      return {
        ...state,
        surveyData: {
          count: 0,
          offset: 0,
          limit: null,
          filter: {},
          next: '',
          sortBy: '',
          search: '',
          previous: '',
          results: []
        },
        failure: null
      };
    default:
      return state;
  }
};

// RiserClamps
const initialStateRiserClamps: ISurveyRiserClamps = {
  surveyData: {
    count: 0,
    offset: 0,
    limit: null,
    filter: {},
    next: '',
    sortBy: '',
    search: '',
    previous: '',
    results: []
  },
  failure: null,
  currentRiserClamp: null
};

export const surveyRiserClamps = (
  state = initialStateRiserClamps,
  action: PayloadedAction
): ISurveyRiserClamps => {
  switch (action.type) {
    case constants.GET_RISER_CLAMPS_SUCCESS:
      return {
        ...state,
        surveyData: action.payload
      };
    case constants.GET_RISER_CLAMPS_FAILURE:
      return {
        ...state,
        failure: action.payload
      };
    case constants.EDIT_RISER_CLAMP_SUCCESS:
    case constants.GET_RISER_CLAMP_SUCCESS:
      return { ...state, currentRiserClamp: action.payload };
    case constants.REMOVE_RISER_CLAMP_SUCCESS:
      return {
        ...state, surveyData: {
          ...state.surveyData,
          results: state.surveyData.results.filter(result => result.id !== action.payload.id)
        }
      }
    case constants.REMOVE_RISER_CLAMP_FAILURE:
      return { ...state, failure: action.payload }
    case constants.CLEAR_LIST:
      return {
        ...state,
        surveyData: {
          count: 0,
          offset: 0,
          limit: null,
          filter: {},
          next: '',
          sortBy: '',
          search: '',
          previous: '',
          results: []
        },
        failure: null
      };
    default:
      return state;
  }
};

// CathodicProtection
const initialStateCathodicProtection: ISurveyCathodicProtection = {
  surveyData: {
    count: 0,
    offset: 0,
    limit: null,
    filter: {},
    next: '',
    sortBy: '',
    search: '',
    previous: '',
    results: []
  },
  failure: null,
  currentCPItem: null
};

export const surveyCathodicProtection = (
  state = initialStateCathodicProtection,
  action: PayloadedAction
): ISurveyCathodicProtection => {
  switch (action.type) {
    case constants.GET_CATHODIC_PROTECTION_SUCCESS:
      return {
        ...state,
        surveyData: action.payload
      };
    case constants.GET_CATHODIC_PROTECTION_FAILURE:
      return {
        ...state,
        failure: action.payload
      };
    case constants.EDIT_CP_ITEM_SUCCESS:
    case constants.GET_CP_ITEM_SUCCESS:
      return { ...state, currentCPItem: action.payload };
    case constants.REMOVE_CP_ITEM_SUCCESS:
      return {
        ...state, surveyData: {
          ...state.surveyData,
          results: state.surveyData.results.filter(result => result.id !== action.payload.id)
        }
      }
    case constants.REMOVE_CP_ITEM_FAILURE:
      return { ...state, failure: action.payload }
    case constants.CLEAR_LIST:
      return {
        ...state,
        surveyData: {
          count: 0,
          offset: 0,
          limit: null,
          filter: {},
          next: '',
          sortBy: '',
          search: '',
          previous: '',
          results: []
        },
        failure: null
      };
    default:
      return state;
  }
};

// CPCalibration
const initialStateCPCalibration = {
  surveyData: {
    count: 0,
    offset: 0,
    limit: null,
    filter: {},
    next: '',
    sortBy: '',
    search: '',
    previous: '',
    results: []
  },
  failure: null,
  currentCPCItem: null
};

export const surveyCPCalibration = (
  state = initialStateCPCalibration,
  action: PayloadedAction
): ISurveyCPCalibration => {
  switch (action.type) {
    case constants.GET_CP_CALIBRATION_SUCCESS:
      return {
        ...state,
        surveyData: action.payload
      };
    case constants.GET_CP_CALIBRATION_FAILURE:
      return {
        ...state,
        failure: action.payload
      };
    case constants.EDIT_CPC_ITEM_SUCCESS:
    case constants.GET_CPC_ITEM_SUCCESS:
      return { ...state, currentCPCItem: action.payload };
    case constants.REMOVE_CPC_ITEM_SUCCESS:
      return {
        ...state, surveyData: {
          ...state.surveyData,
          results: state.surveyData.results.filter(result => result.id !== action.payload.id)
        }
      }
    case constants.REMOVE_CPC_ITEM_FAILURE:
      return { ...state, failure: action.payload }
    case constants.CLEAR_LIST:
      return {
        ...state,
        surveyData: {
          count: 0,
          offset: 0,
          limit: null,
          filter: {},
          next: '',
          sortBy: '',
          search: '',
          previous: '',
          results: []
        },
        failure: null
      };
    default:
      return state;
  }
};

// ISIMS
const initialStateISIMS = {
  surveyData: {
    count: 0,
    offset: 0,
    limit: null,
    filter: {},
    next: '',
    sortBy: '',
    search: '',
    previous: '',
    results: []
  },
  failure: null,
  currentISIMS: null
};

export const surveyISIMS = (
  state = initialStateISIMS,
  action: PayloadedAction
): ISurveyISIMS => {
  switch (action.type) {
    case constants.GET_ISIMS_SUCCESS:
      return {
        ...state,
        surveyData: action.payload
      };
    case constants.GET_ISIMS_FAILURE:
      return {
        ...state,
        failure: action.payload
      };
    case constants.EDIT_ISIMS_ITEM_SUCCESS:
    case constants.GET_ISIMS_ITEM_SUCCESS: {
      return { ...state, currentISIMS: action.payload };
    }
    case constants.REMOVE_ISIMS_ITEM_SUCCESS:
      return {
        ...state, surveyData: {
          ...state.surveyData,
          results: state.surveyData.results.filter(result => result.id !== action.payload.id)
        }
      }
    case constants.REMOVE_ISIMS_ITEM_FAILURE:
      return { ...state, failure: action.payload }
    case constants.CLEAR_LIST:
      return {
        ...state,
        surveyData: {
          count: 0,
          offset: 0,
          limit: null,
          filter: {},
          next: '',
          sortBy: '',
          search: '',
          previous: '',
          results: []
        },
        failure: null
      };
    default:
      return state;
  }
};

// Platform photos
const initialStatePlatformPhotos: ISurveyPlatformPhotosRoot = {
  data: {
    count: 0,
    offset: 0,
    limit: null,
    filter: {},
    next: '',
    sortBy: '',
    search: '',
    previous: '',
    results: []
  },
  failure: null,
  current: null
};

export const surveyPlatfromPhotos = (
  state = initialStatePlatformPhotos,
  action: PayloadedAction
): ISurveyPlatformPhotosRoot => {
  switch (action.type) {
    case constants.GET_PLATFORM_PHOTOS_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload
      };
    case constants.GET_PLATFORM_PHOTOS_LIST_FAILURE:
      return {
        ...state,
        failure: action.payload
      };
    case constants.EDIT_PLATFORM_PHOTOS_SUCCESS:
    case constants.GET_PLATFORM_PHOTOS_SUCCESS: {
      return { ...state, current: action.payload };
    }
    case constants.CLEAR_PLATFORM_PHOTOS:
      return { ...state, current: null };
    case constants.REMOVE_PLATFORM_PHOTOS_SUCCESS:
      return {
        ...state, data: {
          ...state.data,
          results: state.data.results.filter(result => result.id !== action.payload.id)
        }
      }
    case constants.REMOVE_PLATFORM_PHOTOS_FAILURE:
      return { ...state, failure: action.payload }
    case constants.CLEAR_LIST:
      return {
        ...state,
        data: {
          count: 0,
          offset: 0,
          limit: null,
          filter: {},
          next: '',
          sortBy: '',
          search: '',
          previous: '',
          results: []
        },
        failure: null
      };
    default:
      return state;
  }
};

// Wellheads
const initialStateWellheads = {
  surveyData: {
    count: 0,
    offset: 0,
    limit: null,
    filter: {},
    next: '',
    sortBy: '',
    search: '',
    previous: '',
    results: []
  },
  currentWellhead: null,
  failure: null
};

export const surveyWellheads = (
  state = initialStateWellheads,
  action: PayloadedAction
): ISurveyWellheads => {
  switch (action.type) {
    case constants.GET_WELLHEADS_SUCCESS:
      return {
        ...state,
        surveyData: action.payload
      };
    case constants.GET_WELLHEADS_FAILURE:
      return {
        ...state,
        failure: action.payload
      };
    case constants.EDIT_WELLHEAD_SUCCESS:
    case constants.GET_WELLHEAD_SUCCESS:
      return { ...state, currentWellhead: action.payload };
    case constants.REMOVE_WELLHEAD_SUCCESS:
      return {
        ...state, surveyData: {
          ...state.surveyData,
          results: state.surveyData.results.filter(result => result.id !== action.payload.id)
        }
      }
    case constants.REMOVE_WELLHEAD_FAILURE:
      return { ...state, failure: action.payload }
    case constants.CLEAR_LIST:
      return {
        ...state,
        surveyData: {
          count: 0,
          offset: 0,
          limit: null,
          filter: {},
          next: '',
          sortBy: '',
          search: '',
          previous: '',
          results: []
        },
        failure: null
      };
    default:
      return state;
  }
};

const surveyReducers = combineReducers<ISurveyReducer>({
  surveyISIMS,
  surveyRisers,
  serveyIsLoading,
  surveyComponents,
  surveyConductors,
  surveyRiserClamps,
  surveyCPCalibration,
  surveyCathodicProtection,
  surveyPlatfromPhotos,
  surveyWellheads
});

export default surveyReducers;
