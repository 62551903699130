import cx from 'classnames';
import * as React from 'react';

import styles from './Typography.module.scss';

export interface TypographyProps extends React.HTMLProps<HTMLParagraphElement> {
  className: string;
  noWrap: boolean;
  margin: boolean;
  leftGap: boolean;
  pointer: boolean;
  component: any;
  align: 'left' | 'center' | 'right';
  color: 'default' | 'contrast' | 'textSecondary' | 'error';
  variant: 'default' | 'secondary' | 'h3' | 'h5' | 'h6' | 'h7';
}

export const Typography: React.FC<Partial<TypographyProps>> = ({
  className,
  noWrap = false,
  margin = false,
  component: Component = 'p',
  color = 'default',
  variant = 'default',
  align,
  leftGap,
  pointer,
  ...other
}) => (
    <Component
      className={cx(
        styles.typography,
        styles['align_' + align],
        styles['color_' + color],
        styles['variant_' + variant],
        {
          [styles.noWrap]: noWrap,
          [styles.margin]: margin,
          [styles.left_gap]: leftGap,
          [styles.pointer]: pointer
        },
        className
      )}
      {...other}
    ></Component>
  );

export default Typography;
