import * as React from 'react';

import styles from './styles.module.scss';

export interface ListItemProps {
  title?: any;
  text: any;
}

interface Props {
  children: any;
}

export const ListItem: React.FC<ListItemProps> = ({
  title,
  text,
  ...other
}) => (
  <div className={styles.list_item} {...other}>
    {title && <p className={styles.list_item_title}>{title}</p>}
    <div className={styles.list_item_text}>{text}</div>
  </div>
);

export const List: React.FC<Props> = ({ children })=> (
  <div className={styles.list}>
    {children}
  </div>
);

// export const ListItem: React.FC = props => (
//   <div className={styles.list_item} {...props} />
// );
