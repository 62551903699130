import { push } from 'connected-react-router';
import { api } from 'core/config/api';
import { toastr } from 'react-redux-toastr';
/* eslint-disable no-throw-literal */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { catchError, uploadFile } from 'utils/sagaUtils';

import * as constants from './constants';
import { actions } from './duck';
import * as selectors from './selectors';

//Components
export function* fetchComponentsWorker(action) {
  const { search, sortBy, limit, offset, filter } = action.payload;

  const {
    search: prevSearch,
    sortBy: prevSortBy,
    limit: prevLimit,
    offset: prevOffet,
    filter: prevFilter,
  } = yield select(selectors.componentsSelector);

  const { date_created, date_to, date_from, ...other_filters } = filter ?? prevFilter ?? {};

  let queryParams = {
    search: search ?? prevSearch,
    orderBy: sortBy ?? prevSortBy,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0,
    date_created: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? ''
        : date_created.format('YYYY-MM-DD')
      : '',
    date_created__year: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? date_created
        : ''
      : '',
    date_created__range: date_to && date_from 
      ? `${date_to.format('YYYY-MM-DD')}, ${date_from.format('YYYY-MM-DD')}` 
      : '',
    ...other_filters,
  };

  try {
    const fetchGetComponents = () =>
      api
        .get('/surveys/components/', {
          params: { ...queryParams },
        })
        .then((response) => response.data)
        .catch(catchError);
    const result = yield call(fetchGetComponents);

    yield put(
      actions.getComponentsSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        filter: filter ?? prevFilter,
        sortBy: queryParams.orderBy,
        search: queryParams.search,
      })
    );
  } catch (error) {
    yield put(actions.getComponentsFailure(error));
  }
}

function* fetchComponentWorker(action) {
  const {
    payload: { id, force },
  } = action;
  const currentComponent = yield select(selectors.currentComponentSelector);

  if (currentComponent && currentComponent.id === id && !force) return;

  const fetchComponent = () => api.get(`/surveys/components/${id}/`);
  try {
    const response = yield call(fetchComponent);
    const { data, status } = response;
    if (status === 200) {
      yield put(actions.getComponentSuccess(data));
    }
  } catch (error) {
    yield put(actions.getComponentFailure(error.message));
  }
}

function* removeComponentWorker(action) {
  const { payload: { id } } = action

  try {
    yield api.delete(`/surveys/components/${id}/`)
    yield toastr.success('', 'Entry deleted successfully.');
    yield put(actions.removeComponentSuccess({ id }))
    yield put(push(`/surveys/components`));
  } catch (error) {
    toastr.error('', 'Error deleting entry');
    yield put(actions.removeComponentFailure(error))
  }
}

function* editComponentWorker(action) {
  const {
    payload: { data, uploadPhotos },
    meta: { resolve, reject },
  } = action;

  try {
    const saveSurveyComponent = () =>
      api
        .patch(`/surveys/components/${data.id}/`, data)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(saveSurveyComponent);

    if (uploadPhotos && uploadPhotos.length > 0)
      for (var i in uploadPhotos) {
        const [index, photo] = uploadPhotos[i];
        const { caption, file, order } = photo;
        try {
          yield call(() =>
            uploadFile(
              `/surveys/components/${data.id}/upload_link/`,
              { caption, order },
              file
            )
          );
        } catch (error) {
          throw {
            photos: { [index]: error },
          };
        }
      }

    yield put(actions.editComponentSuccess(result));
    yield put(actions.getComponent({ id: data.id, force: true }));
    yield put(actions.getComponents({}));

    yield call(resolve);
    yield put(push(`/surveys/components/${data.id}`));
    toastr.success('Success', 'Component Survey entry saved');
  } catch (error) {
    yield call(reject, error);
    yield put(actions.editComponentFailure(error));
  }
}

//Conductors
export function* fetchConductorsWorker(action) {
  const { search, sortBy, limit, offset, filter } = action.payload;

  const {
    search: prevSearch,
    sortBy: prevSortBy,
    limit: prevLimit,
    offset: prevOffet,
    filter: prevFilter,
  } = yield select(selectors.conductorsSelector);

  const { date_created, date_to, date_from, ...other_filters } = filter ?? prevFilter ?? {};

  let queryParams = {
    search: search ?? prevSearch,
    orderBy: sortBy ?? prevSortBy,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0,
    date_created: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? ''
        : date_created.format('YYYY-MM-DD')
      : '',
    date_created__year: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? date_created
        : ''
      : '',
    date_created__range: date_to && date_from 
      ? `${date_to.format('YYYY-MM-DD')}, ${date_from.format('YYYY-MM-DD')}` 
      : '',
    ...other_filters,
  };

  try {
    const fetchGetConductors = () =>
      api
        .get('/surveys/conductors/', {
          params: { ...queryParams },
        })
        .then((response) => response.data)
        .catch(catchError);
    const result = yield call(fetchGetConductors);

    yield put(
      actions.getConductorsSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        filter: {
          date_created,
          ...other_filters,
        },
        sortBy: queryParams.orderBy,
        search: queryParams.search,
      })
    );
  } catch (error) {
    yield put(actions.getConductorsFailure(error));
  }
}

function* fetchConductorWorker(action) {
  const {
    payload: { id, force },
  } = action;

  const currentConductor = yield select(selectors.currentConductorSelector);

  if (currentConductor && currentConductor.id === id && !force) return;

  try {
    const fetchConductor = () =>
      api.get(`/surveys/conductors/${id}/`).catch(catchError);
    const response = yield call(fetchConductor);
    const { data, status } = response;
    if (status === 200) {
      yield put(actions.getConductorSuccess(data));
    }
  } catch (error) {
    yield put(actions.getConductorFailure(error));
  }
}
function* removeConductorWorker(action) {
  const { payload: { id } } = action

  try {
    yield api.delete(`/surveys/conductors/${id}/`)
    yield toastr.success('', 'Entry deleted successfully.');
    yield put(actions.removeConductorSuccess({ id }))
    yield put(push(`/surveys/conductors`));
  } catch (error) {
    toastr.error('', 'Error deleting entry');
    yield put(actions.removeConductorFailure(error))
  }
}

function* editConductorWorker(action) {
  const {
    payload: { data, uploadPhotos },
    meta: { resolve, reject },
  } = action;

  try {
    const saveSurveyConductor = () =>
      api
        .patch(`/surveys/conductors/${data.id}/`, data)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(saveSurveyConductor);

    if (uploadPhotos && uploadPhotos.length > 0)
      for (var i in uploadPhotos) {
        const [index, photo] = uploadPhotos[i];
        const { caption, file, order } = photo;
        try {
          yield call(() =>
            uploadFile(
              `/surveys/conductors/${data.id}/upload_link/`,
              { caption, order },
              file
            )
          );
        } catch (error) {
          throw {
            photos: { [index]: error },
          };
        }
      }

    yield put(actions.editConductorSuccess(result));
    yield put(actions.getConductor({ id: data.id, force: true }));

    yield call(resolve);
    yield put(push(`/surveys/conductors/${data.id}`));
    yield put(actions.getConductors({}));

    toastr.success('Success', 'Conductor Survey entry saved');
  } catch (error) {
    yield call(reject, error);
    yield put(actions.editConductorFailure(error.message));
  }
}

//Risers
export function* fetchRisersWorker(action) {
  const { search, sortBy, limit, offset, filter } = action.payload;

  const {
    search: prevSearch,
    sortBy: prevSortBy,
    limit: prevLimit,
    offset: prevOffet,
    filter: prevFilter,
  } = yield select(selectors.risersSelector);

  const { date_created, date_to, date_from, ...other_filters } = filter ?? prevFilter ?? {};

  let queryParams = {
    search: search ?? prevSearch,
    orderBy: sortBy ?? prevSortBy,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0,
    date_created: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? ''
        : date_created.format('YYYY-MM-DD')
      : '',
    date_created__year: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? date_created
        : ''
      : '',
    date_created__range: date_to && date_from 
      ? `${date_to.format('YYYY-MM-DD')}, ${date_from.format('YYYY-MM-DD')}` 
      : '',
    ...other_filters,
  };

  try {
    const fetchGetRisers = () =>
      api
        .get('/surveys/risers/', {
          params: { ...queryParams },
        })
        .then((response) => response.data)
        .catch(catchError);
    const result = yield call(fetchGetRisers);

    yield put(
      actions.getRisersSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        filter: filter ?? prevFilter,
        sortBy: queryParams.orderBy,
        search: queryParams.search,
      })
    );
  } catch (error) {
    yield put(actions.getRisersFailure(error));
  }
}

function* fetchRiserWorker(action) {
  const {
    payload: { id, force },
  } = action;

  const currentConductor = yield select(selectors.currentRiserSelector);

  if (currentConductor && currentConductor.id === id && !force) return;

  try {
    const fetchRiser = () =>
      api.get(`/surveys/risers/${id}/`).catch(catchError);
    const response = yield call(fetchRiser);
    const { data, status } = response;
    if (status === 200) {
      yield put(actions.getRiserSuccess(data));
    }
  } catch (error) {
    yield put(actions.getRiserFailure(error));
  }
}

function* removeRiserWorker(action) {
  const { payload: { id } } = action

  try {
    yield api.delete(`/surveys/risers/${id}/`)
    yield toastr.success('', 'Entry deleted successfully.');
    yield put(actions.removeRiserSuccess({ id }))
    yield put(push(`/surveys/risers/`));
  } catch (error) {
    toastr.error('', 'Error deleting entry');
    yield put(actions.removeRiserFailure(error))
  }
}

function* editRiserWorker(action) {
  const {
    payload: {
      data: { photo, photos, ...data },
      uploadPhoto,
      uploadPhotos,
    },
    meta: { resolve, reject },
  } = action;

  try {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) =>
      formData.append(
        key,
        typeof value == 'string' ? value : JSON.stringify(value)
      )
    );

    if (photos?.length === 0) {
      formData.append('empty_photos', 'true');
    } else {
      photos?.forEach((obj, id) => {
        Object.entries(obj).forEach(([key, value]) =>
          formData.append(
            `photos[${id}]${key}`,
            typeof value == 'string' ? value : JSON.stringify(value)
          )
        );
      });
    }

    if (uploadPhoto && uploadPhoto.preview) {
      formData.append('photo', uploadPhoto.file);
    }

    if (uploadPhoto && !uploadPhoto?.preview && photo === null) {
      formData.append('photo', '');
    }

    const saveSurveyRiser = () =>
      api
        .patch(`/surveys/risers/${data.id}/`, formData)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(saveSurveyRiser);

    if (uploadPhotos && uploadPhotos.length > 0)
      for (var i in uploadPhotos) {
        const [index, photo] = uploadPhotos[i];
        const { caption, file, order } = photo;
        try {
          yield call(() =>
            uploadFile(
              `/surveys/risers/${data.id}/upload_link/`,
              { caption, order },
              file
            )
          );
        } catch (error) {
          throw {
            photos: { [index]: error },
          };
        }
      }

    yield put(actions.editRiserSuccess(result));
    yield put(actions.getRiser({ id: data.id, force: true }));
    yield put(actions.getRisers({}));

    yield call(resolve);
    yield put(push(`/surveys/risers/${data.id}`));
    toastr.success('Success', 'Riser Survey entry saved');
  } catch (error) {
    yield call(reject, error);
    yield put(actions.editRiserFailure(error));
  }
}

//RiserClamps
export function* fetchRiserClampsWorker(action) {
  const { search, sortBy, limit, offset, filter } = action.payload;

  const {
    search: prevSearch,
    sortBy: prevSortBy,
    limit: prevLimit,
    offset: prevOffet,
    filter: prevFilter,
  } = yield select(selectors.riserClampsSelector);

  const { date_created, date_to, date_from, ...other_filters } = filter ?? prevFilter ?? {};

  let queryParams = {
    search: search ?? prevSearch,
    orderBy: sortBy ?? prevSortBy,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0,
    date_created: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? ''
        : date_created.format('YYYY-MM-DD')
      : '',
    date_created__year: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? date_created
        : ''
      : '',
    date_created__range: date_to && date_from 
      ? `${date_to.format('YYYY-MM-DD')}, ${date_from.format('YYYY-MM-DD')}` 
      : '',
    ...other_filters,
  };

  try {
    const fetchGetRiserClamps = () =>
      api
        .get('/surveys/riser_clamps/', {
          params: { ...queryParams },
        })
        .then((response) => response.data)
        .catch(catchError);
    const result = yield call(fetchGetRiserClamps);

    yield put(
      actions.getRiserClampsSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        filter: filter ?? prevFilter,
        sortBy: queryParams.orderBy,
        search: queryParams.search,
      })
    );
  } catch (error) {
    yield put(actions.getRiserClampsFailure(error));
  }
}

function* fetchRiserClampWorker(action) {
  const {
    payload: { id, force },
  } = action;

  const currentConductor = yield select(selectors.currentRiserSelector);

  if (currentConductor && currentConductor.id === id && !force) return;

  try {
    const fetchRiserClamp = () =>
      api.get(`/surveys/riser_clamps/${id}/`).catch(catchError);
    const response = yield call(fetchRiserClamp);
    const { data, status } = response;
    if (status === 200) {
      yield put(actions.getRiserClampSuccess(data));
    }
  } catch (error) {
    yield put(actions.getRiserClampFailure(error));
  }
}

function* removeRiserClampWorker(action) {
  const { payload: { id } } = action

  try {
    yield api.delete(`/surveys/riser_clamps/${id}/`)
    yield toastr.success('', 'Entry deleted successfully.');
    yield put(actions.removeRiserClampSuccess({ id }))
    yield put(push(`/surveys/riserClamps`));
  } catch (error) {
    toastr.error('', 'Error deleting entry');
    yield put(actions.removeRiserClampFailure(error))
  }
}

function* editRiserClampWorker(action) {
  const {
    payload: { data, uploadClamps },
    meta: { resolve, reject },
  } = action;
  try {
    const saveSurveyRiserClamp = () =>
      api
        .patch(`/surveys/riser_clamps/${data.id}/`, data)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(saveSurveyRiserClamp);

    if (uploadClamps && uploadClamps.length > 0)
      for (var index in uploadClamps) {
        const {
          does_clamp_have_gasket,
          fasteners_info,
          clamp_elevation,
          platform_clamp_type,
          riser_clamp_type,
          caption,
          order,
          file,
        } = uploadClamps[index];

        try {
          yield call(() =>
            uploadFile(
              `/surveys/riser_clamps/${data.id}/upload_link/`,
              {
                does_clamp_have_gasket,
                fasteners_info,
                clamp_elevation,
                platform_clamp_type,
                riser_clamp_type,
                caption,
                order,
              },
              file
            )
          );
        } catch (error) {
          throw {
            clamp_data: { [index]: error },
          };
        }
      }

    yield put(actions.editRiserClampSuccess(result));
    yield put(actions.getRiserClamp({ id: data.id, force: true }));
    yield put(actions.getRiserClamps({}));

    yield call(resolve);
    yield put(push(`/surveys/riserClamps/${data.id}`));
    toastr.success('Success', 'Riser Clamps Survey entry saved');
  } catch (error) {
    yield call(reject, error);
    yield put(actions.editRiserClampFailure(error));
  }
}

//CathodicProtection
export function* fetchCathodicProtectionWorker(action) {
  const { search, sortBy, limit, offset, filter } = action.payload;

  const {
    search: prevSearch,
    sortBy: prevSortBy,
    limit: prevLimit,
    offset: prevOffet,
    filter: prevFilter,
  } = yield select(selectors.cathodicProtectionSelector);

  const { date_created, date_to, date_from, ...other_filters } = filter ?? prevFilter ?? {};

  let queryParams = {
    search: search ?? prevSearch,
    orderBy: sortBy ?? prevSortBy,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0,
    date_created: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? ''
        : date_created.format('YYYY-MM-DD')
      : '',
    date_created__year: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? date_created
        : ''
      : '',
    date_created__range: date_to && date_from 
      ? `${date_to.format('YYYY-MM-DD')}, ${date_from.format('YYYY-MM-DD')}` 
      : '',
    ...other_filters,
  };

  try {
    const fetchCathodicProtection = () =>
      api
        .get('/surveys/cathodic_protections/', {
          params: { ...queryParams },
        })
        .then((response) => response.data)
        .catch(catchError);
    const result = yield call(fetchCathodicProtection);

    yield put(
      actions.getCathodicProtectionSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        filter: filter ?? prevFilter,
        sortBy: queryParams.orderBy,
        search: queryParams.search,
      })
    );
  } catch (error) {
    yield put(actions.getCathodicProtectionFailure(error));
  }
}

function* fetchCPItemWorker(action) {
  const {
    payload: { id, force },
  } = action;

  const item = yield select(selectors.currentCPItemSelector);

  if (item && id === item.id && !force) return;

  try {
    const fetchCPItem = () =>
      api.get(`/surveys/cathodic_protections/${id}/`).catch(catchError);
    const response = yield call(fetchCPItem);
    const { data, status } = response;
    if (status === 200) {
      yield put(actions.getCPItemSuccess(data));
    }
  } catch (error) {
    yield put(actions.getCPItemFailure(error));
  }
}
function* removeCPItemWorker(action) {
  const { payload: { id } } = action

  try {
    yield api.delete(`/surveys/cathodic_protections/${id}/`)
    yield toastr.success('', 'Entry deleted successfully.');
    yield put(actions.removeCPItemSuccess({ id }))
    yield put(push(`/surveys/cathodicProtection`));
  } catch (error) {
    toastr.error('', 'Error deleting entry');
    yield put(actions.removeCPItemFailure(error))
  }
}
function* editCPItemWorker(action) {
  const {
    payload: { data, uploadPhotos },
    meta: { resolve, reject },
  } = action;

  try {
    const saveSurveyCPItem = () =>
      api
        .patch(`/surveys/cathodic_protections/${data.id}/`, data)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(saveSurveyCPItem);

    if (uploadPhotos && uploadPhotos.length > 0)
      for (var i in uploadPhotos) {
        const [index, photo] = uploadPhotos[i];
        const { caption, file, order } = photo;
        try {
          yield call(() =>
            uploadFile(
              `/surveys/cathodic_protections/${data.id}/upload_link/`,
              { caption, order },
              file
            )
          );
        } catch (error) {
          throw {
            photos: { [index]: error },
          };
        }
      }

    yield put(actions.editCPItemSuccess(result));
    yield put(actions.getCPItem({ id: data.id, force: true }));
    yield put(actions.getCathodicProtection({}));

    yield call(resolve);
    yield put(push(`/surveys/cathodicProtection/${data.id}`));
    toastr.success('Success', 'Cathodic Protection Survey entry saved');
  } catch (error) {
    yield call(reject, error);
    yield put(actions.editCPItemFailure(error));
  }
}

//CPCalibration
export function* fetchCPCalibrationWorker(action) {
  const { search, sortBy, limit, offset, filter } = action.payload;

  const {
    search: prevSearch,
    sortBy: prevSortBy,
    limit: prevLimit,
    offset: prevOffet,
    filter: prevFilter,
  } = yield select(selectors.CPCalibrationSelector);

  const { date_created, date_to, date_from, ...other_filters } = filter ?? prevFilter ?? {};

  let queryParams = {
    search: search ?? prevSearch,
    orderBy: sortBy ?? prevSortBy,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0,
    date_created: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? ''
        : date_created.format('YYYY-MM-DD')
      : '',
    date_created__year: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? date_created
        : ''
      : '',
    date_created__range: date_to && date_from 
      ? `${date_to.format('YYYY-MM-DD')}, ${date_from.format('YYYY-MM-DD')}` 
      : '',
    ...other_filters,
  };

  try {
    const fetchCPCalibration = () =>
      api
        .get('/surveys/cathodic_protection_calibrations/', {
          params: { ...queryParams },
        })
        .then((response) => response.data)
        .catch(catchError);
    const result = yield call(fetchCPCalibration);

    yield put(
      actions.getCPCalibrationSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        filter: filter ?? prevFilter,
        sortBy: queryParams.orderBy,
        search: queryParams.search,
      })
    );
  } catch (error) {
    yield put(actions.getCPCalibrationFailure(error));
  }
}

function* fetchCPCItemWorker(action) {
  const {
    payload: { id, force },
  } = action;

  const item = yield select(selectors.currentCPItemSelector);

  if (item && id === item.id && !force) return;

  try {
    const fetchCPItem = () =>
      api
        .get(`/surveys/cathodic_protection_calibrations/${id}/`)
        .catch(catchError);
    const response = yield call(fetchCPItem);
    const { data, status } = response;
    if (status === 200) {
      yield put(actions.getCPCItemSuccess(data));
    }
  } catch (error) {
    yield put(actions.getCPCItemFailure());
  }
}
function* removeCPCItemWorker(action) {
  const { payload: { id } } = action

  try {
    yield api.delete(`/surveys/cathodic_protection_calibrations/${id}/`)
    yield toastr.success('', 'Entry deleted successfully.');
    yield put(actions.removeCPCItemSuccess({ id }))
    yield put(push(`/surveys/CPCalibration`));
  } catch (error) {
    toastr.error('', 'Error deleting entry');
    yield put(actions.removeCPCItemFailure(error))
  }
}
function* editCPCItemWorker(action) {
  const {
    payload: { data, uploadPhotos },
    meta: { resolve, reject },
  } = action;
  try {
    const saveSurveyCPItem = () =>
      api
        .patch(`/surveys/cathodic_protection_calibrations/${data.id}/`, data)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(saveSurveyCPItem);

    if (uploadPhotos && uploadPhotos.length > 0)
      for (var i in uploadPhotos) {
        const [index, photo] = uploadPhotos[i];
        const { caption, file, order } = photo;
        try {
          yield call(() =>
            uploadFile(
              `/surveys/cathodic_protection_calibrations/${data.id}/upload_link/`,
              { caption, order },
              file
            )
          );
        } catch (error) {
          throw {
            photos: { [index]: error },
          };
        }
      }

    yield put(actions.editCPCItemSuccess(result));
    yield put(actions.getCPCItem({ id: data.id, force: true }));
    yield put(actions.getCPCalibration({}));

    yield call(resolve);
    yield put(push(`/surveys/CPCalibration/${data.id}`));
    toastr.success(
      'Success',
      'Cathodic Protection Calibration Survey entry saved'
    );
  } catch (error) {
    yield call(reject, error);
    yield put(actions.editCPCItemFailure(error));
  }
}

//ISIMS
export function* fetchISIMSWorker(action) {
  const { search, sortBy, limit, offset, filter } = action.payload;

  const {
    search: prevSearch,
    sortBy: prevSortBy,
    limit: prevLimit,
    offset: prevOffet,
    filter: prevFilter,
  } = yield select(selectors.listISIMSSelector);

  const { date_created, date_to, date_from, ...other_filters } = filter ?? prevFilter ?? {};

  let queryParams = {
    search: search ?? prevSearch,
    orderBy: sortBy ?? prevSortBy,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0,
    date_created: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? ''
        : date_created.format('YYYY-MM-DD')
      : '',
    date_created__year: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? date_created
        : ''
      : '',
    date_created__range: date_to && date_from 
      ? `${date_to.format('YYYY-MM-DD')}, ${date_from.format('YYYY-MM-DD')}` 
      : '',
    ...other_filters,
  };

  try {
    const fetchISIMS = () =>
      api
        .get('/surveys/isims/', {
          params: { ...queryParams },
        })
        .then((response) => response.data)
        .catch(catchError);
    const result = yield call(fetchISIMS);

    yield put(
      actions.getISIMSSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        filter: filter ?? prevFilter,
        sortBy: queryParams.orderBy,
        search: queryParams.search,
      })
    );
  } catch (error) {
    yield put(actions.getISIMSFailure(error));
  }
}

function* fetchISIMSItemWorker(action) {
  const {
    payload: { id, force },
  } = action;

  const item = yield select(selectors.currentCPItemSelector);

  if (item && id === item.id && !force) return;

  try {
    const fetchCPItem = () =>
      api.get(`/surveys/isims/${id}/`).catch(catchError);
    const response = yield call(fetchCPItem);
    const { data, status } = response;
    if (status === 200) {
      yield put(actions.getISIMSItemSuccess(data));
    }
  } catch (error) {
    yield put(actions.getISIMSItemFailure(error));
  }
}
function* removeISIMSItemWorker(action) {
  const { payload: { id } } = action

  try {
    yield api.delete(`/surveys/isims/${id}/`)
    yield toastr.success('', 'Entry deleted successfully.');
    yield put(actions.removeISIMSSuccess({ id }))
    yield put(push(`/surveys/isims`));
  } catch (error) {
    toastr.error('', 'Error deleting entry');
    yield put(actions.removeISIMSFailure(error))
  }
}

function* editISIMSItemWorker(action) {
  const {
    payload: { data, uploadPhotos },
    meta: { resolve, reject },
  } = action;
  try {
    const saveSurveyCPItem = () =>
      api
        .patch(`/surveys/isims/${data.id}/`, data)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(saveSurveyCPItem);

    if (uploadPhotos && uploadPhotos.length > 0)
      for (var i in uploadPhotos) {
        const [index, photo] = uploadPhotos[i];
        const { caption, file, order } = photo;
        try {
          yield call(() =>
            uploadFile(
              `/surveys/isims/${data.id}/upload_link/`,
              { caption, order },
              file
            )
          );
        } catch (error) {
          throw {
            photos: { [index]: error },
          };
        }
      }

    yield put(actions.editISIMSItemSuccess(result));
    yield put(actions.getISIMSItem({ id: data.id, force: true }));
    yield put(actions.getISIMS({}));

    yield call(resolve);
    yield put(push(`/surveys/ISIMS/${data.id}`));
    toastr.success('Success', 'ISIMS Survey entry saved');
  } catch (error) {
    yield call(reject, error);
    yield put(actions.editISIMSItemFailure(error));
  }
}

//Components
export function* fetchPlatformPhotosListWorker(action) {
  const { search, sortBy, limit, offset, filter } = action.payload;

  const {
    search: prevSearch,
    sortBy: prevSortBy,
    limit: prevLimit,
    offset: prevOffet,
    filter: prevFilter,
  } = yield select(selectors.platformPhotosListSelector);

  const { date_created, date_to, date_from, ...other_filters } = filter ?? prevFilter ?? {};

  let queryParams = {
    search: search ?? prevSearch,
    orderBy: sortBy ?? prevSortBy,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0,
    date_created: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? ''
        : date_created.format('YYYY-MM-DD')
      : '',
    date_created__year: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? date_created
        : ''
      : '',
    date_created__range: date_to && date_from 
      ? `${date_to.format('YYYY-MM-DD')}, ${date_from.format('YYYY-MM-DD')}` 
      : '',
    ...other_filters,
  };

  try {
    const fetch = () =>
      api
        .get('/surveys/platform_photos/', {
          params: { ...queryParams },
        })
        .then((response) => response.data)
        .catch(catchError);
    const result = yield call(fetch);

    yield put(
      actions.getPlatformPhotosListSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        filter: filter ?? prevFilter,
        sortBy: queryParams.orderBy,
        search: queryParams.search,
      })
    );
  } catch (error) {
    yield put(actions.getPlatformPhotosListFailure(error.message));
  }
}

function* fetchPlatformPhotosWorker(action) {
  const {
    payload: { id, force },
  } = action;
  const item = yield select(selectors.currentPlatfromPhotoSelector);

  if (item && item.id === id && !force) return;

  try {
    const fetch = () =>
      api.get(`/surveys/platform_photos/${id}/`).catch(catchError);
    const response = yield call(fetch);
    const { data, status } = response;
    if (status === 200) {
      yield put(actions.getPlatformPhotosSuccess(data));
    }
  } catch (error) {
    yield put(actions.getPlatformPhotosFailure(error));
  }
}
function* removePlatformPhotosWorker(action) {
  const { payload: { id } } = action

  try {
    yield api.delete(`/surveys/platform_photos/${id}/`)
    yield toastr.success('', 'Entry deleted successfully.');
    yield put(actions.removePlatformPhotosSuccess({ id }))
    yield put(push(`/surveys/platformPhotos`));
  } catch (error) {
    toastr.error('', 'Error deleting entry');
    yield put(actions.removePlatformPhotosFailure(error))
  }
}

function* editPlatformPhotosWorker(action) {
  const {
    payload: { data, uploadPlatformPhotos, uploadPhotos },
    meta: { resolve, reject },
  } = action;
  try {
    const save = () =>
      api
        .patch(`/surveys/platform_photos/${data.id}/`, data)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(save);

    if (uploadPlatformPhotos && uploadPlatformPhotos.length > 0)
      for (let i in uploadPlatformPhotos) {
        const [index, photo] = uploadPlatformPhotos[i];
        const { caption, side, file, order } = photo;
        try {
          yield call(() =>
            uploadFile(
              `/surveys/platform_photos/${data.id}/upload_link/`,
              { caption, side, order },
              file
            )
          );
        } catch (error) {
          throw {
            platform_photos: { [index]: error },
          };
        }
      }

    if (uploadPhotos && uploadPhotos.length > 0)
      for (let i in uploadPhotos) {
        const [index, photo] = uploadPhotos[i];
        const { caption, file, order } = photo;
        try {
          yield call(() =>
            uploadFile(
              `/surveys/platform_photos/${data.id}/upload_link/?is_sign_photo=true`,
              { caption, order },
              file
            )
          );
        } catch (error) {
          throw {
            photos: { [index]: error },
          };
        }
      }

    yield put(actions.editPlatformPhotosSuccess(result));
    yield put(actions.getPlatformPhotos({ id: data.id, force: true }));
    yield put(actions.getPlatformPhotosList({}));

    yield call(resolve);
    yield put(push(`/surveys/platformPhotos/${data.id}`));
    toastr.success('Success', 'Platform Photos Survey entry saved');
  } catch (error) {
    yield call(reject, error);
    yield put(actions.editPlatformPhotosFailure(error));
  }
}

//Wellheads
export function* fetchWellheadsWorker(action) {
  const { search, sortBy, limit, offset, filter } = action.payload;

  const {
    search: prevSearch,
    sortBy: prevSortBy,
    limit: prevLimit,
    offset: prevOffet,
    filter: prevFilter,
  } = yield select(selectors.wellheadsSelector);

  const { date_created, date_to, date_from, ...other_filters } = filter ?? prevFilter ?? {};

  let queryParams = {
    search: search ?? prevSearch,
    orderBy: sortBy ?? prevSortBy,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0,
    date_created: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? ''
        : date_created.format('YYYY-MM-DD')
      : '',
    date_created__year: date_created
      ? typeof date_created === 'string' || typeof date_created === 'number'
        ? date_created
        : ''
      : '',
    date_created__range: date_to && date_from 
      ? `${date_to.format('YYYY-MM-DD')}, ${date_from.format('YYYY-MM-DD')}` 
      : '',
    ...other_filters,
  };

  try {
    const fetchGetWellheads = () =>
      api
        .get('/surveys/wellheads/', {
          params: { ...queryParams },
        })
        .then((response) => response.data)
        .catch(catchError);
    const result = yield call(fetchGetWellheads);

    yield put(
      actions.getWellheadsSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        filter: {
          date_created,
          ...other_filters,
        },
        sortBy: queryParams.orderBy,
        search: queryParams.search,
      })
    );
  } catch (error) {
    yield put(actions.getWellheadsFailure(error));
  }
}

function* fetchWellheadWorker(action) {
  const {
    payload: { id, force },
  } = action;

  const currentWellhead = yield select(selectors.currentWellheadSelector);

  if (currentWellhead && currentWellhead.id === id && !force) return;

  try {
    const fetchWellhead = () =>
      api.get(`/surveys/wellheads/${id}/`).catch(catchError);
    const response = yield call(fetchWellhead);
    const { data, status } = response;
    if (status === 200) {
      yield put(actions.getWellheadSuccess(data));
    }
  } catch (error) {
    yield put(actions.getWellheadFailure(error));
  }
}

function* removeWellheadWorker(action) {
  const { payload: { id } } = action

  try {
    yield api.delete(`/surveys/wellheads/${id}/`)
    yield toastr.success('', 'Entry deleted successfully.');
    yield put(actions.removeWellheadSuccess({ id }))
    yield put(push(`/surveys/wellheads`));
  } catch (error) {
    toastr.error('', 'Error deleting entry');
    yield put(actions.removeWellheadFailure(error))
  }
}

function* editWellheadWorker(action) {
  const {
    payload: { data, uploadPhotos },
    meta: { resolve, reject },
  } = action;

  try {
    const saveSurveyWellhead = () =>
      api
        .patch(`/surveys/wellheads/${data.id}/`, data)
        .then((response) => response.data)
        .catch(catchError);

    const result = yield call(saveSurveyWellhead);

    if (uploadPhotos && uploadPhotos.length > 0)
      for (var i in uploadPhotos) {
        const [index, photo] = uploadPhotos[i];
        const { caption, file, order } = photo;
        try {
          yield call(() =>
            uploadFile(
              `/surveys/wellheads/${data.id}/upload_link/`,
              { caption, order },
              file
            )
          );
        } catch (error) {
          throw {
            photos: { [index]: error },
          };
        }
      }

    yield put(actions.editWellheadSuccess(result));
    yield put(actions.getWellhead({ id: data.id, force: true }));

    yield call(resolve);
    yield put(push(`/surveys/wellheads/${data.id}`));
    yield put(actions.getWellheads({}));

    toastr.success('Success', 'Wellhead Survey entry saved');
  } catch (error) {
    yield call(reject, error);
    yield put(actions.editWellheadFailure(error.message));
  }
}

export default function* surveySaga() {
  yield takeLatest(constants.GET_COMPONENTS, fetchComponentsWorker);
  yield takeLatest(constants.GET_CONDUCTORS, fetchConductorsWorker);
  yield takeLatest(constants.GET_RISERS, fetchRisersWorker);
  yield takeLatest(constants.GET_RISER_CLAMPS, fetchRiserClampsWorker);
  yield takeLatest(
    constants.GET_CATHODIC_PROTECTION,
    fetchCathodicProtectionWorker
  );
  yield takeLatest(constants.GET_CP_CALIBRATION, fetchCPCalibrationWorker);
  yield takeLatest(constants.GET_ISIMS, fetchISIMSWorker);
  yield takeLatest(
    constants.GET_PLATFORM_PHOTOS_LIST,
    fetchPlatformPhotosListWorker
  );

  yield takeLatest(constants.GET_COMPONENT, fetchComponentWorker);
  yield takeLatest(constants.EDIT_COMPONENT, editComponentWorker);
  yield takeLatest(constants.REMOVE_COMPONENT, removeComponentWorker)

  yield takeLatest(constants.GET_CONDUCTOR, fetchConductorWorker);
  yield takeLatest(constants.EDIT_CONDUCTOR, editConductorWorker);
  yield takeLatest(constants.REMOVE_CONDUCTOR, removeConductorWorker)

  yield takeLatest(constants.GET_RISER, fetchRiserWorker);
  yield takeLatest(constants.EDIT_RISER, editRiserWorker);
  yield takeLatest(constants.REMOVE_RISER, removeRiserWorker)

  yield takeLatest(constants.GET_RISER_CLAMP, fetchRiserClampWorker);
  yield takeLatest(constants.EDIT_RISER_CLAMP, editRiserClampWorker);
  yield takeLatest(constants.REMOVE_RISER_CLAMP, removeRiserClampWorker)

  yield takeLatest(constants.GET_CP_ITEM, fetchCPItemWorker);
  yield takeLatest(constants.EDIT_CP_ITEM, editCPItemWorker);
  yield takeLatest(constants.REMOVE_CP_ITEM, removeCPItemWorker)

  yield takeLatest(constants.GET_CPC_ITEM, fetchCPCItemWorker);
  yield takeLatest(constants.EDIT_CPC_ITEM, editCPCItemWorker);
  yield takeLatest(constants.REMOVE_CPC_ITEM, removeCPCItemWorker)

  yield takeLatest(constants.GET_ISIMS_ITEM, fetchISIMSItemWorker);
  yield takeLatest(constants.EDIT_ISIMS_ITEM, editISIMSItemWorker);
  yield takeLatest(constants.REMOVE_ISIMS_ITEM, removeISIMSItemWorker)

  yield takeLatest(constants.GET_PLATFORM_PHOTOS, fetchPlatformPhotosWorker);
  yield takeLatest(constants.EDIT_PLATFORM_PHOTOS, editPlatformPhotosWorker);
  yield takeLatest(constants.REMOVE_PLATFORM_PHOTOS, removePlatformPhotosWorker)

  yield takeLatest(constants.GET_WELLHEADS, fetchWellheadsWorker);
  yield takeLatest(constants.GET_WELLHEAD, fetchWellheadWorker);
  yield takeLatest(constants.EDIT_WELLHEAD, editWellheadWorker);
  yield takeLatest(constants.REMOVE_WELLHEAD, removeWellheadWorker)
}
