import cx from 'classnames';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './styles.module.scss';

const back = require('assets/icons/back.svg');

export const ToolbarSubTitle = props => (
  <div className={styles.toolbar_subtite} {...props}></div>
);

export const ToolbarTitle = props => (
  <div className={styles.toolbar_tite} {...props}></div>
);

export const PaperToolbar = props => (
  <div className={styles.paper_toolbar} {...props}></div>
);

export const PaperNavbar = props => (
  <div className={styles.paper_navbar} {...props}></div>
);

export const Toolbar = props => (
  <div className={styles.toolbar} {...props}></div>
);

export const ToolbarNav = props => (
  <div className={styles.toolbar_nav} {...props}></div>
);

export const ToolbarBack: React.FC<any> = ({ text, path = '/' }) => (
  <div className={styles.toolbar_back}>
    <NavLink to={path}>
      <img src={back} alt="" />
    </NavLink>
    <ToolbarTitle>{text}</ToolbarTitle>
  </div>
);

export const ToolbarNavItem: React.FC<any> = ({
  className,
  status = null,
  component: Component = 'div',
  ...other
}) => (
  <Component
    className={cx(
      {
        [styles.toolbar_item]: true,
        [styles['toolbar_item_' + status]]: status
      },
      className
    )}
    {...other}
  />
);

export const ToolbarNavLinkItem: React.FC<any> = ({
  path = '/platforms',
  text = ''
}) => (
  <NavLink
    to={path}
    className={styles.link}
    activeClassName={styles.link_active}
  >
    {text}
  </NavLink>
);

export default Toolbar;
