const STORE_TYPE = 'platforms';

export const GET_PLATFORMS = `${STORE_TYPE}/GET_PLATFORMS`;
export const GET_PLATFORMS_SUCCESS = `${STORE_TYPE}/GET_PLATFORMS_SUCCESS`;
export const GET_PLATFORMS_FAILURE = `${STORE_TYPE}/GET_PLATFORMS_FAILURE`;

export const CLEAR_PLATFORMS = `${STORE_TYPE}/CLEAR_PLATFORMS`;
export const CLEAR_PLATFORM_COMPONENT = `${STORE_TYPE}/CLEAR_PLATFORM_COMPONENT`;

export const GET_PLATFORM = `${STORE_TYPE}/GET_PLATFORM`;
export const GET_PLATFORM_SUCCESS = `${STORE_TYPE}/GET_PLATFORM_SUCCESS`;
export const GET_PLATFORM_FAILURE = `${STORE_TYPE}/GET_PLATFORM_FAILURE`;

export const EDIT_PLATFORM_INFORMATION = `${STORE_TYPE}/EDIT_PLATFORM_INFORMATION`;
export const EDIT_PLATFORM_INFORMATION_SUCCESS = `${STORE_TYPE}/EDIT_PLATFORM_INFORMATION_SUCCESS`;
export const EDIT_PLATFORM_INFORMATION_FAILURE = `${STORE_TYPE}/EDIT_PLATFORM_INFORMATION_FAILURE`;

export const GET_PIPELINES = `${STORE_TYPE}/GET_PIPELINES`;
export const GET_PIPELINES_SUCCESS = `${STORE_TYPE}/GET_PIPELINES_SUCCESS`;
export const GET_PIPELINES_FAILURE = `${STORE_TYPE}/GET_PIPELINES_FAILURE`;

export const GET_PIPELINE = `${STORE_TYPE}/GET_PIPELINE`;
export const GET_PIPELINE_SUCCESS = `${STORE_TYPE}/GET_PIPELINE_SUCCESS`;
export const GET_PIPELINE_FAILURE = `${STORE_TYPE}/GET_PIPELINE_FAILURE`;

export const EDIT_PIPELINE = `${STORE_TYPE}/EDIT_PIPELINE`;
export const EDIT_PIPELINE_SUCCESS = `${STORE_TYPE}/EDIT_PIPELINE_SUCCESS`;
export const EDIT_PIPELINE_FAILURE = `${STORE_TYPE}/EDIT_PIPELINE_FAILURE`;

export const ADD_PIPELINE = `${STORE_TYPE}/ADD_PIPELINE`;
export const ADD_PIPELINE_SUCCESS = `${STORE_TYPE}/ADD_PIPELINE_SUCCESS`;
export const ADD_PIPELINE_FAILURE = `${STORE_TYPE}/ADD_PIPELINE_FAILURE`;

export const GET_WELLS = `${STORE_TYPE}/GET_WELLS`;
export const GET_WELLS_SUCCESS = `${STORE_TYPE}/GET_WELLS_SUCCESS`;
export const GET_WELLS_FAILURE = `${STORE_TYPE}/GET_WELLS_FAILURE`;

export const GET_WELL = `${STORE_TYPE}/GET_WELL`;
export const GET_WELL_SUCCESS = `${STORE_TYPE}/GET_WELL_SUCCESS`;
export const GET_WELL_FAILURE = `${STORE_TYPE}/GET_WELL_FAILURE`;

export const EDIT_WELL = `${STORE_TYPE}/EDIT_WELL`;
export const EDIT_WELL_SUCCESS = `${STORE_TYPE}/EDIT_WELL_SUCCESS`;
export const EDIT_WELL_FAILURE = `${STORE_TYPE}/EDIT_WELL_FAILURE`;

export const ADD_WELL = `${STORE_TYPE}/ADD_WELL`;
export const ADD_WELL_SUCCESS = `${STORE_TYPE}/ADD_WELL_SUCCESS`;
export const ADD_WELL_FAILURE = `${STORE_TYPE}/ADD_WELL_FAILURE`;

export const ADD_PLATFORM = `${STORE_TYPE}/ADD_PLATFORM`;
export const ADD_PLATFORM_SUCCESS = `${STORE_TYPE}/ADD_PLATFORM_SUCCESS`;
export const ADD_PLATFORM_FAILURE = `${STORE_TYPE}/ADD_PLATFORM_FAILURE`;

export const GET_WORK_ORDERS = `${STORE_TYPE}/GET_WORK_ORDERS`;
export const GET_WORK_ORDERS_SUCCESS = `${STORE_TYPE}/GET_WORK_ORDERS_SUCCESS`;
export const GET_WORK_ORDERS_FAILURE = `${STORE_TYPE}/GET_WORK_ORDERS_FAILURE`;

export const CREATE_WORK_ORDER = `${STORE_TYPE}/CREATE_WORK_ORDER`;
export const CREATE_WORK_ORDER_SUCCESS = `${STORE_TYPE}/CREATE_WORK_ORDER_SUCCESS`;
export const CREATE_WORK_ORDER_FAILURE = `${STORE_TYPE}/CREATE_WORK_ORDER_FAILURE`;

export const GET_WORK_ORDER = `${STORE_TYPE}/GET_WORK_ORDER`;
export const GET_WORK_ORDER_SUCCESS = `${STORE_TYPE}/GET_WORK_ORDER_SUCCESS`;
export const GET_WORK_ORDER_FAILURE = `${STORE_TYPE}/GET_WORK_ORDER_FAILURE`;

export const EDIT_WORK_ORDER = `${STORE_TYPE}/EDIT_WORK_ORDER`;
export const EDIT_WORK_ORDER_SUCCESS = `${STORE_TYPE}/EDIT_WORK_ORDER_SUCCESS`;
export const EDIT_WORK_ORDER_FAILURE = `${STORE_TYPE}/EDIT_WORK_ORDER_FAILURE`;

export const GET_WELL_SLOTS = `${STORE_TYPE}/GET_WELL_SLOTS`;
export const GET_WELL_SLOTS_SUCCESS = `${STORE_TYPE}/GET_WELL_SLOTS_SUCCESS`;
export const GET_WELL_SLOTS_FAILURE = `${STORE_TYPE}/GET_WELL_SLOTS_FAILURE`;

export const GET_WELL_SLOT = `${STORE_TYPE}/GET_WELL_SLOT`;
export const GET_WELL_SLOT_SUCCESS = `${STORE_TYPE}/GET_WELL_SLOT_SUCCESS`;
export const GET_WELL_SLOT_FAILURE = `${STORE_TYPE}/GET_WELL_SLOT_FAILURE`;

export const EDIT_WELL_SLOT = `${STORE_TYPE}/EDIT_WELL_SLOT`;
export const EDIT_WELL_SLOT_SUCCESS = `${STORE_TYPE}/EDIT_WELL_SLOT_SUCCESS`;
export const EDIT_WELL_SLOT_FAILURE = `${STORE_TYPE}/EDIT_WELL_SLOT_FAILURE`;

export const ADD_WELL_SLOT = `${STORE_TYPE}/ADD_WELL_SLOT`;
export const ADD_WELL_SLOT_SUCCESS = `${STORE_TYPE}/ADD_WELL_SLOT_SUCCESS`;
export const ADD_WELL_SLOT_FAILURE = `${STORE_TYPE}/ADD_WELL_SLOT_FAILURE`;

export const GET_REPORTS = `${STORE_TYPE}/GET_REPORTS`;
export const GET_REPORTS_SUCCESS = `${STORE_TYPE}/GET_REPORTS_SUCCESS`;
export const GET_REPORTS_FAILURE = `${STORE_TYPE}/GET_REPORTS_FAILURE`;

export const CREATE_REPORT = `${STORE_TYPE}/CREATE_REPORT`;
export const CREATE_REPORT_SUCCESS = `${STORE_TYPE}/CREATE_REPORT_SUCCESS`;
export const CREATE_REPORT_FAILURE = `${STORE_TYPE}/CREATE_REPORT_FAILURE`;

export const GET_REPORT = `${STORE_TYPE}/GET_REPORT`;
export const GET_REPORT_SUCCESS = `${STORE_TYPE}/GET_REPORT_SUCCESS`;
export const GET_REPORT_FAILURE = `${STORE_TYPE}/GET_REPORT_FAILURE`;

export const DELETE_REPORT = `${STORE_TYPE}/DELETE_REPORT`;
export const DELETE_REPORT_SUCCESS = `${STORE_TYPE}/DELETE_REPORT_SUCCESS`;
export const DELETE_REPORT_FAILURE = `${STORE_TYPE}/DELETE_REPORT_FAILURE`;

export const EDIT_REPORT = `${STORE_TYPE}/EDIT_REPORT`;
export const EDIT_REPORT_SUCCESS = `${STORE_TYPE}/EDIT_REPORT_SUCCESS`;
export const EDIT_REPORT_FAILURE = `${STORE_TYPE}/EDIT_REPORT_FAILURE`;

export const GET_RETINGS = `${STORE_TYPE}/GET_RETINGS`;
export const GET_RETINGS_SUCCESS = `${STORE_TYPE}/GET_RETINGS_SUCCESS`;
export const GET_RETINGS_FAILURE = `${STORE_TYPE}/GET_RETINGS_FAILURE`;

export const EDIT_RETINGS = `${STORE_TYPE}/EDIT_RETINGS`;
export const EDIT_RETINGS_SUCCESS = `${STORE_TYPE}/EDIT_RETINGS_SUCCESS`;
export const EDIT_RETINGS_FAILURE = `${STORE_TYPE}/EDIT_RETINGS_FAILURE`;

export const GET_STATUSES = `${STORE_TYPE}/GET_STATUSES`;
export const GET_STATUSES_SUCCESS = `${STORE_TYPE}/GET_STATUSES_SUCCESS`;
export const GET_STATUSES_FAILURE = `${STORE_TYPE}/GET_STATUSES_FAILURE`;

export const EDIT_STATUSES = `${STORE_TYPE}/EDIT_STATUSES`;
export const EDIT_STATUSES_SUCCESS = `${STORE_TYPE}/EDIT_STATUSES_SUCCESS`;
export const EDIT_STATUSES_FAILURE = `${STORE_TYPE}/EDIT_STATUSES_FAILURE`;

export const GET_ANALYTICS = `${STORE_TYPE}/GET_ANALYTICS`;
export const GET_ANALYTICS_SUCCESS = `${STORE_TYPE}/GET_ANALYTICS_SUCCESS`;
export const GET_ANALYTICS_FAILURE = `${STORE_TYPE}/GET_ANALYTICS_FAILURE`;

export const CLEAR_REPORTS_ERROR = `${STORE_TYPE}/CLEAR_REPORTS_ERROR`;
