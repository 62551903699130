import * as React from 'react';
import { TextBox } from 'shared/Form';

import { DatePicker as MaterialDatePicker, DatePickerProps } from '@material-ui/pickers';

import styles from './DatePicker.module.scss';

export const ToolBarPicker = ({ title }) => (
  <div className={styles.toolBarPicker}>{title}</div>
);

interface IProps extends DatePickerProps {
  value: any;
  disableMargin?: boolean;
}

export const DatePicker: React.FC<IProps> = props => {
  return (
    <MaterialDatePicker
      autoOk={true}
      showToolbar={true}
      ToolbarComponent={ToolBarPicker as any}
      TextFieldComponent={TextBox as any}
      {...props}
    />
  );
};

export default DatePicker;
