import { PayloadedAction } from 'core/interfaces/PayloadedAction';
import { combineReducers } from 'redux';
import { createAction } from 'redux-actions';
import { promiseAction } from 'utils/sagaUtils';
import * as constants from './constants';
import { ICampaigns, ICampaignsReducers } from './interfaces';

export const actions = {
  getCampaigns: createAction(constants.GET_CAMPAIGNS),
  getCampaignsSuccess: createAction(constants.GET_CAMPAIGNS_SUCCESS),
  getCampaignsFailure: createAction(constants.GET_CAMPAIGNS_FAILURE),
  clearCampaigns: createAction(constants.CLEAR_CAMPAIGNS),
  clearCampaign: createAction(constants.CLEAR_CAMPAIGN),
  getCampaign: createAction(constants.GET_CAMPAIGN),
  getCampaignSuccess: createAction(constants.GET_CAMPAIGN_SUCCESS),
  getCampaignFailure: createAction(constants.GET_CAMPAIGN_FAILURE),
  addCampaign: createAction(constants.ADD_CAMPAIGN),
  addCampaignSuccess: createAction(constants.ADD_CAMPAIGN_SUCCESS),
  addCampaignFailure: createAction(constants.ADD_CAMPAIGN_FAILURE),
  editCampaign: promiseAction(constants.EDIT_CAMPAIGN),
  editCampaignSuccess: createAction(constants.EDIT_CAMPAIGN_SUCCESS),
  editCampaignFailure: createAction(constants.EDIT_CAMPAIGN_FAILURE),
  deleteCampaign: createAction(constants.DELETE_CAMPAIGN),
  deleteCampaignSuccess: createAction(constants.DELETE_CAMPAIGN_SUCCESS),
  deleteCampaignFailure: createAction(constants.DELETE_CAMPAIGN_FAILURE)
};

const campaignsIsLoading = (
  state = false,
  action: PayloadedAction
): boolean => {
  switch (action.type) {
    case constants.GET_CAMPAIGNS:
    case constants.GET_CAMPAIGN:
      return true;
    case constants.GET_CAMPAIGNS_SUCCESS:
    case constants.GET_CAMPAIGNS_FAILURE:
    case constants.GET_CAMPAIGN_SUCCESS:
    case constants.GET_CAMPAIGN_FAILURE:
      return false;
    default:
      return state;
  }
};

const initialStateCampaigns = {
  campaignsData: {
    count: 0,
    offset: 0,
    limit: null,
    search: '',
    next: '',
    sortBy: '',
    filter: {},
    previous: '',
    results: []
  },
  currentCampaign: null,
  failure: null,
  loader: false
};

export const campaignsReducer = (
  state = initialStateCampaigns,
  action: PayloadedAction
): ICampaigns => {
  switch (action.type) {
    case constants.ADD_CAMPAIGN:
    case constants.EDIT_CAMPAIGN:
      return {
        ...state,
        loader: true
      };
    case constants.GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaignsData: action.payload
      };
    case constants.GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        currentCampaign: action.payload
      };
    case constants.ADD_CAMPAIGN_SUCCESS:
    case constants.EDIT_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loader: false
      };
    case constants.GET_CAMPAIGNS_FAILURE:
    case constants.GET_CAMPAIGN_FAILURE:
    case constants.EDIT_CAMPAIGN_FAILURE:
    case constants.ADD_CAMPAIGN_FAILURE:
      return {
        ...state,
        failure: action.payload,
        loader: false
      };
    case constants.CLEAR_CAMPAIGNS:
      return {
        ...state,
        campaignsData: {
          count: 0,
          offset: 0,
          limit: null,
          search: '',
          next: '',
          sortBy: '',
          filter: {},
          previous: '',
          results: []
        },
        currentCampaign: null,
        failure: null,
        loader: false
      };
    case constants.CLEAR_CAMPAIGN:
      return {
        ...state,
        currentCampaign: null,
        failure: null,
        loader: false
      };
    default:
      return state;
  }
};

const campaignsReducers = combineReducers<ICampaignsReducers>({
  campaignsIsLoading,
  campaignsReducer
});

export default campaignsReducers;
