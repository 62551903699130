import closeModal from 'assets/icons/closeModal.svg';
import cx from 'classnames';
import * as React from 'react';
import Modal from 'react-modal';
import { Button } from 'shared/Button';
import { Container, Toolbar, ToolbarTitle } from 'shared/Containers';
import { EditablePhoto } from 'shared/Form/Photo/PhotoControlContainer';
import Preloader from 'shared/Preloader';
import { TablePanelSeparator } from 'shared/Table';
import { base64ToBlob } from 'utils/photos';

import ImageEditor from '@toast-ui/react-image-editor';

import PhotoAlert from './PhotoAlert';
import styles from './PhotoGallery.module.scss';
import { defaultImage, defaultTheme } from './TuiWhiteTheme';

export interface PhotoGalleryProps {
  property: string;
  setFieldValue: (path: any, value: any) => void;
  photos: Partial<EditablePhoto>[];
  children: (button: any) => any;
}

export class PhotoGallery extends React.Component<Partial<PhotoGalleryProps>> {
  static defaultProps = {
    property: 'photos'
  };
  state = {
    loading: false,
    open: false,
    edit: false,
    current: null
  };
  editor = null;
  handleEditorRef = ref => {
    this.editor = ref?.getInstance();
    ref?.getRootElement().classList.add(styles.editor_tui);
  };
  handleOpen = (index = 0, event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      edit: false,
      open: true,
      current: index
    });
  };
  handleClose = async event => {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.edit) {
      const result = await this.dialog.show();
      if (!result) return;
    }

    this.setState({
      edit: false,
      open: false
    });
  };
  handleEdit = async () => {
    let result = null;
    this.setState({
      loading: true
    });

    const current = this.props.photos[this.state.current];

    try {
      if (current.preview) {
        result = await this.editor.loadImageFromURL(current.preview, 'image');
      } else if (current.links) {
        const response = await fetch(current.links.original, {
          method: 'GET',
          cache: 'no-cache',
          headers: {
            Accept: 'image/webp,image/apng,image/*,*/*;q=0.8'
          }
        });

        const image = URL.createObjectURL(await response.blob());
        result = await this.editor.loadImageFromURL(image, 'image');
      }

      await this.editor.ui.resizeEditor({ imageSize: result });
      this.setState({
        edit: true,
        loading: false
      });
    } catch (error) {
      console.log(error);
      this.setState({
        edit: false,
        loading: false
      });
    }
  };
  handleCancel = async () => {
    const result = await this.editor.loadImageFromURL(
      defaultImage.path,
      defaultImage.name
    );
    await this.editor.ui.resizeEditor({ imageSize: result });
    this.setState({
      edit: false
    });
  };
  handleApply = async () => {
    const { property, photos, setFieldValue } = this.props;

    this.setState({
      loading: true
    });
    const { id, links, preview, ...other } = this.props.photos[
      this.state.current
    ];

    const dataURL = this.editor.toDataURL();
    const blob = base64ToBlob(dataURL);

    const newPhoto = {
      ...other,
      upload: true,
      file: blob,
      preview: URL.createObjectURL(blob)
    };

    const newPhotos = photos.filter(
      (x, index) => x.id !== id || index !== this.state.current
    );
    newPhotos.push(newPhoto);

    setFieldValue(
      property,
      newPhotos.sort((a, b) => a.order - b.order)
    );

    this.setState({
      loading: false,
      edit: false
    });
  };
  dialog: PhotoAlert = null;
  async handlePhotoChange(index, event) {
    if (this.state.edit) {
      const result = await this.dialog.show();
      if (result) this.handleCancel();
      else return;
    }
    this.setState({
      current: index
    });
  }
  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        <PhotoAlert ref={x => (this.dialog = x)} />
        {this.renderGallery()}
        {children(this.renderButton)}
      </React.Fragment>
    );
  }
  renderButton = ({ className, photo, children }) => {
    return (
      <div
        className={cx(styles.link, className)}
        onClick={this.handleOpen.bind(this, photo)}
      >
        {children}
      </div>
    );
  };
  renderGallery = () => {
    const { setFieldValue } = this.props;

    const current = this.props.photos?.[this.state.current];

    return (
      <Modal
        isOpen={this.state.open}
        className={styles.modal}
        overlayClassName={styles.overlay}
        ariaHideApp={false}
        onRequestClose={this.handleClose}
      >
        <img
          alt=""
          src={closeModal}
          className={styles.closeModal}
          onClick={this.handleClose}
        />

        <Container
          onClick={event => {
            // event.preventDefault();
            event.stopPropagation();
          }}
        >
          <div className={styles.wrapper}>
            <Toolbar>
              <ToolbarTitle>Photos</ToolbarTitle>
              <TablePanelSeparator />
              {setFieldValue &&
                (this.state.edit ? (
                  <React.Fragment>
                    <div className={styles.leftButtom}>
                      <Button variant="outlined" onClick={this.handleCancel}>
                        Cancel
                      </Button>
                    </div>
                    <Button variant="solid" onClick={this.handleApply}>
                      Apply
                    </Button>
                  </React.Fragment>
                ) : (
                  <Button variant="solid" onClick={this.handleEdit}>
                    Edit photo
                  </Button>
                ))}
            </Toolbar>
            <div className={styles.body}>
              <div className={cx(styles.preview)}>
                <div className={cx(styles.overlay, styles.loader)}>
                  <Preloader />
                </div>
                <div
                  className={cx(styles.editor, {
                    [styles.editor_enabled]: this.state.edit
                  })}
                >
                  <ImageEditor
                    ref={this.handleEditorRef}
                    includeUI={{
                      loadImage: defaultImage,
                      theme: defaultTheme,
                      menuBarPosition: 'bottom',
                      menu: [
                        'crop',
                        'flip',
                        'rotate',
                        'draw',
                        'shape',
                        'icon',
                        'text',
                        'filter'
                      ]
                    }}
                    usageStatistics={false}
                  />
                </div>
                {current && (
                  <div
                    className={cx(styles.view, {
                      [styles.view_disabled]:
                        this.state.loading || this.state.edit
                    })}
                  >
                    <img
                      alt=""
                      src={current?.links?.original ?? current?.preview}
                    />
                  </div>
                )}
              </div>
              <div className={styles.gallery}>
                {this.props.photos?.map((photo, index) => (
                  <div
                    key={index}
                    className={cx(styles.gallery_thumbnail, {
                      [styles.gallery_active]: this.state.current === index
                    })}
                    onClick={this.handlePhotoChange.bind(this, index)}
                  >
                    <img alt="" src={photo.links?.small ?? photo?.preview} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Container>
      </Modal>
    );
  };
}

export default PhotoGallery;
