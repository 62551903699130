import * as React from 'react';

import styles from './styles.module.scss';

const defaultImage = require('./assets/img/photo_add.svg');

interface IProps {
  viewPhoto: any;
  image: any;
  name: string;
  onChange: (name: string, file: any) => void;
}

class InputUploadImg extends React.Component<Partial<IProps>> {
  state = {
    file: '',
    imagePreviewUrl: ''
  };

  componentDidMount() {
    this.setAvatar();
  }

  componentDidUpdate(prevProps, prevState) {
    const { imagePreviewUrl } = this.state;
    const { image } = this.props;
    if (
      prevState.imagePreviewUrl !== imagePreviewUrl ||
      prevProps.image !== image
    )
      this.setAvatar();
  }

  setAvatar = () => {
    const { image } = this.props;
    const { imagePreviewUrl } = this.state;

    if (image && !imagePreviewUrl && typeof image === 'object') {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          file: image,
          imagePreviewUrl: reader.result
        });
      };
      reader.readAsDataURL(image);
    } else if (image && !imagePreviewUrl) {
      this.setState({ imagePreviewUrl: image });
    }
    imagePreviewUrl === 'delete' && this.setState({ imagePreviewUrl: '' });
  };

  handleImageChange = e => {
    e.preventDefault();
    e.persist();

    const { onChange, name } = this.props;
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        this.setState(
          {
            file,
            imagePreviewUrl: reader.result
          },
          () => onChange(name, file)
        );
        e.target.value = null;
      };

      reader.readAsDataURL(file);
    }
  };

  deletePhoto = () => {
    const { onChange, name } = this.props;
    this.setState(
      {
        file: '',
        imagePreviewUrl: 'delete'
      },
      () => onChange(name, null)
    );
  };

  render() {
    const { viewPhoto: ViewPhoto } = this.props;
    const { imagePreviewUrl } = this.state;

    return (
      <div
        className={styles.container}
        style={{
          backgroundImage: `url(${
            imagePreviewUrl ? imagePreviewUrl : defaultImage
          })`,
          backgroundSize: `${imagePreviewUrl ? 'contain' : 'auto'}`
        }}
      >
        <div className={styles.pic}>
          <input
            id="avatar"
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={this.handleImageChange}
            className={styles.addPhoto}
          />
          <label htmlFor="avatar" className={styles.avatarLabel} />
        </div>
        {ViewPhoto && (
          <ViewPhoto>
            <button className={styles.deleteButton} onClick={() => {}}>
              View photo
            </button>
          </ViewPhoto>
        )}
        {imagePreviewUrl && (
          <button className={styles.deleteButton} onClick={this.deletePhoto}>
            Delete photo
          </button>
        )}
      </div>
    );
  }
}

export default InputUploadImg;
