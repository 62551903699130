// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_pic__1DQ9V {\n  width: 118px;\n  height: 118px;\n  background: #fffeff;\n  border: 1px solid #e7e7e7;\n  border-radius: 4px;\n  box-sizing: border-box;\n}\n.styles_pic__1DQ9V img {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}", "",{"version":3,"sources":["webpack://src/shared/Profile/Avatar/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,sBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AACJ","sourcesContent":[".pic {\n  width: 118px;\n  height: 118px;\n  background: #fffeff;\n  border: 1px solid #e7e7e7;\n  border-radius: 4px;\n  box-sizing: border-box;\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pic": "styles_pic__1DQ9V"
};
export default ___CSS_LOADER_EXPORT___;
