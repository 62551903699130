import cx from 'classnames';
import * as React from 'react';
import ReactSelect, { components, ReactSelectProps } from 'react-select';

import TextBox, { TextBoxProps } from '../TextBox/TextBox';
import styles from './styles.module.scss';

const downImg = require('./assets/img/down.svg');
const upImg = require('./assets/img/up.svg');

const customStyles = {
  control: base => ({
    ...base,
    border: 0,
    boxShadow: 0
  })
};

const DropdownIndicator = props => {
  const {
    selectProps: { menuIsOpen }
  } = props;
  return (
    <components.DropdownIndicator {...props}>
      <img src={menuIsOpen ? upImg : downImg} alt="" />
    </components.DropdownIndicator>
  );
};

export interface SelectProps extends TextBoxProps, ReactSelectProps {
  closeMenuOnSelect: boolean;
  isMulti: boolean;
  findValue: boolean;
  isClearable: boolean;
  isDisabled: boolean;
  placeholder: string;
  options: any[];
  onInputChange: (string) => void;
  getOptionLabel: (option) => string;
  getOptionValue: (option) => string;
}

export const Select: React.FC<Partial<SelectProps>> = ({
  findValue = false,
  options,
  value,
  inputProps: { className, ...otherInputProps } = {},
  ...other
}) => {
  const getValue = React.useCallback(
    value => (findValue ? options.find(x => x.value === value) : value),
    [options, findValue]
  );
  return (
    <TextBox
      component={ReactSelect}
      inputProps={{
        ...otherInputProps,
        classNamePrefix: 'combobox',
        className: cx(className, styles.combobox),
        components: { DropdownIndicator },
        styles: customStyles,
        options: options,
        value: getValue(value)
      }}
      {...other}
    />
  );
};

export default Select;
