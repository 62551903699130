import * as React from 'react';

import { ReactComponent as IconActive } from './assets/activeRadio.svg';
import { ReactComponent as Icon } from './assets/inactiveRadio.svg';
import styles from './styles.module.scss';

export interface IProps extends React.HTMLAttributes<HTMLInputElement> {
  checked: boolean;
  value: string;
  name: string;
  label: string;
  error: string;
}

export const RadioButton: React.FC<Partial<IProps>> = ({
  value,
  checked,
  label,
  error,
  ...other
}) => {
  return (
    <div className={styles.checkboxContainer} {...other}>
      <input
        readOnly
        className={styles.hiddenCheckbox}
        type="radio"
        value={value}
        checked={checked}
      />
      <div className={styles.styledCheckbox}>
        {checked ? <IconActive /> : <Icon />}
      </div>
      {label && <label htmlFor={value}>{label}</label>}
    </div>
  );
};

export default RadioButton;
