import path from 'path';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button } from 'shared/Button';
import ButtonEdit from 'shared/ButtonEdit';
import {
  Container,
  Paper,
  Toolbar,
  ToolbarBack,
  ToolbarTitle
} from 'shared/Containers';
import ModalPopUp from 'shared/ModalPopUp';
import Preloader from 'shared/Preloader';
import { Avatar, Field, Label, Text } from 'shared/Profile';
import { TablePanelSeparator } from 'shared/Table';
import { actions } from 'users/store/duck';
import { IUser } from 'users/store/interfaces';
import {
  editProfileSelector,
  isLoadingSelector,
  userInfoSelector
} from 'users/store/selectors';
import { userInfoSelector as userProfile } from 'app/containers/App/store/selectors';
import UsersPopUp from '../UsersPopUp';
import { bindActionToPromise } from 'utils/sagaUtils';
import PermissionsContainer from 'shared/Permissions/PermissionsContainer';

interface IProps {
  userId: number;
  match: any;
  location: { state: { fromUserView: string } };
  userInfo: IUser;
  userProfile: IUser;
  isLoading: boolean;
  editProfileView: boolean;
  clearUser: () => void;
  showEditProfile: () => void;
  getUsers: (value: any) => void;
  getUsersInfo: (value: any) => void;
  changeUsersName: (value: any) => void;
}

const mapStateToProps = state => {
  return {
    userProfile: userProfile(state),
    userInfo: userInfoSelector(state),
    isLoading: isLoadingSelector(state),
    editProfileView: editProfileSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearUser: () => dispatch(actions.clearUser()),
    getUsers: value => dispatch(actions.getUsers(value)),
    getUsersInfo: value => dispatch(actions.getUsersInfo(value)),
    showEditProfile: value => dispatch(actions.showEditProfile(value)),
    changeUsersName: bindActionToPromise(dispatch, actions.changeUsersName)
  };
};

class UserProfile extends React.Component<IProps> {
  state = {
    isOpen: false
  };

  componentDidMount() {
    const {
      userId,
      match: {
        params: { id }
      },
      getUsersInfo
    } = this.props;
    getUsersInfo({ id: id ?? userId });
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      userId,
      match: {
        params: { id }
      },
      getUsersInfo
    } = this.props;
    if (prevProps.match.params.id !== id) {
      getUsersInfo({ id: id ?? userId });
    }
  }

  componentWillUnmount() {
    const { clearUser } = this.props;
    clearUser();
  }

  handleClick = () => {
    const { showEditProfile } = this.props;
    showEditProfile();
  };

  activateUser = async () => {
    const { changeUsersName, getUsers, userInfo, match } = this.props;
    const value = {
      id: userInfo.id,
      is_archived: false
    };
    await changeUsersName({ url: path.join(match.url, '../'), data: value });
    getUsers({ is_archived: true });
    this.togglePopup(false);
  };

  archiveUser = async () => {
    const { changeUsersName, getUsers, userInfo, match } = this.props;
    const value = {
      id: userInfo.id,
      is_archived: true
    };
    await changeUsersName({ url: path.join(match.url, '../'), data: value });
    getUsers({ is_archived: false });
    this.togglePopup(false);
  };

  togglePopup = value => {
    this.setState({ isOpen: value });
  };

  render() {
    const { isOpen } = this.state;
    const { match, userInfo, location, isLoading, userProfile } = this.props;

    return (
      <Container>
        <Toolbar>
          {location?.state?.fromUserView ? (
            <ToolbarTitle>Profile</ToolbarTitle>
          ) : (
            <ToolbarBack path={path.join(match.url, '../')} text={`Profile`} />
          )}
          <TablePanelSeparator />

          {userInfo?.id !== userProfile?.id && (
            <PermissionsContainer roles={['admin']}>
              <Button
                fullWidth
                disabled={isLoading}
                variant="solid"
                type="submit"
                onClick={() => this.togglePopup(true)}
              >
                {isLoading ? (
                  <Preloader size="button" />
                ) : userInfo?.is_archived ? (
                  'Activate user'
                ) : (
                  'Archive user'
                )}
              </Button>
            </PermissionsContainer>
          )}
        </Toolbar>
        <Paper>
          <Field>
            <Label>Userpic</Label>
            <Avatar src={userInfo?.avatar?.medium} />
          </Field>

          <Field>
            <Label>First name</Label>
            <Text>{userInfo?.first_name}</Text>
          </Field>

          <Field>
            <Label>Last name</Label>
            <Text>{userInfo?.last_name}</Text>
          </Field>

          <Field>
            <Label>Email</Label>
            <Text>{userInfo?.email}</Text>
          </Field>

          <Field>
            <Label>Role</Label>
            <Text>{userInfo?.role}</Text>
          </Field>

          {!userInfo?.is_archived && (
            <ButtonEdit path={path.join(match.url, `edit`)} />
          )}
        </Paper>

        <ModalPopUp isOpen={isOpen} togglePopup={this.togglePopup}>
          <UsersPopUp
            togglePopup={this.togglePopup}
            is_archived={userInfo?.is_archived}
            activateUser={this.activateUser}
            archiveUser={this.archiveUser}
          />
        </ModalPopUp>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
