var rImageType = /data:(image\/.+);base64,/;

export function base64ToBlob(data: string) {
  var mimeString = '';
  var raw, uInt8Array, i, rawLength;

  raw = data.replace(rImageType, function(header, imageType) {
    mimeString = imageType;

    return '';
  });

  raw = atob(raw);
  rawLength = raw.length;
  uInt8Array = new Uint8Array(rawLength); // eslint-disable-line

  for (i = 0; i < rawLength; i += 1) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: mimeString });
}

export function saveFile(arrayBuffer, mime, name) {
  var blob = new Blob([arrayBuffer], {
    type: mime
  });
  // @ts-ignore: Unreachable code error
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //IE
    // @ts-ignore: Unreachable code error
    window.navigator.msSaveOrOpenBlob(blob, name);
  } else {
    //other browsers
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }
}
