import { ReactComponent as Active } from './active.svg';
import { ReactComponent as Archive } from './archive.svg';
import { ReactComponent as Assignments } from './assignments.svg';
import { ReactComponent as Calibration } from './calibration.svg';
import { ReactComponent as ISIMS } from './isims.svg';
import { ReactComponent as Cathodic } from './cathodicProtection.svg';
import { ReactComponent as Completed } from './completed.svg';
import { ReactComponent as Components } from './components.svg';
import { ReactComponent as Conductors } from './conductors.svg';
import { ReactComponent as Wellheads } from './wellheads.svg';
import { ReactComponent as Customers } from './customers.svg';
import { ReactComponent as Directories } from './directories.svg';
import { ReactComponent as Inspection } from './inspectionCampaigns.svg';
import { ReactComponent as manuImg } from './menu.svg';
import { ReactComponent as Opened } from './opened.svg';
import { ReactComponent as PlatformPhotos } from './platformPhotos.svg';
import { ReactComponent as Platforms } from './platforms.svg';
import { ReactComponent as RiserClamp } from './riserClapms.svg';
import { ReactComponent as Risers } from './risers.svg';
import { ReactComponent as Surveys } from './surveys.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as Analytics } from './analytics.svg';

export default {
  Archive,
  Analytics,
  Assignments,
  'CP Calibration': Calibration,
  ISIMS,
  'Cathodic Protection': Cathodic,
  Completed,
  Components,
  Conductors,
  Wellheads,
  Customers,
  Directories,
  Active,
  'Inspection campaigns': Inspection,
  manuImg,
  Opened,
  Platforms,
  'Riser Clamps': RiserClamp,
  Risers,
  Surveys,
  Users,
  'Platform photos': PlatformPhotos
};
