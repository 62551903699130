const STORE_TYPE = 'users';

export const GET_USERS_INFO = `${STORE_TYPE}/GET_USERS_INFO`;
export const GET_USERS_INFO_SUCCESS = `${STORE_TYPE}/GET_USERS_INFO_SUCCESS`;
export const GET_USERS_INFO_FAILURE = `${STORE_TYPE}/GET_USERS_INFO_FAILURE`;

export const SHOW_EDIT_PROFILE = `${STORE_TYPE}/SHOW_EDIT_PROFILE`;

export const BACK_TO_PROFILE = `${STORE_TYPE}/BACK_TO_PROFILE`;

export const BACK_TO_EDIT_PROFILE = `${STORE_TYPE}/BACK_TO_EDIT_PROFILE`;

export const CHANGE_USERS_NAME = `${STORE_TYPE}/CHANGE_USERS_NAME`;
export const CHANGE_USERS_NAME_SUCCESS = `${STORE_TYPE}/CHANGE_USERS_NAME_SUCCESS`;
export const CHANGE_USERS_NAME_FAILURE = `${STORE_TYPE}/CHANGE_USERS_NAME_FAILURE`;

export const GET_PASSWORD_CODE = `${STORE_TYPE}/GET_PASSWORD_CODE`;
export const GET_PASSWORD_CODE_SUCCESS = `${STORE_TYPE}/GET_PASSWORD_CODE_SUCCESS`;
export const GET_PASSWORD_CODE_FAILURE = `${STORE_TYPE}/GET_PASSWORD_CODE_FAILURE`;

export const SEND_PASSWORD_CODE = `${STORE_TYPE}/SEND_PASSWORD_CODE`;
export const SEND_PASSWORD_CODE_SUCCESS = `${STORE_TYPE}/SEND_PASSWORD_CODE_SUCCESS`;
export const SEND_PASSWORD_CODE_FAILURE = `${STORE_TYPE}/SEND_PASSWORD_CODE_FAILURE`;

export const FINISH_CHANGE_PASSWORD = `${STORE_TYPE}/FINISH_CHANGE_PASSWORD`;
export const FINISH_CHANGE_PASSWORD_SUCCESS = `${STORE_TYPE}/FINISH_CHANGE_PASSWORD_SUCCESS`;
export const FINISH_CHANGE_PASSWORD_FAILURE = `${STORE_TYPE}/FINISH_CHANGE_PASSWORD_FAILURE`;

export const GET_USERS = `${STORE_TYPE}/GET_USERS`;
export const GET_USERS_SUCCESS = `${STORE_TYPE}/GET_USERS_SUCCESS`;
export const GET_USERS_FAILURE = `${STORE_TYPE}/GET_USERS_FAILURE`;

export const CLEAR_USERS = `${STORE_TYPE}/CLEAR_USERS`;

export const GET_AVATAR = `${STORE_TYPE}/GET_AVATAR`;
export const GET_AVATAR_SUCCESS = `${STORE_TYPE}/GET_AVATAR_SUCCESS`;
export const GET_AVATAR_FAILURE = `${STORE_TYPE}/GET_AVATAR_FAILURE`;

export const UPLOAD_AVATAR = `${STORE_TYPE}/UPLOAD_AVATAR`;
export const UPLOAD_AVATAR_SUCCESS = `${STORE_TYPE}/UPLOAD_AVATAR_SUCCESS`;
export const UPLOAD_AVATAR_FAILURE = `${STORE_TYPE}/UPLOAD_AVATAR_FAILURE`;

export const DELETE_AVATAR = `${STORE_TYPE}/DELETE_AVATAR`;
export const DELETE_AVATAR_SUCCESS = `${STORE_TYPE}/DELETE_AVATAR_SUCCESS`;
export const DELETE_AVATAR_FAILURE = `${STORE_TYPE}/DELETE_AVATAR_FAILURE`;

export const ADD_USER = `${STORE_TYPE}/ADD_USER`;
export const ADD_USER_SUCCESS = `${STORE_TYPE}/ADD_USER_SUCCESS`;
export const ADD_USER_FAILURE = `${STORE_TYPE}/ADD_USER_FAILURE`;

export const CLEAR_USER = `${STORE_TYPE}/CLEAR_USER`;

export const ADD_USER_ID = `${STORE_TYPE}/ADD_USER_ID`;
export const CLEAR_USER_ID = `${STORE_TYPE}/CLEAR_USER_ID`;
