import { withFormik } from 'formik';
import * as React from 'react';
import { Button } from 'shared/Button';
import { TextBox } from 'shared/Form';
import Preloader from 'shared/Preloader';

import styles from './styles.module.scss';

const close = require('assets/icons/close.svg');

interface IProps {
  isLoading: boolean;
  failure?: any;
  values: {
    oldPassword?: string;
    newPassword?: string;
    confirmedPassword?: string;
  };
  errors: FormErrors;
  changePasswordToken: string;
  handleChange: () => void;
  handleSubmit: () => void;
  handleBlur: () => void;
  touched?: any;
  backToEditProfile: () => void;
  finishChangePassword: (value: any) => void;
}

interface FormValues {
  oldPassword: string;
  newPassword: string;
  confirmedPassword: string;
}

interface FormErrors {
  oldPassword?: string;
  newPassword?: string;
  confirmedPassword?: string;
}

const formikEnhancer = withFormik<any, any>({
  enableReinitialize: true,
  validate: (values: FormValues) => {
    let errors: FormErrors = {};
    const { oldPassword, newPassword, confirmedPassword } = values;

    if (!oldPassword) {
      errors.oldPassword = 'Old password is empty';
    } else if (oldPassword.length < 8) {
      errors.oldPassword = 'Password must consist of 8 digits';
    }

    if (!newPassword) {
      errors.newPassword = 'Password is empty';
    } else if (newPassword.length < 8) {
      errors.newPassword = 'Password must consist of 8 digits';
    }

    if (!confirmedPassword) {
      errors.confirmedPassword = 'Password is empty';
    } else if (confirmedPassword !== newPassword) {
      errors.confirmedPassword = 'Passwords do not match';
    }
    return errors;
  },
  mapPropsToValues: () => {
    return {
      oldPassword: '',
      newPassword: '',
      confirmedPassword: ''
    };
  },
  handleSubmit: (values, props: any) => {
    const {
      props: { finishChangePassword, changePasswordToken }
    } = props;
    const value = {
      old_password: values.oldPassword,
      new_password_1: values.newPassword,
      new_password_2: values.confirmedPassword,
      change_password_token: changePasswordToken
    };
    finishChangePassword(value);
  },
  displayName: 'ChangePasswordStep2'
});

class ChangePasswordStep2 extends React.Component<IProps> {
  handleClick = () => {
    const { backToEditProfile } = this.props;
    backToEditProfile();
  };

  render() {
    const {
      values,
      errors,
      failure,
      touched,
      isLoading,
      handleBlur,
      handleSubmit,
      handleChange
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className={styles.back}>
          <img
            src={close}
            alt="back"
            className={styles.backImg}
            onClick={this.handleClick}
          />
          <h1 className={styles.title}>Changing password</h1>
        </div>
        <div className={styles.profile}>
          <div className={styles.field}>
            <div className={styles.subtext}>
              <TextBox
                key="oldPassword"
                id="oldPassword"
                type="password"
                name="oldPassword"
                placeholder="Old password"
                value={values.oldPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                error={
                  (errors?.oldPassword &&
                    touched.oldPassword &&
                    errors?.oldPassword) ??
                  failure?.old_password
                }
              />
            </div>
          </div>

          <div className={styles.field}>
            <div className={styles.subtext}>
              <TextBox
                key="newPassword"
                type="password"
                id="newPassword"
                placeholder="New password"
                name="newPassword"
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  (errors?.newPassword &&
                    touched.newPassword &&
                    errors?.newPassword) ??
                  failure?.new_password_1
                }
              />
            </div>
          </div>

          <div className={styles.field}>
            <div className={styles.subtext}>
              <TextBox
                key="confirmedPassword"
                type="password"
                id="confirmedPassword"
                placeholder="Confirm the password"
                name="confirmedPassword"
                value={values.confirmedPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  (errors?.confirmedPassword &&
                    touched.confirmedPassword &&
                    errors?.confirmedPassword) ??
                  failure?.new_password_2
                }
              />
            </div>
          </div>
          {failure && failure.detail && (
            <p className={styles.error}>{failure.detail}</p>
          )}
          <div className={styles.submitButton}>
            <Button
              type="submit"
              fullWidth
              disabled={isLoading}
              variant="solid"
            >
              {isLoading ? <Preloader size="button" /> : 'CHANGE'}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

export default formikEnhancer(ChangePasswordStep2);
