import { actions } from 'assignments/store/duck';
import * as React from 'react';
import { connect } from 'react-redux';

interface IProps {
  addAssignment: (value: any) => void;
  getAssignments: (value: any) => void;
  user: string;
  inspection_campaign: string;
  platforms: string;
}

const mapDispatchToProps = dispatch => {
  return {
    addAssignment: value => dispatch(actions.addAssignment(value)),
    getAssignments: value => dispatch(actions.getAssignments(value))
  };
};

class AssignmentUndo extends React.Component<IProps> {
  render() {
    const {
      addAssignment,
      getAssignments,
      user,
      inspection_campaign,
      platforms
    } = this.props;
    return (
      <span
        onClick={() => {
          addAssignment({ user, inspection_campaign, platforms });
          getAssignments({});
        }}
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
          fontWeight: 'bold',
          color: '#3E3E3E'
        }}
      >
        UNDO
      </span>
    );
  }
}

export default connect(null, mapDispatchToProps)(AssignmentUndo);
