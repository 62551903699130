import { userInfoSelector as meSelector } from 'app/containers/App/store/selectors';
import * as React from 'react';
import { connect } from 'react-redux';
import Preloader from 'shared/Preloader';
import { actions } from 'users/store/duck';
import { IUser } from 'users/store/interfaces';
import {
  changePasswordTokenSelector,
  editProfileSelector,
  editStepSelector,
  failureChangeUserNameSelector,
  failureFinishChangePasswordSelector,
  failureSendPasswordCodeSelector,
  isLoadingSelector,
  userInfoSelector
} from 'users/store/selectors';

import ChangePasswordStep1 from './components/ChangePasswordStep1';
import ChangePasswordStep2 from './components/ChangePasswordStep2';
import EditProfileForm from './components/EditProfileForm';

interface IProps {
  userId: number;
  match: any;
  userInfo: IUser;
  meInfo: IUser;
  editStep: number;
  isLoading: boolean;
  changePasswordToken: any;
  failureChangeUserName?: any;
  failureSendPasswordCode?: any;
  failureFinishChangePassword?: any;
  clearUser: () => void;
  backToProfile: () => void;
  getPasswordCode: () => void;
  backToEditProfile: () => void;
  getAvatar: (value: any) => void;
  getUsersInfo: (value: any) => void;
  changeUsersName: (value: any) => void;
  sendPasswordCode: (value: any) => void;
  finishChangePassword: (value: any) => void;
}

const mapStateToProps = state => {
  return {
    meInfo: meSelector(state),
    userInfo: userInfoSelector(state),
    isLoading: isLoadingSelector(state),
    editProfileView: editProfileSelector(state),
    editStep: editStepSelector(state),
    changePasswordToken: changePasswordTokenSelector(state),
    failureChangeUserName: failureChangeUserNameSelector(state),
    failureSendPasswordCode: failureSendPasswordCodeSelector(state),
    failureFinishChangePassword: failureFinishChangePasswordSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearUser: () => dispatch(actions.clearUser()),
    backToProfile: () => dispatch(actions.backToProfile()),
    getUsersInfo: value => dispatch(actions.getUsersInfo(value)),
    getPasswordCode: () => dispatch(actions.getPasswordCode()),
    backToEditProfile: () => dispatch(actions.backToEditProfile()),
    changeUsersName: value => dispatch(actions.changeUsersName(value)),
    sendPasswordCode: value => dispatch(actions.sendPasswordCode(value)),
    finishChangePassword: value =>
      dispatch(actions.finishChangePassword(value)),
    getAvatar: value => dispatch(actions.getAvatar(value))
  };
};

class EditProfile extends React.Component<IProps> {
  componentDidMount() {
    const {
      userId,
      match: {
        params: { id }
      },
      getUsersInfo
    } = this.props;
    getUsersInfo({ id: id ?? userId });
  }

  componentWillUnmount() {
    const { backToProfile } = this.props;
    backToProfile();
  }

  renderStep = () => {
    const {
      match,
      meInfo,
      userInfo,
      isLoading,
      editStep,
      clearUser,
      getAvatar,
      backToProfile,
      changeUsersName,
      getPasswordCode,
      sendPasswordCode,
      backToEditProfile,
      changePasswordToken,
      finishChangePassword,
      failureChangeUserName,
      failureSendPasswordCode,
      failureFinishChangePassword
    } = this.props;

    const fromUserView = (meInfo && meInfo.id) === (userInfo && userInfo.id);

    if (userInfo === null) {
      return <Preloader />;
    } else {
      switch (editStep) {
        case 1:
          return (
            <EditProfileForm
              match={match}
              userInfo={userInfo}
              isLoading={isLoading}
              getAvatar={getAvatar}
              clearUser={clearUser}
              fromUserView={fromUserView}
              backToProfile={backToProfile}
              failure={failureChangeUserName}
              getPasswordCode={getPasswordCode}
              changeUsersName={changeUsersName}
            />
          );
        case 2:
          return (
            <ChangePasswordStep1
              isLoading={isLoading}
              failure={failureSendPasswordCode}
              sendPasswordCode={sendPasswordCode}
              backToEditProfile={backToEditProfile}
            />
          );
        case 3:
          return (
            <ChangePasswordStep2
              isLoading={isLoading}
              backToEditProfile={backToEditProfile}
              failure={failureFinishChangePassword}
              changePasswordToken={changePasswordToken}
              finishChangePassword={finishChangePassword}
            />
          );
        default:
          break;
      }
    }
  };

  render() {
    return this.renderStep();
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
