import * as React from 'react';
import cx from 'classnames';
import { TextBox } from 'shared/Form';
import { SketchPicker } from 'react-color';
import { ReactComponent as Icon } from 'assets/icons/colour_picker.svg';
import { IconButton } from 'shared/Button';
import styles from './ColorPicker.module.scss';

interface IProps {
  value: any;
  label?: any;
  name?: any;
  unregister?: any;
  inputRef?: any;
  displayColorPicker?: boolean;
  setValue?: (any) => void;
  onChange?: (any) => void;
}

class ColorPicker extends React.Component<IProps> {
  state = {
    displayColorPicker: false,
    value: ''
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = value => {
    this.setState({ value });
    this.props.onChange(value);
  };

  render() {
    return (
      <React.Fragment>
        <TextBox
          disableMargin
          label="Color"
          placeholder="Select color"
          name={this.props.name}
          onChange={e => this.handleChange(e.target.value)}
          value={this.props.value}
          endAdornment={
            <IconButton component="a" onClick={this.handleClick}>
              <Icon
                className={cx(styles.eye, {
                  [styles.eye_active]: this.state.displayColorPicker
                })}
              />
            </IconButton>
          }
        />
        {this.state.displayColorPicker ? (
          <div className={styles.popover}>
            <div className={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              disableAlpha={true}
              presetColors={[
                '#3e3e3e',
                '#469367',
                '#cbc927',
                '#DC0000',
                '#1A3DD4',
                '#CF9210',
                '#417505',
                '#BD10E0',
                '#D30B55',
                '#5C1FC2',
                '#4A90E2',
                '#50E3C2',
                '#B8E986',
                '#290613',
                '#000000'
              ]}
              color={this.props.value}
              onChangeComplete={color => this.handleChange(color.hex)}
              {...this.props}
            />
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default ColorPicker;
