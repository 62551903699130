import { withFormik } from 'formik';
import * as React from 'react';
import { Button } from 'shared/Button';
import { TextBox } from 'shared/Form';
import Preloader from 'shared/Preloader';

import styles from './styles.module.scss';

interface IProps {
  failure?: any;
  isLoading: boolean;
  tokenRecovery: string;
  recoveryStep: number;
  finishRecovery: (value: any) => void;
  values: {
    newPassword: string;
    confirmedPassword: string;
  };
  errors: FormErrors;
  handleChange: () => void;
  handleSubmit: () => void;
  handleBlur: () => void;
  touched?: any;
}

interface FormValues {
  newPassword: string;
  confirmedPassword: string;
}

interface FormErrors {
  newPassword?: string;
  confirmedPassword?: string;
}

const formikEnhancer = withFormik<any, any>({
  enableReinitialize: true,
  validate: (values: FormValues) => {
    let errors: FormErrors = {};
    const { newPassword, confirmedPassword } = values;

    if (!newPassword) {
      errors.newPassword = 'Password is empty';
    } else if (newPassword.length < 8) {
      errors.newPassword = 'Password must consist of 8 digits';
    }

    if (!confirmedPassword) {
      errors.confirmedPassword = 'Password is empty';
    } else if (confirmedPassword !== newPassword) {
      errors.confirmedPassword = 'Passwords do not match';
    }

    return errors;
  },
  mapPropsToValues: () => {
    return {
      newPassword: '',
      confirmedPassword: ''
    };
  },
  handleSubmit: (values, props: any) => {
    const {
      props: { finishRecovery, tokenRecovery }
    } = props;

    const value = {
      recovery_token: tokenRecovery,
      new_password_1: values.confirmedPassword,
      new_password_2: values.newPassword
    };

    finishRecovery(value);
  },
  displayName: 'RecoveryStep3'
});

class RecoveryStep3 extends React.Component<IProps> {
  render() {
    const {
      values,
      errors,
      failure,
      isLoading,
      handleBlur,
      handleSubmit,
      handleChange
    } = this.props;
    return (
      <form onSubmit={handleSubmit} className={styles.recoveryForm}>
        <div>
          <TextBox
            key="newPassword"
            type="password"
            id="newPassword"
            placeholder="New password"
            name="newPassword"
            value={values.newPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors?.newPassword ?? failure?.new_password_1}
          />
        </div>
        <div>
          <TextBox
            key="confirmedPassword"
            type="password"
            id="confirmedPassword"
            placeholder="Confirm the password"
            name="confirmedPassword"
            value={values.confirmedPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors?.confirmedPassword ?? failure?.new_password_2}
          />
        </div>
        {failure && failure.detail && (
          <p className={styles.error}>{failure.detail}</p>
        )}
        <div className={styles.submitButton}>
          <Button disabled={isLoading} fullWidth variant="solid" type="submit">
            {isLoading ? <Preloader size="button" /> : 'ACCEPT'}
          </Button>
        </div>
      </form>
    );
  }
}
export default formikEnhancer(RecoveryStep3);
