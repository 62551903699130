import { PayloadedAction } from 'core/interfaces/PayloadedAction';
import { combineReducers } from 'redux';
import { createAction } from 'redux-actions';
import { promiseAction } from 'utils/sagaUtils';

import * as constants from './constants';
import {
  IEditPlatformReducer,
  IPipelines,
  IPlatform,
  IPlatforms,
  IPlatformsReducer,
  IReports,
  IWells,
  IWellSlots,
  IWorkOrders,
  IRatings,
  IAnalytics,
} from './interfaces';

export const actions = {
  getPlatforms: createAction(constants.GET_PLATFORMS),
  getPlatformsSuccess: createAction(constants.GET_PLATFORMS_SUCCESS),
  getPlatformsFailure: createAction(constants.GET_PLATFORMS_FAILURE),
  clearPlatforms: createAction(constants.CLEAR_PLATFORMS),
  clearPlatformComponent: createAction(constants.CLEAR_PLATFORM_COMPONENT),
  getPlatform: createAction(constants.GET_PLATFORM),
  getPlatformSuccess: createAction(constants.GET_PLATFORM_SUCCESS),
  getPlatformFailure: createAction(constants.GET_PLATFORM_FAILURE),
  editPlatformInformation: promiseAction(constants.EDIT_PLATFORM_INFORMATION),
  editPlatformInformationSuccess: createAction(
    constants.EDIT_PLATFORM_INFORMATION_SUCCESS
  ),
  editPlatformInformationFailure: createAction(
    constants.EDIT_PLATFORM_INFORMATION_FAILURE
  ),

  addPlatform: createAction(constants.ADD_PLATFORM),
  addPlatformSuccess: createAction(constants.ADD_PLATFORM_SUCCESS),
  addPlatformFailure: createAction(constants.ADD_PLATFORM_FAILURE),

  getWells: createAction(constants.GET_WELLS),
  getWellsSuccess: createAction(constants.GET_WELLS_SUCCESS),
  getWellsFailure: createAction(constants.GET_WELLS_FAILURE),
  getWell: createAction(constants.GET_WELL),
  getWellSuccess: createAction(constants.GET_WELL_SUCCESS),
  getWellFailure: createAction(constants.GET_WELL_FAILURE),
  editWell: createAction(constants.EDIT_WELL),
  editWellSuccess: createAction(constants.EDIT_WELL_SUCCESS),
  editWellFailure: createAction(constants.EDIT_WELL_FAILURE),
  addWell: createAction(constants.ADD_WELL),
  addWellSuccess: createAction(constants.ADD_WELL_SUCCESS),
  addWellFailure: createAction(constants.ADD_WELL_FAILURE),

  getPipelines: createAction(constants.GET_PIPELINES),
  getPipelinesSuccess: createAction(constants.GET_PIPELINES_SUCCESS),
  getPipelinesFailure: createAction(constants.GET_PIPELINES_FAILURE),
  getPipeline: createAction(constants.GET_PIPELINE),
  getPipelineSuccess: createAction(constants.GET_PIPELINE_SUCCESS),
  getPipelineFailure: createAction(constants.GET_PIPELINE_FAILURE),
  addPipeline: createAction(constants.ADD_PIPELINE),
  addPipelineSuccess: createAction(constants.ADD_PIPELINE_SUCCESS),
  addPipelineFailure: createAction(constants.ADD_PIPELINE_FAILURE),
  editPipeline: createAction(constants.EDIT_PIPELINE),
  editPipelineSuccess: createAction(constants.EDIT_PIPELINE_SUCCESS),
  editPipelineFailure: createAction(constants.EDIT_PIPELINE_FAILURE),

  getWorkOrders: createAction(constants.GET_WORK_ORDERS),
  getWorkOrdersSuccess: createAction(constants.GET_WORK_ORDERS_SUCCESS),
  getWorkOrdersFailure: createAction(constants.GET_WORK_ORDERS_FAILURE),

  getWorkOrder: createAction(constants.GET_WORK_ORDER),
  getWorkOrderSuccess: createAction(constants.GET_WORK_ORDER_SUCCESS),
  getWorkOrderFailure: createAction(constants.GET_WORK_ORDER_FAILURE),

  createWorkOrder: promiseAction(constants.CREATE_WORK_ORDER),
  createWorkOrderSuccess: createAction(constants.CREATE_WORK_ORDER_SUCCESS),
  createWorkOrderFailure: createAction(constants.CREATE_WORK_ORDER_FAILURE),

  editWorkOrder: promiseAction(constants.EDIT_WORK_ORDER),
  editWorkOrderSuccess: createAction(constants.EDIT_WORK_ORDER_SUCCESS),
  editWorkOrderFailure: createAction(constants.EDIT_WORK_ORDER_FAILURE),

  getWellSlots: createAction(constants.GET_WELL_SLOTS),
  getWellSlotsSuccess: createAction(constants.GET_WELL_SLOTS_SUCCESS),
  getWellSlotsFailure: createAction(constants.GET_WELL_SLOTS_FAILURE),
  getWellSlot: createAction(constants.GET_WELL_SLOT),
  getWellSlotSuccess: createAction(constants.GET_WELL_SLOT_SUCCESS),
  getWellSlotFailure: createAction(constants.GET_WELL_SLOT_FAILURE),
  editWellSlot: createAction(constants.EDIT_WELL_SLOT),
  editWellSlotSuccess: createAction(constants.EDIT_WELL_SLOT_SUCCESS),
  editWellSlotFailure: createAction(constants.EDIT_WELL_SLOT_FAILURE),
  addWellSlot: createAction(constants.ADD_WELL_SLOT),
  addWellSlotSuccess: createAction(constants.ADD_WELL_SLOT_SUCCESS),
  addWellSlotFailure: createAction(constants.ADD_WELL_SLOT_FAILURE),

  getReports: createAction(constants.GET_REPORTS),
  getReportsSuccess: createAction(constants.GET_REPORTS_SUCCESS),
  getReportsFailure: createAction(constants.GET_REPORTS_FAILURE),

  getReport: createAction(constants.GET_REPORT),
  getReportSuccess: createAction(constants.GET_REPORT_SUCCESS),
  getReportFailure: createAction(constants.GET_REPORT_FAILURE),

  createReport: promiseAction(constants.CREATE_REPORT),
  createReportSuccess: createAction(constants.CREATE_REPORT_SUCCESS),
  createReportFailure: createAction(constants.CREATE_REPORT_FAILURE),
  clearReportsError: createAction(constants.CLEAR_REPORTS_ERROR),

  editReport: promiseAction(constants.EDIT_REPORT),
  editReportSuccess: createAction(constants.EDIT_REPORT_SUCCESS),
  editReportFailure: createAction(constants.EDIT_REPORT_FAILURE),

  deleteReport: createAction(constants.DELETE_REPORT),
  deleteReportSuccess: createAction(constants.DELETE_REPORT_SUCCESS),
  deleteReportFailure: createAction(constants.DELETE_REPORT_FAILURE),

  getRatings: createAction(constants.GET_RETINGS),
  getRatingsSuccess: createAction(constants.GET_RETINGS_SUCCESS),
  getRatingsFailure: createAction(constants.GET_RETINGS_FAILURE),

  editRatings: promiseAction(constants.EDIT_RETINGS),
  editRatingsSuccess: createAction(constants.EDIT_RETINGS_SUCCESS),
  editRatingsFailure: createAction(constants.EDIT_RETINGS_FAILURE),

  getPlatformStatuses: createAction(constants.GET_STATUSES),
  getPlatformStatusesSuccess: createAction(constants.GET_STATUSES_SUCCESS),
  getPlatformStatusesFailure: createAction(constants.GET_STATUSES_FAILURE),

  editStatuses: promiseAction(constants.EDIT_STATUSES),
  editStatusesSuccess: promiseAction(constants.EDIT_STATUSES_SUCCESS),
  editStatusesFailure: promiseAction(constants.EDIT_STATUSES_FAILURE),

  getAnalytics: createAction(constants.GET_ANALYTICS),
  getAnalyticsSuccess: createAction(constants.GET_ANALYTICS_SUCCESS),
  getAnalyticsFailure: createAction(constants.GET_ANALYTICS_FAILURE),
};

const platformsIsLoading = (
  state = false,
  action: PayloadedAction
): boolean => {
  switch (action.type) {
    case constants.GET_PLATFORMS:
    case constants.CREATE_REPORT:
    case constants.GET_PLATFORM:
    case constants.GET_PIPELINES:
    case constants.GET_WELLS:
    case constants.GET_WELL_SLOTS:
    case constants.GET_WORK_ORDERS:
    case constants.CREATE_WORK_ORDER:
    case constants.GET_WORK_ORDER:
    case constants.EDIT_WORK_ORDER:
    case constants.GET_REPORTS:
    case constants.GET_RETINGS:
    case constants.GET_ANALYTICS:
      return true;
    case constants.GET_PLATFORMS_SUCCESS:
    case constants.GET_PLATFORMS_FAILURE:
    case constants.GET_PLATFORM_SUCCESS:
    case constants.GET_PLATFORM_FAILURE:
    case constants.GET_PIPELINES_SUCCESS:
    case constants.GET_PIPELINES_FAILURE:
    case constants.GET_WELLS_SUCCESS:
    case constants.GET_WELLS_FAILURE:
    case constants.GET_WELL_SLOTS_SUCCESS:
    case constants.GET_WELL_SLOTS_FAILURE:
    case constants.GET_WORK_ORDERS_SUCCESS:
    case constants.GET_WORK_ORDERS_FAILURE:
    case constants.CREATE_WORK_ORDER_SUCCESS:
    case constants.CREATE_WORK_ORDER_FAILURE:
    case constants.GET_WORK_ORDER_SUCCESS:
    case constants.GET_WORK_ORDER_FAILURE:
    case constants.EDIT_WORK_ORDER_SUCCESS:
    case constants.EDIT_WORK_ORDER_FAILURE:
    case constants.GET_REPORTS_SUCCESS:
    case constants.GET_REPORTS_FAILURE:
    case constants.GET_RETINGS_SUCCESS:
    case constants.GET_RETINGS_FAILURE:
    case constants.GET_ANALYTICS_SUCCESS:
    case constants.GET_ANALYTICS_FAILURE:
      return false;
    default:
      return state;
  }
};

const initialStatePlatforms = {
  platformsData: {
    count: 0,
    offset: 0,
    limit: null,
    search: '',
    next: '',
    sortBy: '',
    filter: {},
    previous: '',
    results: [],
  },
  failure: null,
};

export const platformsReducer = (
  state = initialStatePlatforms,
  action: PayloadedAction
): IPlatforms => {
  switch (action.type) {
    case constants.GET_PLATFORMS_SUCCESS:
      return {
        ...state,
        platformsData: action.payload,
      };

    case constants.GET_PLATFORMS_FAILURE:
      return {
        ...state,
        failure: action.payload,
      };
    case constants.CLEAR_PLATFORMS:
      return {
        ...state,
        platformsData: {
          count: 0,
          offset: 0,
          limit: null,
          search: '',
          next: '',
          sortBy: '',
          filter: {},
          previous: '',
          results: [],
        },
      };
    default:
      return state;
  }
};

export const platformReducer = (
  state = null,
  action: PayloadedAction
): IPlatform => {
  switch (action.type) {
    case constants.GET_PLATFORM_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const initialStateAnalytics = {
  data: {
    platforms: {
      ratings: { 'A-Good': null, 'B-Fair': null, 'C-Poor': null },
      progress_statuses: {
        completed: null,
        in_progress: null,
        remaining: null,
      },
    },
    surveys: {
      components: {
        ehs_hazard: { true: null, false: null },
        mechanical_damage: {
          without_damage_type: null,
          corrosion_type: null,
          mechanical_damage_type: null,
        },
      },
      conductors: {
        ehs_hazard: { true: null, false: null },
        mechanical_damage: {
          without_damage_type: null,
          corrosion_type: null,
          mechanical_damage_type: null,
        },
      },
      wellheads: {
        ehs_hazard: { true: null, false: null },
        mechanical_damage: {
          without_damage_type: null,
          corrosion_type: null,
          mechanical_damage_type: null,
        },
      },
      risers: {
        ehs_hazard: { true: null, false: null },
        mechanical_damage: {
          without_damage_type: null,
          corrosion_type: null,
          mechanical_damage_type: null,
        },
      },
    },
  },
  failure: null,
};

export const analyticsReducer = (
  state = initialStateAnalytics,
  action: PayloadedAction
): IAnalytics => {
  switch (action.type) {
    case constants.GET_ANALYTICS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    case constants.GET_ANALYTICS_FAILURE:
      return {
        ...state,
        failure: action.payload,
      };
    case constants.CLEAR_PLATFORMS:
      return {
        ...state,
        data: {
          platforms: {
            ratings: { 'A-Good': null, 'B-Fair': null, 'C-Poor': null },
            progress_statuses: {
              completed: null,
              in_progress: null,
              remaining: null,
            },
          },
          surveys: {
            components: {
              ehs_hazard: { true: null, false: null },
              mechanical_damage: {
                without_damage_type: null,
                corrosion_type: null,
                mechanical_damage_type: null,
              },
            },
            conductors: {
              ehs_hazard: { true: null, false: null },
              mechanical_damage: {
                without_damage_type: null,
                corrosion_type: null,
                mechanical_damage_type: null,
              },
            },
            wellheads: {
              ehs_hazard: { true: null, false: null },
              mechanical_damage: {
                without_damage_type: null,
                corrosion_type: null,
                mechanical_damage_type: null,
              },
            },
            risers: {
              ehs_hazard: { true: null, false: null },
              mechanical_damage: {
                without_damage_type: null,
                corrosion_type: null,
                mechanical_damage_type: null,
              },
            },
          },
        },
      };
    default:
      return state;
  }
};

const initialStateEditPlatform = {
  loader: false,
  editPlatformInformationSuccess: null,
  editPlatformInformationFailure: null,
  addPlatformSuccess: null,
  addPlatformFailure: null,
};

export const editPlatformReducer = (
  state = initialStateEditPlatform,
  action: PayloadedAction
): IEditPlatformReducer => {
  switch (action.type) {
    case constants.EDIT_PLATFORM_INFORMATION:
    case constants.ADD_PLATFORM:
      return {
        ...state,
        loader: true,
      };
    case constants.EDIT_PLATFORM_INFORMATION_SUCCESS:
      return {
        ...state,
        loader: false,
        editPlatformInformationSuccess: action.payload,
      };

    case constants.EDIT_PLATFORM_INFORMATION_FAILURE:
      return {
        ...state,
        loader: false,
        editPlatformInformationFailure: action.payload,
      };

    case constants.ADD_PLATFORM_SUCCESS:
      return {
        ...state,
        loader: false,
        addPlatformSuccess: action.payload,
      };

    case constants.ADD_PLATFORM_FAILURE:
      return {
        ...state,
        loader: false,
        addPlatformFailure: action.payload,
      };

    case constants.CLEAR_PLATFORM_COMPONENT:
      return {
        ...state,
        editPlatformInformationFailure: null,
        addPlatformFailure: null,
      };
    default:
      return state;
  }
};

const initialStatePipelines = {
  pipelineData: {
    count: 0,
    offset: 0,
    limit: null,
    search: '',
    next: '',
    previous: '',
    results: [],
  },
  currentPipeline: null,
  editPipelineSuccess: null,
  addPipelineSuccess: null,
  failure: null,
  loader: false,
};

export const pipelinesReducer = (
  state = initialStatePipelines,
  action: PayloadedAction
): IPipelines => {
  switch (action.type) {
    case constants.GET_PIPELINE:
    case constants.EDIT_PIPELINE:
    case constants.ADD_PIPELINE:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_PIPELINES_SUCCESS:
      return {
        ...state,
        pipelineData: action.payload,
        loader: false,
      };
    case constants.GET_PIPELINE_SUCCESS:
      return {
        ...state,
        currentPipeline: action.payload,
        loader: false,
      };
    case constants.EDIT_PIPELINE_SUCCESS:
      return {
        ...state,
        editPipelineSuccess: action.payload,
        loader: false,
      };
    case constants.ADD_PIPELINE_SUCCESS:
      return {
        ...state,
        addPipelineSuccess: action.payload,
        loader: false,
      };
    case constants.GET_PIPELINES_FAILURE:
    case constants.GET_PIPELINE_FAILURE:
    case constants.EDIT_PIPELINE_FAILURE:
    case constants.ADD_PIPELINE_FAILURE:
      return {
        ...state,
        failure: action.payload,
        loader: false,
      };
    case constants.CLEAR_PLATFORMS:
      return {
        ...state,
        pipelineData: {
          count: 0,
          offset: 0,
          limit: null,
          search: '',
          next: '',
          previous: '',
          results: [],
        },
        failure: null,
        currentPipeline: null,
        editPipelineSuccess: null,
      };
    case constants.CLEAR_PLATFORM_COMPONENT:
      return {
        ...state,
        failure: null,
        currentPipeline: null,
        editPipelineSuccess: null,
      };
    default:
      return state;
  }
};

const initialStateWells = {
  wellsData: {
    count: 0,
    offset: 0,
    limit: null,
    search: '',
    next: '',
    previous: '',
    results: [],
  },
  currentWell: null,
  editWellSuccess: null,
  addWellSuccess: null,
  failure: null,
  loader: false,
};

export const wellsReducer = (
  state = initialStateWells,
  action: PayloadedAction
): IWells => {
  switch (action.type) {
    case constants.GET_WELL:
    case constants.EDIT_WELL:
    case constants.ADD_WELL:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_WELLS_SUCCESS:
      return {
        ...state,
        wellsData: action.payload,
      };
    case constants.GET_WELL_SUCCESS:
      return {
        ...state,
        currentWell: action.payload,
        loader: false,
      };
    case constants.EDIT_WELL_SUCCESS:
      return {
        ...state,
        editWellSuccess: action.payload,
        loader: false,
      };
    case constants.ADD_WELL_SUCCESS:
      return {
        ...state,
        addWellSuccess: action.payload,
        loader: false,
      };
    case constants.GET_WELLS_FAILURE:
    case constants.GET_WELL_FAILURE:
    case constants.EDIT_WELL_FAILURE:
    case constants.ADD_WELL_FAILURE:
      return {
        ...state,
        failure: action.payload,
        loader: false,
      };
    case constants.CLEAR_PLATFORMS:
      return {
        ...state,
        wellsData: {
          count: 0,
          offset: 0,
          limit: null,
          search: '',
          next: '',
          previous: '',
          results: [],
        },
        failure: null,
        currentWell: null,
        editWellSuccess: null,
        addWellSuccess: null,
      };
    case constants.CLEAR_PLATFORM_COMPONENT:
      return {
        ...state,
        failure: null,
        currentWell: null,
        editWellSuccess: null,
        addWellSuccess: null,
      };
    default:
      return state;
  }
};

const initialStateWorkOrders = {
  list: {
    count: 0,
    offset: 0,
    limit: null,
    search: '',
    next: '',
    previous: '',
    results: [],
  },
  current: null,
  failure: null,
};

export const workOrders = (
  state = initialStateWorkOrders,
  action: PayloadedAction
): IWorkOrders => {
  switch (action.type) {
    case constants.GET_WORK_ORDERS_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case constants.GET_WORK_ORDER_SUCCESS:
      return {
        ...state,
        current: action.payload,
        failure: null,
      };
    case constants.CREATE_WORK_ORDER_SUCCESS:
    case constants.EDIT_WORK_ORDER_SUCCESS:
      return {
        ...state,
        failure: null,
      };
    case constants.EDIT_WORK_ORDER_FAILURE:
    case constants.GET_WORK_ORDER_FAILURE:
    case constants.CREATE_WORK_ORDER_FAILURE:
    case constants.GET_WORK_ORDERS_FAILURE:
      return {
        ...state,
        failure: action.payload,
      };
    case constants.CLEAR_PLATFORMS:
      return {
        ...state,
        list: {
          count: 0,
          offset: 0,
          limit: null,
          search: '',
          next: '',
          previous: '',
          results: [],
        },
        failure: null,
      };
    case constants.CLEAR_REPORTS_ERROR:
      return {
        ...state,
        failure: null,
      };

    default:
      return state;
  }
};

const initialStateWellSlots = {
  wellSlotsData: {
    count: 0,
    offset: 0,
    limit: null,
    search: '',
    next: '',
    previous: '',
    results: [],
  },
  currentWellSlot: null,
  editWellSlotSuccess: null,
  addWellSlotSuccess: null,
  failure: null,
  loader: false,
};

export const wellSlotsReducer = (
  state = initialStateWellSlots,
  action: PayloadedAction
): IWellSlots => {
  switch (action.type) {
    case constants.GET_WELL_SLOT:
    case constants.EDIT_WELL_SLOT:
    case constants.ADD_WELL_SLOT:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_WELL_SLOTS_SUCCESS:
      return {
        ...state,
        wellSlotsData: action.payload,
      };
    case constants.GET_WELL_SLOT_SUCCESS:
      return {
        ...state,
        currentWellSlot: action.payload,
        loader: false,
      };
    case constants.EDIT_WELL_SLOT_SUCCESS:
      return {
        ...state,
        editWellSlotSuccess: action.payload,
        loader: false,
      };
    case constants.ADD_WELL_SLOT_SUCCESS:
      return {
        ...state,
        addWellSlotSuccess: action.payload,
        loader: false,
      };
    case constants.GET_WELL_SLOTS_FAILURE:
    case constants.GET_WELL_SLOT_FAILURE:
    case constants.EDIT_WELL_SLOT_FAILURE:
    case constants.ADD_WELL_SLOT_FAILURE:
      return {
        ...state,
        failure: action.payload,
        loader: false,
      };
    case constants.CLEAR_PLATFORMS:
      return {
        ...state,
        wellSlotsData: {
          count: 0,
          offset: 0,
          limit: null,
          search: '',
          next: '',
          previous: '',
          results: [],
        },
        failure: null,
        currentWellSlot: null,
        editWellSlotSuccess: null,
        addWellSlotSuccess: null,
      };
    case constants.CLEAR_PLATFORM_COMPONENT:
      return {
        ...state,
        failure: null,
        currentWellSlot: null,
        editWellSlotSuccess: null,
        addWellSlotSuccess: null,
      };
    default:
      return state;
  }
};

const initialStateReports = {
  list: {
    count: 0,
    offset: 0,
    limit: null,
    search: '',
    next: '',
    previous: '',
    results: [],
  },
  current: null,
  failure: null,
};

export const reports = (
  state = initialStateReports,
  action: PayloadedAction
): IReports => {
  switch (action.type) {
    case constants.GET_REPORTS_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case constants.GET_REPORT_SUCCESS:
      return {
        ...state,
        current: action.payload,
        failure: null,
      };
    case constants.CREATE_REPORT_SUCCESS:
    case constants.EDIT_REPORT_SUCCESS:
      return {
        ...state,
        failure: null,
      };
    case constants.EDIT_REPORT_FAILURE:
    case constants.GET_REPORT_FAILURE:
    case constants.CREATE_REPORT_FAILURE:
    case constants.GET_REPORTS_FAILURE:
      return {
        ...state,
        failure: action.payload,
      };
    case constants.CLEAR_PLATFORMS:
      return {
        ...state,
        list: {
          count: 0,
          offset: 0,
          limit: null,
          search: '',
          next: '',
          previous: '',
          results: [],
        },
        failure: null,
      };
    default:
      return state;
  }
};

const initialStateRatings = {
  data: {
    count: 0,
    offset: 0,
    limit: null,
    next: '',
    previous: '',
    results: [],
  },
  failure: null,
  loader: false,
};

const initialStatusState = {
  data: {
    count: 0,
    offset: 0,
    limit: null,
    next: '',
    previous: '',
    results: [],
  },
  failure: null,
  loader: true,
};

export const ratingsReducer = (
  state = initialStateRatings,
  action: PayloadedAction
): IRatings => {
  switch (action.type) {
    case constants.GET_RETINGS:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_RETINGS_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload,
          results: action.payload?.results.sort((a, b) => b.year - a.year),
        },
        loader: false,
      };
    case constants.GET_RETINGS_FAILURE:
    case constants.EDIT_RETINGS_FAILURE:
      return {
        ...state,
        failure: action.payload,
      };
    case constants.CLEAR_PLATFORM_COMPONENT:
      return {
        ...state,
        data: {
          count: 0,
          offset: 0,
          limit: null,
          next: '',
          previous: '',
          results: [],
        },
        failure: null,
        loader: false,
      };
    default:
      return state;
  }
};

export const platformStatusesReducer = (
  state = initialStatusState,
  action: PayloadedAction
): IRatings => {
  switch (action.type) {
    case constants.GET_STATUSES:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_STATUSES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loader: false,
      };
    case constants.GET_STATUSES_FAILURE:
    case constants.EDIT_STATUSES_FAILURE:
      return {
        ...state,
        failure: action.payload,
        loader: false,
      };
    case constants.CLEAR_PLATFORM_COMPONENT:
      return {
        ...state,
        data: {
          count: 0,
          offset: 0,
          limit: null,
          next: '',
          previous: '',
          results: [],
        },
        failure: null,
        loader: false,
      };
    default:
      return state;
  }
};

const platformsReducers = combineReducers<IPlatformsReducer>({
  platformsIsLoading,
  platformsReducer,
  platformReducer,
  editPlatformReducer,
  pipelinesReducer,
  wellsReducer,
  workOrders,
  wellSlotsReducer,
  reports,
  ratingsReducer,
  platformStatusesReducer,
  analyticsReducer,
});

export default platformsReducers;
