// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Details_details__34c7R {\n  background: white;\n  border: 1px solid #e7e7e7;\n  border-radius: 4px;\n  margin-bottom: 20px;\n}\n.Details_details__34c7R summary::-webkit-details-marker {\n  display: none;\n}\n.Details_details__34c7R summary {\n  list-style-type: none;\n  list-style: none;\n}\n.Details_details_summary__3vkcO {\n  display: flex;\n  height: 48px;\n  align-items: center;\n  padding-left: 16px;\n}\n.Details_details_summary__3vkcO button {\n  margin: 16px;\n}\n.Details_details_body___3kBZ {\n  display: flex;\n  flex-direction: column;\n  padding: 16px;\n}", "",{"version":3,"sources":["webpack://src/shared/Form/Details/Details.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,qBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,aAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;AADJ;AAGI;EACE,YAAA;AADN;AAKE;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AAHJ","sourcesContent":[".details {\n  background: white;\n  border: 1px solid #e7e7e7;\n  border-radius: 4px;\n  margin-bottom: 20px;\n\n  summary::-webkit-details-marker {\n    display: none;\n  }\n\n  summary {\n    list-style-type: none;\n    list-style: none;\n  }\n\n  &_summary {\n    display: flex;\n    height: 48px;\n    align-items: center;\n    padding-left: 16px;\n\n    button {\n      margin: 16px;\n    }\n  }\n\n  &_body {\n    display: flex;\n    flex-direction: column;\n    padding: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"details": "Details_details__34c7R",
	"details_summary": "Details_details_summary__3vkcO",
	"details_body": "Details_details_body___3kBZ"
};
export default ___CSS_LOADER_EXPORT___;
