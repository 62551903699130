const STORE_TYPE = 'customers';

export const GET_CUSTOMERS = `${STORE_TYPE}/GET_CUSTOMERS`;
export const GET_CUSTOMERS_SUCCESS = `${STORE_TYPE}/GET_CUSTOMERS_SUCCESS`;
export const GET_CUSTOMERS_FAILURE = `${STORE_TYPE}/GET_CUSTOMERS_FAILURE`;

export const GET_CUSTOMER = `${STORE_TYPE}/GET_CUSTOMER`;
export const GET_CUSTOMER_SUCCESS = `${STORE_TYPE}/GET_CUSTOMER_SUCCESS`;
export const GET_CUSTOMER_FAILURE = `${STORE_TYPE}/GET_CUSTOMER_FAILURE`;

export const CLEAR_CUSTOMERS = `${STORE_TYPE}/CLEAR_CUSTOMERS`;

export const CLEAR_CUSTOMER = `${STORE_TYPE}/CLEAR_CUSTOMER`;

export const CHANGE_CUSTOMER = `${STORE_TYPE}/CHANGE_CUSTOMER`;
export const CHANGE_CUSTOMER_SUCCESS = `${STORE_TYPE}/CHANGE_CUSTOMER_SUCCESS`;
export const CHANGE_CUSTOMER_FAILURE = `${STORE_TYPE}/CHANGE_CUSTOMER_FAILURE`;

export const GET_LOGO = `${STORE_TYPE}/GET_LOGO`;
export const GET_LOGO_SUCCESS = `${STORE_TYPE}/GET_LOGO_SUCCESS`;
export const GET_LOGO_FAILURE = `${STORE_TYPE}/GET_LOGO_FAILURE`;

export const UPLOAD_LOGO = `${STORE_TYPE}/UPLOAD_LOGO`;
export const UPLOAD_LOGO_SUCCESS = `${STORE_TYPE}/UPLOAD_LOGO_SUCCESS`;
export const UPLOAD_LOGO_FAILURE = `${STORE_TYPE}/UPLOAD_LOGO_FAILURE`;

export const DELETE_LOGO = `${STORE_TYPE}/DELETE_LOGO`;
export const DELETE_LOGO_SUCCESS = `${STORE_TYPE}/DELETE_LOGO_SUCCESS`;
export const DELETE_LOGO_FAILURE = `${STORE_TYPE}/DELETE_LOGO_FAILURE`;

export const ADD_CUSTOMER = `${STORE_TYPE}/ADD_CUSTOMER`;
export const ADD_CUSTOMER_SUCCESS = `${STORE_TYPE}/ADD_CUSTOMER_SUCCESS`;
export const ADD_CUSTOMER_FAILURE = `${STORE_TYPE}/ADD_CUSTOMER_FAILURE`;

export const ADD_CUSTOMER_ID = `${STORE_TYPE}/ADD_CUSTOMER_ID`;
export const CLEAR_CUSTOMER_ID = `${STORE_TYPE}/CLEAR_CUSTOMER_ID`;
