import cx from 'classnames';
import * as React from 'react';
import { Merge } from 'utils/typeMerge';

import Label from '../Label/Label';
import styles from './styles.module.scss';

const errorImg = require('./assets/img/error.svg');

export interface TextBoxProps
  extends Merge<
    React.HTMLProps<HTMLInputElement>,
    {
      value: any;
      onChange: (any) => void;
      onBlur: (any) => void;
    }
  > {
  inputRef: any;
  disableMargin: boolean;
  className: string;
  variant: string;
  label: string;
  error: string;
  component: any;
  helperText: any;
  startAdornment: any;
  endAdornment: any;
  inputProps: any;
  InputProps: any;
  forCalendar?: boolean;
}

interface Props {
  children: any;
}

export const Adornment: React.FC<Props> = ({ children }) => (
  <div className={styles.adornment}>
    {children}
  </div>
);

export const TextBox: React.FC<Partial<TextBoxProps>> = ({
  inputRef,
  disableMargin = false,
  className,
  inputProps,
  InputProps = {},
  name,
  startAdornment,
  endAdornment,
  component: Component = 'input',
  error,
  id,
  label,
  helperText,
  style,
  ...other
}) => {
  const start = startAdornment ?? InputProps.startAdornment;
  const end = endAdornment ?? InputProps.endAdornment;

  return (
    <div
      className={cx(
        styles.container,
        {
          [styles.container_margin]: !disableMargin,
        },
        className
      )}
      style={style}
    >
      {label && (
        <Label className={styles.container_label} htmlFor={id}>
          {label}
        </Label>
      )}
      <div
        className={cx(styles.field, {
          [styles.error]: error,
        })}
      >
        {start && <Adornment>{start}</Adornment>}
        <Component
          spellCheck
          ref={inputRef}
          id={id}
          name={name}
          className={cx(styles.field_input, {
            [styles.error]: error,
          })}
          {...other}
          {...inputProps}
        />
        {end && <Adornment>{end}</Adornment>}
      </div>

      {error && (
        <div className={styles.errorBlock}>
          <p className={styles.errorText}>{error}</p>{' '}
          <img src={errorImg} alt="" className={styles.errorImg} />
        </div>
      )}
    </div>
  );
};

export default TextBox;
