import { actions as actionsApp } from 'app/containers/App/store/duck';
import { push } from 'connected-react-router';
import { api } from 'core/config/api';
import { toastr } from 'react-redux-toastr';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { catchError } from 'utils/sagaUtils';

import * as constants from './constants';
import { actions } from './duck';
import { listUsersSelector, userIdSelector } from './selectors';

export function* fetchGetUsersInfoWorker(action) {
  const {
    payload: { id }
  } = action;

  try {
    const fetchGetUsersInfo = () =>
      api
        .get(`/users/${id}/`)
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchGetUsersInfo);

    yield put(actions.getUsersInfoSuccess(result));
  } catch (error) {
    yield put(actions.getUsersInfoFailure(error));
  }
}

export function* fetchChangeUsersNameWorker(action) {
  const {
    payload: {
      url,
      data: { id, avatar, ...other }
    },
    meta: { resolve, reject }
  } = action;

  try {
    if (avatar) {
      let formData = new FormData();
      if (typeof avatar == 'object') {
        //if new file
        formData.append('file', avatar);

        //get uploadLink
        const fetchGetAvatar = () =>
          api
            .get(`/users/${id}/avatar/`)
            .then(response => response.data)
            .catch(catchError);

        const resultGetAvatar = yield call(fetchGetAvatar);

        const uploadLink = resultGetAvatar.upload_link;

        const fetchUploadAvatar = () =>
          api
            .post(uploadLink, formData)
            .then(response => response.data)
            .catch(catchError);

        const result = yield call(fetchUploadAvatar);
        yield put(actions.uploadAvatarSuccess(result));
      }
    } else if (avatar === null) {
      const fetchDeleteAvatar = () =>
        api
          .delete(`/users/${id}/avatar/`)
          .then(response => response.data)
          .catch(catchError);

      const result = yield call(fetchDeleteAvatar);
      yield put(actions.deleteAvatarSuccess(result));
    }

    //edit info

    const fetchChangeUsersName = () =>
      api
        .patch(`/users/${id}/`, {
          ...other
        })
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchChangeUsersName);

    yield put(actions.changeUsersNameSuccess(result));
    yield call(resolve);

    yield put(actionsApp.fetchMe());

    toastr.success('', 'Profile data has been changed');
    yield put(push(url));

    yield put(actions.getUsersInfo({ id }));
    yield put(actions.getUsers({}));
  } catch (error) {
    yield put(actions.changeUsersNameFailure(error));
    yield call(reject, error);
  }
}

export function* fetchGetPasswordCodeWorker(action) {
  try {
    const fetchSendCodeRecovery = () =>
      api
        .post('/users/account/change_password/request/')
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchSendCodeRecovery);

    yield put(actions.getPasswordCodeSuccess(result));
  } catch (error) {
    yield put(actions.getPasswordCodeFailure(error));
  }
}

export function* fetchSendPasswordCodeWorker(action) {
  const {
    payload: { code }
  } = action;

  try {
    const fetchSendPasswordCode = () =>
      api
        .post('/users/account/change_password/confirm_code/ ', {
          code
        })
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchSendPasswordCode);
    yield put(actions.sendPasswordCodeSuccess(result));
  } catch (error) {
    yield put(actions.sendPasswordCodeFailure(error));
  }
}

export function* fetchFinishChangePasswordWorker(action) {
  const {
    payload: {
      old_password,
      new_password_1,
      new_password_2,
      change_password_token
    }
  } = action;

  try {
    const fetchFinishChangePassword = () =>
      api
        .post('/users/account/change_password/finish/', {
          old_password,
          new_password_1,
          new_password_2,
          change_password_token
        })
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchFinishChangePassword);

    yield put(actions.finishChangePasswordSuccess(result));
    toastr.success('', 'Password has been changed');
  } catch (error) {
    yield put(actions.finishChangePasswordFailure(error));
  }
}

//users
export function* fetchUsersWorker(action) {
  const {
    sortBy,
    search,
    role,
    limit,
    offset,
    is_archived,
    customers
  } = action.payload;

  const {
    search: prevSearch,
    role: prevRole,
    sortBy: prevSortBy,
    limit: prevLimit,
    offset: prevOffet
  } = yield select(listUsersSelector);

  let queryParams = {
    is_archived,
    customers,
    role: role ?? prevRole,
    search: search ?? prevSearch,
    ordering: sortBy ?? prevSortBy,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0
  };

  try {
    const fetchGetUsers = () =>
      api
        .get('/users/', {
          params: { ...queryParams }
        })
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchGetUsers);

    yield put(
      actions.getUsersSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        search: queryParams.search,
        sortBy: queryParams.ordering,
        role: queryParams.role
      })
    );
  } catch (error) {
    yield put(actions.getUsersFailure(error));
  }
}

//avatar
export function* fetchGetAvatarWorker(action) {
  const { payload: id } = action;

  try {
    const fetchGetAvatar = () =>
      api
        .get(`/users/${id}/avatar/`)
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchGetAvatar);
    yield put(actions.getAvatarSuccess(result));
  } catch (error) {
    yield put(actions.getAvatarFailure(error));
  }
}

export function* fetchUploadAvatarWorker(action) {
  const { id, uploadLink, formData } = action.payload;

  try {
    const fetchUploadAvatar = () =>
      api
        .post(uploadLink, formData)
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchUploadAvatar);
    yield put(actions.uploadAvatarSuccess(result));

    yield put(actionsApp.fetchMe());
    yield put(actions.getUsersInfo({ id }));
  } catch (error) {
    yield put(actions.uploadAvatarFailure(error));
  }
}

export function* fetchDeleteAvatarWorker(action) {
  const { payload: id } = action;

  try {
    const fetchDeleteAvatar = () =>
      api
        .delete(`/users/${id}/avatar/`)
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchDeleteAvatar);
    yield put(actions.deleteAvatarSuccess(result));
  } catch (error) {
    yield put(actions.deleteAvatarFailure(error));
  }
}

//add user
export function* fetchAddUserWorker(action) {
  const { formData, first_name, last_name, email, role, password } = action.payload;

  const userId = yield select(userIdSelector);

  let id, result, resultUploadAvatar;
  //get id
  try {
    if (!userId) {
      const fetchAddUser = () =>
        api
          .post(`/users/`, {
            first_name,
            last_name,
            email,
            password,
            role
          })
          .then(response => response.data)
          .catch(catchError);

      result = yield call(fetchAddUser);
      yield put(actions.addUserId(result?.id));
    }

    // if was avatar's error, but user already exists
    id = userId ? userId : result?.id || null;

    //get uploadLink
    if (id) {
      const fetchGetAvatar = () =>
        api
          .get(`/users/${id}/avatar/`)
          .then(response => response.data)
          .catch(catchError);

      const resultGetAvatar = yield call(fetchGetAvatar);

      //add  avatar
      if (formData) {
        const uploadLink = resultGetAvatar.upload_link;

        const fetchUploadAvatar = () =>
          api
            .post(uploadLink, formData)
            .then(response => response.data)
            .catch(catchError);

        resultUploadAvatar = yield call(fetchUploadAvatar);
        yield put(actions.clearUserId());
      }
    }

    yield put(actions.addUserSuccess(resultUploadAvatar));

    yield put(push('/users/active'));
  } catch (error) {
    yield put(actions.addUserFailure(error));
  }
}

export default function* usersSaga() {
  yield takeLatest(constants.GET_USERS_INFO, fetchGetUsersInfoWorker);
  yield takeLatest(constants.CHANGE_USERS_NAME, fetchChangeUsersNameWorker);
  yield takeLatest(constants.GET_PASSWORD_CODE, fetchGetPasswordCodeWorker);
  yield takeLatest(constants.SEND_PASSWORD_CODE, fetchSendPasswordCodeWorker);
  yield takeLatest(
    constants.FINISH_CHANGE_PASSWORD,
    fetchFinishChangePasswordWorker
  );
  yield takeLatest(constants.GET_USERS, fetchUsersWorker);
  yield takeLatest(constants.GET_AVATAR, fetchGetAvatarWorker);
  yield takeLatest(constants.UPLOAD_AVATAR, fetchUploadAvatarWorker);
  yield takeLatest(constants.DELETE_AVATAR, fetchDeleteAvatarWorker);
  yield takeLatest(constants.ADD_USER, fetchAddUserWorker);
}
