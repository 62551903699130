import { userInfoSelector } from 'app/containers/App/store/selectors';
import { IGlobalState } from 'core/reducers/interface';
import { connect } from 'react-redux';
import { IUser, IUserRole } from 'users/store/interfaces';

export interface PermissionsContainerProps {
  userInfo?: IUser;
  roles: IUserRole[];
  children: any;
  fallback?: any;
}

const PermissionsContainer: React.FC<PermissionsContainerProps> = ({
  roles,
  userInfo,
  children,
  fallback = null
}) => {
  return roles.includes(userInfo.role) ? children : fallback;
};

export default connect((state: IGlobalState) => ({
  userInfo: userInfoSelector(state)
}))(PermissionsContainer);
