import * as React from 'react';

export const HookHiddenInput = ({ name, register, unregister }) => {
  React.useEffect(() => {
    const names = !Array.isArray(name) ? [name] : name;
    names.forEach(x => register({ name: x }));
    return () => names.forEach(x => unregister(x));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...name, register, unregister]);

  return null;
};

export const HookFieldWrapper = ({
  defaultValue,
  name,
  validation = null,
  register,
  unregister,
  setValue,
  field: Field,
  onChange = null,
  ...other
}) => {
  const [value, setReactSelect] = React.useState(defaultValue);

  const handleChange = option => {
    setValue(name, option);
    setReactSelect(option);
    onChange?.(option);
  };

  React.useEffect(() => {
    register({ name }, validation);
    return () => unregister(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, register, unregister]);

  return <Field value={value} onChange={handleChange} {...other} />;
};
