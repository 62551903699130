// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__3Tip9 {\n  display: flex;\n  flex: 1 1;\n  flex-direction: column;\n  position: relative;\n  overflow: hidden;\n}", "",{"version":3,"sources":["webpack://src/shared/Containers/Container/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,sBAAA;EACA,kBAAA;EACA,gBAAA;AACF","sourcesContent":[".container {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  position: relative;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__3Tip9"
};
export default ___CSS_LOADER_EXPORT___;
