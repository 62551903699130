import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import moment from 'moment';
import { IPlatform, IWorkOrder } from 'platforms/store/interfaces';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { TableCell, TableRow } from 'shared/Table';
import { saveFile } from 'utils/photos';

import styles from '../../PlatformComponent.module.scss';
import PermissionsContainer from 'shared/Permissions/PermissionsContainer';

const defaultImage = require('surveys/assets/img/avatar.svg');

export async function saveWorkOrder(event, file, title) {
  event.stopPropagation();
  event.preventDefault();

  try {
    const response = await fetch(file, {
      //mode: 'no-cors',
      cache: 'no-cache',
      headers: {
        Accept: 'application/pdf'
      }
    });

    saveFile(await response.arrayBuffer(), 'application/pdf', title + '.pdf');
  } catch (error) {
    console.log(error);
  }
}

export interface WorkOrderItemProps {
  dataPlatform: IPlatform;
  item: IWorkOrder;
}

const WorkOrderItem: React.FC<WorkOrderItemProps> = ({
  item,
  dataPlatform
}) => {
  return (
    <TableRow
      component={NavLink}
      to={`/platforms/active/${dataPlatform?.id}/orders/${item.id}`}
      key={item.id}
      className={styles.navLink}
      style={{ cursor: 'default' }}
    >
      <TableCell variant="medium" className={styles.navLink}>
        {item.title}
      </TableCell>
      <TableCell variant="small" className={styles.navLink}>
        {moment(item.created_at).isValid()
          ? moment(item.created_at).format('MM-DD-YYYY')
          : ''}
      </TableCell>
      <TableCell variant="medium">
        <img src={defaultImage} alt="avatar" />
        <span>
          {item.creator?.first_name} {item.creator?.last_name}
        </span>
      </TableCell>
      <TableCell variant="small" className={styles.navLink}>
        {item.id}
      </TableCell>
      <TableCell variant="small" className={styles.navLink}>
        {item.file && !dataPlatform?.is_archived && (
          <div
            className={styles.edit}
            onClick={event => saveWorkOrder(event, item.file, item.title)}
          >
            <DownloadIcon />
          </div>
        )}
        {!dataPlatform?.is_archived && item?.is_verified === false && item.report_data && (
          <PermissionsContainer roles={['admin', 'engineer']}>
            <NavLink
              to={`/platforms/active/${dataPlatform?.id}/orders/${item.id}/edit`}
              className={styles.edit}
            >
              <EditIcon />
            </NavLink>
          </PermissionsContainer>
        )}
      </TableCell>
    </TableRow>
  );
};

export default WorkOrderItem;
