// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_field__P3lNE {\n  margin-bottom: 40px;\n}\n\n.styles_field_avatar__amOb3 {\n  width: 120px;\n  height: 120px;\n  min-height: 120px;\n  margin-bottom: 60px;\n}", "",{"version":3,"sources":["webpack://src/shared/Profile/Field/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,iBAAA;EACA,mBAAA;AACF","sourcesContent":[".field {\n  margin-bottom: 40px;\n}\n\n.field_avatar {\n  width: 120px;\n  height: 120px;\n  min-height: 120px;\n  margin-bottom: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": "styles_field__P3lNE",
	"field_avatar": "styles_field_avatar__amOb3"
};
export default ___CSS_LOADER_EXPORT___;
