// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_app_container__2kUiV {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  background: #f7f7f7;\n}\n\n.styles_app_content__3ioUI {\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n  height: 100vh;\n  width: 100%;\n  transition: 0.07s;\n  background-color: #f7f7f7;\n}\n\n.styles_block__3lh-U {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1;\n  overflow: hidden;\n  padding: 26px;\n  height: 100%;\n  position: relative;\n}", "",{"version":3,"sources":["webpack://src/app/components/AppContainer/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EAEA,aAAA;EACA,WAAA;EAEA,iBAAA;EACA,yBAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EAEA,gBAAA;EACA,aAAA;EACA,YAAA;EAEA,kBAAA;AAHF","sourcesContent":[".app_container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  background: #f7f7f7;\n}\n\n.app_content {\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n\n  height: 100vh;\n  width: 100%;\n\n  transition: 0.07s;\n  background-color: #f7f7f7;\n}\n\n.block {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n\n  overflow: hidden;\n  padding: 26px;\n  height: 100%;\n\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app_container": "styles_app_container__2kUiV",
	"app_content": "styles_app_content__3ioUI",
	"block": "styles_block__3lh-U"
};
export default ___CSS_LOADER_EXPORT___;
