import { withFormik } from 'formik';
import * as React from 'react';
import { Button } from 'shared/Button';
import { TextBox } from 'shared/Form';
import Preloader from 'shared/Preloader';

import styles from './styles.module.scss';

interface IProps {
  failure?: any;
  values: {
    code: string;
  };
  isLoading: boolean;
  touched?: any;
  errors: FormErrors;
  mailRecovery: string;
  recoveryStep: number;
  handleBlur: () => void;
  handleChange: () => void;
  handleSubmit: () => void;
  sendRecoveryCode: (value: any) => void;
}

interface FormValues {
  code: string;
}

interface FormErrors {
  code?: string;
}

const formikEnhancer = withFormik<any, any>({
  enableReinitialize: true,
  validate: (values: FormValues) => {
    let errors: FormErrors = {};
    const { code } = values;

    if (!code) {
      errors.code = 'Code is empty';
    } else if (code.length < 4) {
      errors.code = 'Code must consist of 4 symbols';
    }

    return errors;
  },
  mapPropsToValues: () => {
    return {
      code: ''
    };
  },
  handleSubmit: (values, props: any) => {
    const {
      props: { sendRecoveryCode, mailRecovery }
    } = props;

    const value = { code: values.code, email: mailRecovery };

    sendRecoveryCode(value);
  },
  displayName: 'RecoveryStep2'
});

class RecoveryStep2 extends React.Component<IProps> {
  render() {
    const {
      values,
      errors,
      failure,
      isLoading,
      handleBlur,
      handleSubmit,
      handleChange
    } = this.props;
    return (
      <form onSubmit={handleSubmit} className={styles.recoveryForm}>
        <p className={styles.text}>
          Type the code we sent to your email in the form below.
        </p>
        <div>
          <TextBox
            id="code"
            name="code"
            placeholder="Code"
            maxLength={4}
            value={values.code}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors?.code ?? failure?.code}
          />
        </div>
        {failure && failure.detail && (
          <p className={styles.error}>{failure.detail}</p>
        )}
        <div className={styles.submitButton}>
          <Button disabled={isLoading} fullWidth variant="solid" type="submit">
            {isLoading ? <Preloader size="button" /> : 'SEND'}
          </Button>
        </div>
      </form>
    );
  }
}
export default formikEnhancer(RecoveryStep2);
