import { withFormik } from 'formik';
import * as React from 'react';
import { Button } from 'shared/Button';
import { PasswordField, TextBox } from 'shared/Form/TextBox';
import Preloader from 'shared/Preloader';

import styles from './styles.module.scss';

interface IProps {
  logIn: (value: any) => void;
  showRecovery: () => void;
  failure?: any;
  isLoading: boolean;
  values: {
    login: string;
    password: string;
  };
  errors: FormErrors;
  handleChange: () => void;
  handleSubmit: () => void;
  handleBlur: () => void;
  touched?: any;
}

interface FormValues {
  login: string;
  password: string;
}

interface FormErrors {
  login?: string;
  password?: string;
}

const formikEnhancer = withFormik<any, any>({
  enableReinitialize: true,
  validate: (values: FormValues) => {
    let errors: FormErrors = {};
    const { login, password } = values;

    if (!login) {
      errors.login = 'Login is empty';
    }

    if (!password) {
      errors.password = 'Password is empty';
    }
    return errors;
  },
  mapPropsToValues: () => {
    return {
      login: '',
      password: ''
    };
  },
  handleSubmit: (values, props: any) => {
    const {
      props: { logIn }
    } = props;

    logIn(values);
  },
  displayName: 'LoginForm'
});

class LoginForm extends React.Component<IProps> {
  showRecovery = () => {
    const { showRecovery } = this.props;
    showRecovery();
  };

  render() {
    const {
      values,
      errors,
      failure,
      isLoading,
      handleBlur,
      handleSubmit,
      handleChange
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className={styles.loginForm}>
        <p className={styles.title}>LOGIN</p>
        <div>
          <TextBox
            type="email"
            id="login"
            placeholder="Login"
            name="login"
            value={values.login}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors?.login ?? failure?.email}
          />
        </div>
        <div>
          <PasswordField
            id="password"
            placeholder="Password"
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors?.password ?? failure?.password}
          />
        </div>
        {failure && failure.detail && (
          <p className={styles.error}>{failure.detail}</p>
        )}
        <div className={styles.submitButton}>
          <Button fullWidth variant="solid" disabled={isLoading} type="submit">
            {isLoading ? <Preloader size="button" /> : 'LOG IN'}
          </Button>
        </div>

        <p className={styles.link} onClick={this.showRecovery}>
          Forgot your password?
        </p>
      </form>
    );
  }
}
export default formikEnhancer(LoginForm);
