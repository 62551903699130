import cx from 'classnames';
import * as React from 'react';
import { Button } from 'shared/Button';
import AddIcon, {
  ReactComponent as AddIconComponent
} from 'shared/InputUploadImg/assets/img/photo_add.svg';
import Typography from 'shared/Typography';

import Label from '../Label/Label';
import styles from './PhotoSelect.module.scss';

export interface PhotoSelectProps {
  style: object;
  showDelete: boolean;
  photoButton: any;
  photo: any;
  index: number;
  name: string;
  label: string;
  error: string;
  onChange: (any) => void;
}

export class PhotoSelect extends React.Component<Partial<PhotoSelectProps>> {
  static defaultProps = {
    showDelete: false
  };
  handleChange = event => {
    const { onChange } = this.props;
    onChange(event.target.files[0]);
    event.target.value = null;
  };
  handleDelete = () => {
    const { onChange } = this.props;
    onChange(null);
  };
  render() {
    const {
      showDelete,
      name,
      label,
      error,
      photo,
      photoButton: PhotoButton = 'div',
      index,
      style
    } = this.props;

    return (
      <div className={styles.container} style={style}>
        <input
          id={name + 'file'}
          type="file"
          accept=".jpg, .jpeg, .png"
          onChange={this.handleChange}
          className={styles.input}
        />
        {label && <Label>{label}</Label>}
        <div className={styles.buttons}>
          <div className={styles.pic}>
            <PhotoButton className={styles.preview} photo={index}>
              <img
                alt=""
                src={photo ?? AddIcon}
                className={cx(styles.preview, {
                  [styles.preview_default]: !photo
                })}
              />
            </PhotoButton>
            {!photo && (
              <label htmlFor={name + 'file'} className={styles.label}>
                <AddIconComponent />
              </label>
            )}
          </div>
          {error && <Typography color="error">{error}</Typography>}
        </div>
        <div className={styles.buttons}>
          {PhotoButton && photo && (
            <Button
              component="label"
              htmlFor={name + 'file'}
              className={styles.button}
              variant="outlined"
            >
              Change photo
            </Button>
          )}
          {showDelete && photo && (
            <Button variant="outlined" onClick={this.handleDelete}>
              Delete photo
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default PhotoSelect;
