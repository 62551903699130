import cx from 'classnames';
import * as React from 'react';
import {
  NavLink,
  Route,
  RouteComponentProps,
  withRouter
} from 'react-router-dom';

import images from './assets/img/img';
import { menu } from './navList';
import styles from './styles.module.scss';
import PermissionsContainer from 'shared/Permissions/PermissionsContainer';

function renderSubMenu(list, isShortMenu: boolean): JSX.Element {
  return (
    <ul className={styles.sub_menu}>
      {list.map(i => {
        const Icon = images[i.name];
        return (
          <li key={i.id}>
            <NavLink
              key={i.id}
              to={i.path}
              className={cx(styles.link, {
                [styles.link_short]: isShortMenu
              })}
              activeClassName={styles.link_active}
              id={`menu__${i.idSlug}`}
            >
              <Icon className={styles.icon} />
              <p className={styles.text}>{i.name}</p>
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
}

interface IProps {
  toggleShort: (value) => void;
  isShort: boolean;
}

type PropType = RouteComponentProps & IProps;

const Navigation = (props: PropType) => {
  const showMenu = () => {
    const { isShort, toggleShort } = props;
    toggleShort(!isShort);
  };
  const { isShort } = props;
  const MenuIcon = images['manuImg'];
  return (
    <nav
      className={cx(styles.app_navigation, {
        [styles.isShortMenu]: isShort
      })}
    >
      <div
        className={cx(styles.menuHeader, {
          [styles.isShortMenu]: isShort
        })}
      >
        <h1
          className={cx(styles.title, {
            [styles.isShortMenu]: isShort
          })}
        >
          MENU
        </h1>
        <button onClick={showMenu}>
          <MenuIcon
            className={cx(styles.menuImg, {
              [styles.menuImgRotate]: isShort
            })}
          />
        </button>
      </div>

      <div className={styles.menu}>
        <ul>
          {menu.map(
            ({
              id,
              name,
              path,
              idSlug,
              subUl,
              roles = ['admin', 'engineer', 'client'] as any
            }) => {
              const Icon = images[name];
              return (
                <PermissionsContainer key={id} roles={roles}>
                  <li>
                    <NavLink
                      to={path}
                      className={cx(styles.section, {
                        [styles.section_short]: isShort
                      })}
                      activeClassName={styles.section_active}
                      id={idSlug}
                    >
                      <Icon className={styles.icon} />
                      <p className={styles.text}>{name}</p>
                    </NavLink>

                    {subUl && (
                      <Route
                        path={path}
                        render={() => renderSubMenu(subUl, isShort)}
                      />
                    )}
                  </li>
                </PermissionsContainer>
              );
            }
          )}
        </ul>
      </div>
    </nav>
  );
};

export default withRouter(Navigation);
