import cx from 'classnames';
import React, { forwardRef } from 'react';

import styles from './Button.module.scss';

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  disabled: boolean;
  fullWidth: boolean;
  component: any;
  color: 'default' | 'accent' | 'contrast';
  variant: 'default' | 'solid' | 'outlined';
}

export const Button: React.FC<Partial<ButtonProps>> = forwardRef(
  (
    {
      disabled,
      component: Component = 'button',
      type = 'button',
      color = 'default',
      className,
      variant = 'text',
      fullWidth,
      ...other
    },
    ref
  ) => (
    <Component
      ref={ref}
      type={type}
      className={cx(
        styles.button,
        {
          //[styles.button_disabled]: disabled,
          [styles.button_full_width]: fullWidth
        },
        styles['button_variant_' + variant],
        styles['button_color_' + (variant === 'solid' ? 'contrast' : color)],
        className
      )}
      disabled={disabled}
      {...other}
    />
  )
);

export default Button;
