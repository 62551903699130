import { push } from 'connected-react-router';
import { api } from 'core/config/api';
import { toastr } from 'react-redux-toastr';
import { call, put, takeLatest } from 'redux-saga/effects';
import { catchError } from 'utils/sagaUtils';

import * as constants from './constants';
import { actions } from './duck';

//Types
export function* fetchTypesWorker(action) {
  const { prefix } = action.payload;

  try {
    const fetchGetTypes = () =>
      api
        .get(`/dictionaries/${prefix}/`, { params: { limit: 9999 } })
        .then(response => response.data)
        .catch(catchError);
    const result = yield call(fetchGetTypes);

    yield put(actions.getTypesSuccess(result));
  } catch (error) {
    yield put(actions.getTypesFailure(error));
  }
}

//add type
export function* fetchAddTypeWorker(action) {
  const {
    payload: { prefix, ...data }
  } = action;

  try {
    const fetchAddType = () =>
      api
        .post(`/dictionaries/${prefix}/`, {
          data
        })
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchAddType);

    yield put(actions.addTypeSuccess(result));

    toastr.success('', 'Data has been changed');

    yield put(push(`/directories/${prefix}`));
  } catch (error) {
    yield put(actions.addTypeFailure(error));
  }
}

//edit type
export function* fetchEditTypeWorker(action) {
  const {
    payload: { prefix, data },
    meta: { resolve, reject }
  } = action;

  try {
    const fetchEditType = () =>
      api
        .post(`/dictionaries/${prefix}/`, { data })
        .then(response => response.data)
        .catch(error => {
          if (error.message === 'Network Error') {
            throw new Error('No internet connection');
          }
          switch (error.response.status) {
            case 400:
            case 401:
            case 403:
            case 429:
              throw { data: error.response.data?.data };
            case 503:
            case 500:
              throw { detail: 'Server error.' };
            default:
              break;
          }
        });
    const result = yield call(fetchEditType);

    yield put(actions.editTypeSuccess(result));

    toastr.success('', 'Data has been changed');
    yield call(resolve);

    yield put(actions.getTypes({ prefix }));

    yield put(push(`/directories/${prefix}`));
  } catch (error) {
    yield put(actions.editTypeFailure(error));
    yield call(reject, error);
  }
}

//delete type
export function* fetchDeleteTypeWorker(action) {
  const {
    payload: { prefix, id }
  } = action;

  try {
    const fetchDeleteType = () =>
      api
        .delete(`/dictionaries/${prefix}/${id}/`)
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchDeleteType);

    yield put(actions.deleteTypeSuccess(result));
  } catch (error) {
    yield put(actions.deleteTypeFailure(error));
  }
}

export default function* dictionariesSaga() {
  yield takeLatest(constants.GET_TYPES, fetchTypesWorker);
  yield takeLatest(constants.ADD_TYPE, fetchAddTypeWorker);
  yield takeLatest(constants.EDIT_TYPE, fetchEditTypeWorker);
  yield takeLatest(constants.DELETE_TYPE, fetchDeleteTypeWorker);
}
