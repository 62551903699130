import * as React from 'react';

import { ReactComponent as SearchIcon } from './assets/img/search.svg';
import { ReactComponent as CalendarIcon } from './assets/img/calendar.svg';
import { TextBox, TextBoxProps } from './TextBox';

export const SearchField: React.FC<Partial<TextBoxProps>> = (props) => (
  <TextBox
    disableMargin
    placeholder="Search"
    endAdornment={props.forCalendar ? <CalendarIcon /> : <SearchIcon />}
    {...props}
  />
);

export default SearchField;
