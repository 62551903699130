// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_paper__1worQ {\n  display: flex;\n  flex: 1 1;\n  flex-direction: column;\n  height: 100%;\n  background-color: #fff;\n  overflow: auto;\n}\n\n.styles_paper_padding_none__1s8ly {\n  padding: 0;\n}\n\n.styles_paper_padding_normal__2xolO {\n  padding: 32px;\n}", "",{"version":3,"sources":["webpack://src/shared/Containers/Paper/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,sBAAA;EACA,YAAA;EACA,sBAAA;EACA,cAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".paper {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  height: 100%;\n  background-color: #fff;\n  overflow: auto;\n}\n\n.paper_padding_none {\n  padding: 0;\n}\n\n.paper_padding_normal {\n  padding: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": "styles_paper__1worQ",
	"paper_padding_none": "styles_paper_padding_none__1s8ly",
	"paper_padding_normal": "styles_paper_padding_normal__2xolO"
};
export default ___CSS_LOADER_EXPORT___;
