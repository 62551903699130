import cx from 'classnames';
import * as React from 'react';
import { Button } from 'shared/Button';
import PhotoGallery from 'shared/Photo/PhotoGallery';
import { ISurveyPhoto } from 'surveys/store/interfaces';

import { Group, GroupRow } from '../Group/Group';
import Label from '../Label/Label';
import TextArea from '../TextArea/TextArea';
import styles from './PhotoControlContainer.module.scss';
import PhotoSelect from './PhotoSelect';
import UploadButton from './UploadButton';

export function validatePhotos(photos: any[] = []) {
  let photosErrors = photos
    .map((photo, index) => {
      const { preview, links, file } = photo;

      const photoErrors = [];
      if (preview == null && links == null && file == null)
        photoErrors.push(['preview', 'Photo is required']);

      // Object.entries(photo)
      //   .map(([field, value]) => {
      //     if (photo.delete) return null;
      //     if (isDefaultValue(value)) return [field, 'This field is required'];
      //   })
      //   .filter(x => x);

      if (photoErrors.length === 0) return null;

      return [
        index.toString(),
        photoErrors.reduce(
          (acc, [key, value]) =>
            Object.assign(acc, {
              [key]: value
            }),
          {}
        )
      ];
    })
    .filter(x => x);

  if (photosErrors.length > 0)
    return photosErrors.reduce(
      (acc, [key, value]) =>
        Object.assign(acc, {
          [key.toString()]: value
        }),
      {}
    );
}

export interface EditablePhoto extends ISurveyPhoto {
  delete: boolean;
  upload: boolean;
  file: File | Blob;
  preview: any;
}

interface PhotoControlContainerProps {
  label: string;
  property: string;
  field: any;
  addButton: any;
  newEntry: any;
  touched: any;
  errors: any;
  is_verified: boolean;
  photos: Partial<EditablePhoto>[];
  setFieldValue: (field: any, value: any) => void;
  handleChange: (any) => void;
  handleBlur: (any) => void;
}

const PhotoControlContainer: React.FC<Partial<PhotoControlContainerProps>> = ({
  label = 'Photos',
  property = 'photos',
  newEntry = newPhotoEntry,
  field: Component = PhotoField,
  addButton: AddButton = UploadButton,
  errors,
  touched,
  is_verified,
  photos = [],
  setFieldValue,
  handleChange,
  handleBlur
}) => {
  const handleFiles = React.useCallback(
    newFile => {
      let newFiles = photos;
      const newOrder =
        Math.max(0, ...newFiles.filter(x => !x.delete).map(x => x.order)) + 1;
      const newItem = {
        ...newEntry,
        ...newFile,
        order: newOrder,
        upload: true
      };
      newFiles.push(newItem);
      setFieldValue(
        property,
        newFiles.sort((a, b) => a.order - b.order)
      );
    },
    [photos, newEntry, setFieldValue, property]
  );

  const handleDelete = React.useCallback(
    index => () => {
      const photo = photos[index];
      if (photo.upload)
        setFieldValue(
          property,
          photos.filter((_, i) => i !== index).sort((a, b) => a.order - b.order)
        );
      else setFieldValue(`${property}[${index}].delete`, !photo.delete);
    },
    [photos, setFieldValue, property]
  );

  return (
    <PhotoGallery
      property={property}
      photos={photos}
      setFieldValue={setFieldValue}
    >
      {Button => (
        <React.Fragment>
          <Label>{label}</Label>
          {photos.map((photo, index) => (
            <Component
              key={index}
              index={index}
              is_verified={is_verified}
              photoButton={Button}
              errors={errors?.[index]}
              touched={touched?.[index]}
              name={`${property}[${index}]`}
              photo={photo}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              onDelete={handleDelete(index)}
            />
          ))}
          <AddButton name={property} onAdd={handleFiles} />
        </React.Fragment>
      )}
    </PhotoGallery>
  );
};

export interface PhotoFieldProps {
  touched: any;
  errors: any;
  photo: EditablePhoto;
  photoButton: any;
  index: number;
  name: string;
  handleBlur: any;
  handleChange: any;
  setFieldValue: any;
  onDelete: any;
  is_verified: boolean;
}

const newPhotoEntry = {
  caption: ''
};

class PhotoField extends React.Component<Partial<PhotoFieldProps>> {
  handlePhoto = file => {
    const { name, photo, setFieldValue } = this.props;
    const newPhoto = {
      ...photo,
      links: null,
      upload: true,
      file: file,
      preview: file ? file.preview ?? URL.createObjectURL(file) : null
    };
    setFieldValue(name, newPhoto);
  };
  render() {
    const {
      photoButton,
      index,
      touched,
      errors,
      photo,
      name,
      handleChange,
      handleBlur,
      onDelete,
      is_verified
    } = this.props;

    return (
      <Group
        className={cx(styles.field_container, {
          [styles.field_delete]: photo.delete
        })}
      >
        <GroupRow>
          <PhotoSelect
            index={index}
            name={name}
            photo={photo.preview ?? photo.links?.medium}
            photoButton={photoButton}
            onChange={this.handlePhoto}
            error={touched?.preview && errors?.preview}
            style={{ flex: 1 }}
          />
          {!is_verified && (
            <Button variant="outlined" onClick={onDelete}>
              {photo.delete ? 'Restore' : 'Delete'}
            </Button>
          )}
        </GroupRow>
        <GroupRow>
          <TextArea
            inputProps={{ className: styles.field_textarea }}
            disableMargin
            placeholder="Caption"
            name={`${name}.caption`}
            value={photo.caption}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched?.caption && errors?.caption}
          />
        </GroupRow>
      </Group>
    );
  }
}

export default PhotoControlContainer;
