const STORE_TYPE = 'survey';

export const CLEAR_LIST = `${STORE_TYPE}/CLEAR_LIST`;

export const GET_COMPONENTS = `${STORE_TYPE}/GET_COMPONENTS`;
export const GET_COMPONENTS_SUCCESS = `${STORE_TYPE}/GET_COMPONENTS_SUCCESS`;
export const GET_COMPONENTS_FAILURE = `${STORE_TYPE}/GET_COMPONENTS_FAILURE`;

export const GET_CONDUCTORS = `${STORE_TYPE}/GET_CONDUCTORS`;
export const GET_CONDUCTORS_SUCCESS = `${STORE_TYPE}/GET_CONDUCTORS_SUCCESS`;
export const GET_CONDUCTORS_FAILURE = `${STORE_TYPE}/GET_CONDUCTORS_FAILURE`;

export const GET_RISERS = `${STORE_TYPE}/GET_RISERS`;
export const GET_RISERS_SUCCESS = `${STORE_TYPE}/GET_RISERS_SUCCESS`;
export const GET_RISERS_FAILURE = `${STORE_TYPE}/GET_RISERS_FAILURE`;

export const GET_RISER_CLAMPS = `${STORE_TYPE}/GET_RISER_CLAMPS`;
export const GET_RISER_CLAMPS_SUCCESS = `${STORE_TYPE}/GET_RISER_CLAMPS_SUCCESS`;
export const GET_RISER_CLAMPS_FAILURE = `${STORE_TYPE}/GET_RISER_CLAMPS_FAILURE`;

export const GET_CATHODIC_PROTECTION = `${STORE_TYPE}/GET_CATHODIC_PROTECTION`;
export const GET_CATHODIC_PROTECTION_SUCCESS = `${STORE_TYPE}/GET_CATHODIC_PROTECTION_SUCCESS`;
export const GET_CATHODIC_PROTECTION_FAILURE = `${STORE_TYPE}/GET_CATHODIC_PROTECTION_FAILURE`;

export const GET_CP_CALIBRATION = `${STORE_TYPE}/GET_CP_CALIBRATION`;
export const GET_CP_CALIBRATION_SUCCESS = `${STORE_TYPE}/GET_CP_CALIBRATION_SUCCESS`;
export const GET_CP_CALIBRATION_FAILURE = `${STORE_TYPE}/GET_CP_CALIBRATION_FAILURE`;

export const GET_ISIMS = `${STORE_TYPE}/GET_ISIMS`;
export const GET_ISIMS_SUCCESS = `${STORE_TYPE}/GET_ISIMS_SUCCESS`;
export const GET_ISIMS_FAILURE = `${STORE_TYPE}/GET_ISIMS_FAILURE`;

export const GET_PLATFORM_PHOTOS_LIST = `${STORE_TYPE}/GET_PLATFORM_PHOTOS_LIST`;
export const GET_PLATFORM_PHOTOS_LIST_SUCCESS = `${STORE_TYPE}/GET_PLATFORM_PHOTOS_LIST_SUCCESS`;
export const GET_PLATFORM_PHOTOS_LIST_FAILURE = `${STORE_TYPE}/GET_PLATFORM_PHOTOS_LIST_FAILURE`;

export const GET_COMPONENT = `${STORE_TYPE}/GET_COMPONENT`;
export const GET_COMPONENT_SUCCESS = `${STORE_TYPE}/GET_COMPONENT_SUCCESS`;
export const GET_COMPONENT_FAILURE = `${STORE_TYPE}/GET_COMPONENT_FAILURE`;
export const CLEAR_COMPONENT = `${STORE_TYPE}/CLEAR_COMPONENT`;

export const EDIT_COMPONENT = `${STORE_TYPE}/EDIT_COMPONENT`;
export const EDIT_COMPONENT_SUCCESS = `${STORE_TYPE}/EDIT_COMPONENT_SUCCESS`;
export const EDIT_COMPONENT_FAILURE = `${STORE_TYPE}/EDIT_COMPONENT_FAILURE`;

export const REMOVE_COMPONENT = `${STORE_TYPE}/REMOVE_COMPONENT`;
export const REMOVE_COMPONENT_SUCCESS = `${STORE_TYPE}/REMOVE_COMPONENT_SUCCESS`;
export const REMOVE_COMPONENT_FAILURE = `${STORE_TYPE}/REMOVE_COMPONENT_FAILURE`;

export const GET_CONDUCTOR = `${STORE_TYPE}/GET_CONDUCTOR`;
export const GET_CONDUCTOR_SUCCESS = `${STORE_TYPE}/GET_CONDUCTOR_SUCCESS`;
export const GET_CONDUCTOR_FAILURE = `${STORE_TYPE}/GET_CONDUCTOR_FAILURE`;

export const EDIT_CONDUCTOR = `${STORE_TYPE}/EDIT_CONDUCTOR`;
export const EDIT_CONDUCTOR_SUCCESS = `${STORE_TYPE}/EDIT_CONDUCTOR_SUCCESS`;
export const EDIT_CONDUCTOR_FAILURE = `${STORE_TYPE}/EDIT_CONDUCTOR_FAILURE`;

export const REMOVE_CONDUCTOR = `${STORE_TYPE}/REMOVE_CONDUCTOR`;
export const REMOVE_CONDUCTOR_SUCCESS = `${STORE_TYPE}/REMOVE_CONDUCTOR_SUCCESS`;
export const REMOVE_CONDUCTOR_FAILURE = `${STORE_TYPE}/REMOVE_CONDUCTOR_FAILURE`;

export const GET_RISER = `${STORE_TYPE}/GET_RISER`;
export const GET_RISER_SUCCESS = `${STORE_TYPE}/GET_RISER_SUCCESS`;
export const GET_RISER_FAILURE = `${STORE_TYPE}/GET_RISER_FAILURE`;

export const EDIT_RISER = `${STORE_TYPE}/EDIT_RISER`;
export const EDIT_RISER_SUCCESS = `${STORE_TYPE}/EDIT_RISER_SUCCESS`;
export const EDIT_RISER_FAILURE = `${STORE_TYPE}/EDIT_RISER_FAILURE`;

export const REMOVE_RISER = `${STORE_TYPE}/REMOVE_RISER`;
export const REMOVE_RISER_SUCCESS = `${STORE_TYPE}/REMOVE_RISER_SUCCESS`;
export const REMOVE_RISER_FAILURE = `${STORE_TYPE}/REMOVE_RISER_FAILURE`;

export const GET_RISER_CLAMP = `${STORE_TYPE}/GET_RISER_CLAMP`;
export const GET_RISER_CLAMP_SUCCESS = `${STORE_TYPE}/GET_RISER_CLAMP_SUCCESS`;
export const GET_RISER_CLAMP_FAILURE = `${STORE_TYPE}/GET_RISER_CLAMP_FAILURE`;

export const EDIT_RISER_CLAMP = `${STORE_TYPE}/EDIT_RISER_CLAMP`;
export const EDIT_RISER_CLAMP_SUCCESS = `${STORE_TYPE}/EDIT_RISER_CLAMP_SUCCESS`;
export const EDIT_RISER_CLAMP_FAILURE = `${STORE_TYPE}/EDIT_RISER_CLAMP_FAILURE`;

export const REMOVE_RISER_CLAMP = `${STORE_TYPE}/REMOVE_RISER_CLAMP`;
export const REMOVE_RISER_CLAMP_SUCCESS = `${STORE_TYPE}/REMOVE_RISER_CLAMP_SUCCESS`;
export const REMOVE_RISER_CLAMP_FAILURE = `${STORE_TYPE}/REMOVE_RISER_CLAMP_FAILURE`;

export const GET_CP_ITEM = `${STORE_TYPE}/GET_CP_ITEM`;
export const GET_CP_ITEM_SUCCESS = `${STORE_TYPE}/GET_CP_ITEM_SUCCESS`;
export const GET_CP_ITEM_FAILURE = `${STORE_TYPE}/GET_CP_ITEM_FAILURE`;

export const EDIT_CP_ITEM = `${STORE_TYPE}/EDIT_CP_ITEM`;
export const EDIT_CP_ITEM_SUCCESS = `${STORE_TYPE}/EDIT_CP_ITEM_SUCCESS`;
export const EDIT_CP_ITEM_FAILURE = `${STORE_TYPE}/EDIT_CP_ITEM_FAILURE`;

export const REMOVE_CP_ITEM = `${STORE_TYPE}/REMOVE_CP_ITEM`;
export const REMOVE_CP_ITEM_SUCCESS = `${STORE_TYPE}/REMOVE_CP_ITEM_SUCCESS`;
export const REMOVE_CP_ITEM_FAILURE = `${STORE_TYPE}/REMOVE_CP_ITEM_FAILURE`;

export const GET_CPC_ITEM = `${STORE_TYPE}/GET_CPC_ITEM`;
export const GET_CPC_ITEM_SUCCESS = `${STORE_TYPE}/GET_CPC_ITEM_SUCCESS`;
export const GET_CPC_ITEM_FAILURE = `${STORE_TYPE}/GET_CPC_ITEM_FAILURE`;

export const EDIT_CPC_ITEM = `${STORE_TYPE}/EDIT_CPC_ITEM`;
export const EDIT_CPC_ITEM_SUCCESS = `${STORE_TYPE}/EDIT_CPC_ITEM_SUCCESS`;
export const EDIT_CPC_ITEM_FAILURE = `${STORE_TYPE}/EDIT_CPC_ITEM_FAILURE`;

export const REMOVE_CPC_ITEM = `${STORE_TYPE}/REMOVE_CPC_ITEM`;
export const REMOVE_CPC_ITEM_SUCCESS = `${STORE_TYPE}/REMOVE_CPC_ITEM_SUCCESS`;
export const REMOVE_CPC_ITEM_FAILURE = `${STORE_TYPE}/REMOVE_CPC_ITEM_FAILURE`;

export const GET_ISIMS_ITEM = `${STORE_TYPE}/GET_ISIMS_ITEM`;
export const GET_ISIMS_ITEM_SUCCESS = `${STORE_TYPE}/GET_ISIMS_ITEM_SUCCESS`;
export const GET_ISIMS_ITEM_FAILURE = `${STORE_TYPE}/GET_ISIMS_ITEM_FAILURE`;

export const EDIT_ISIMS_ITEM = `${STORE_TYPE}/EDIT_ISIMS_ITEM`;
export const EDIT_ISIMS_ITEM_SUCCESS = `${STORE_TYPE}/EDIT_ISIMS_ITEM_SUCCESS`;
export const EDIT_ISIMS_ITEM_FAILURE = `${STORE_TYPE}/EDIT_ISIMS_ITEM_FAILURE`;

export const REMOVE_ISIMS_ITEM = `${STORE_TYPE}/REMOVE_ISIMS_ITEM`;
export const REMOVE_ISIMS_ITEM_SUCCESS = `${STORE_TYPE}/REMOVE_ISIMS_ITEM_SUCCESS`;
export const REMOVE_ISIMS_ITEM_FAILURE = `${STORE_TYPE}/REMOVE_ISIMS_ITEM_FAILURE`;

export const GET_PLATFORM_PHOTOS = `${STORE_TYPE}/GET_PLATFORM_PHOTOS`;
export const GET_PLATFORM_PHOTOS_SUCCESS = `${STORE_TYPE}/GET_PLATFORM_PHOTOS_SUCCESS`;
export const GET_PLATFORM_PHOTOS_FAILURE = `${STORE_TYPE}/GET_PLATFORM_PHOTOS_FAILURE`;
export const CLEAR_PLATFORM_PHOTOS = `${STORE_TYPE}/CLEAR_PLATFORM_PHOTOS`;

export const EDIT_PLATFORM_PHOTOS = `${STORE_TYPE}/EDIT_PLATFORM_PHOTOS`;
export const EDIT_PLATFORM_PHOTOS_SUCCESS = `${STORE_TYPE}/EDIT_PLATFORM_PHOTOS_SUCCESS`;
export const EDIT_PLATFORM_PHOTOS_FAILURE = `${STORE_TYPE}/EDIT_PLATFORM_PHOTOS_FAILURE`;

export const REMOVE_PLATFORM_PHOTOS = `${STORE_TYPE}/REMOVE_PLATFORM_PHOTOS`;
export const REMOVE_PLATFORM_PHOTOS_SUCCESS = `${STORE_TYPE}/REMOVE_PLATFORM_PHOTOS_SUCCESS`;
export const REMOVE_PLATFORM_PHOTOS_FAILURE = `${STORE_TYPE}/REMOVE_PLATFORM_PHOTOS_FAILURE`;

export const GET_WELLHEADS = `${STORE_TYPE}/GET_WELLHEADS`;
export const GET_WELLHEADS_SUCCESS = `${STORE_TYPE}/GET_WELLHEADS_SUCCESS`;
export const GET_WELLHEADS_FAILURE = `${STORE_TYPE}/GET_WELLHEADS_FAILURE`;

export const GET_WELLHEAD = `${STORE_TYPE}/GET_WELLHEAD`;
export const GET_WELLHEAD_SUCCESS = `${STORE_TYPE}/GET_WELLHEAD_SUCCESS`;
export const GET_WELLHEAD_FAILURE = `${STORE_TYPE}/GET_WELLHEAD_FAILURE`;

export const EDIT_WELLHEAD = `${STORE_TYPE}/EDIT_WELLHEAD`;
export const EDIT_WELLHEAD_SUCCESS = `${STORE_TYPE}/EDIT_WELLHEAD_SUCCESS`;
export const EDIT_WELLHEAD_FAILURE = `${STORE_TYPE}/EDIT_WELLHEAD_FAILURE`;

export const REMOVE_WELLHEAD = `${STORE_TYPE}/REMOVE_WELLHEAD`;
export const REMOVE_WELLHEAD_SUCCESS = `${STORE_TYPE}/REMOVE_WELLHEAD_SUCCESS`;
export const REMOVE_WELLHEAD_FAILURE = `${STORE_TYPE}/REMOVE_WELLHEAD_FAILURE`;