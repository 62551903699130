// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_loginForm__1k-wZ {\n  width: 348px;\n  height: 400px;\n  margin-top: 265px;\n  margin-left: 60px;\n}\n\n.styles_title__1F3qT {\n  font-family: RobotoCondensed-Bold;\n  font-size: 36px;\n  line-height: 42px;\n  color: #3e3e3e;\n  margin-bottom: 22px;\n}\n\n.styles_link__3hmZI {\n  cursor: pointer;\n  font-family: Roboto;\n  font-size: 14px;\n  line-height: 16px;\n  text-align: center;\n  letter-spacing: 0.0357143em;\n  color: #328757;\n}\n\n.styles_error__BBo9P {\n  font-family: Roboto;\n  font-size: 12px;\n  line-height: 14px;\n  text-align: center;\n  letter-spacing: 0.0357143em;\n  color: #db4437;\n  margin-bottom: 12px;\n}\n\n.styles_submitButton__ivrmY {\n  margin: 16px 0 20px 0;\n}", "",{"version":3,"sources":["webpack://src/app/containers/Login/components/LoginForm/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,iBAAA;EACA,iBAAA;AACF;;AAEA;EACE,iCAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,2BAAA;EACA,cAAA;AACF;;AAEA;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,2BAAA;EACA,cAAA;EACA,mBAAA;AACF;;AAEA;EACE,qBAAA;AACF","sourcesContent":[".loginForm {\n  width: 348px;\n  height: 400px;\n  margin-top: 265px;\n  margin-left: 60px;\n}\n\n.title {\n  font-family: RobotoCondensed-Bold;\n  font-size: 36px;\n  line-height: 42px;\n  color: #3e3e3e;\n  margin-bottom: 22px;\n}\n\n.link {\n  cursor: pointer;\n  font-family: Roboto;\n  font-size: 14px;\n  line-height: 16px;\n  text-align: center;\n  letter-spacing: 0.0357143em;\n  color: #328757;\n}\n\n.error {\n  font-family: Roboto;\n  font-size: 12px;\n  line-height: 14px;\n  text-align: center;\n  letter-spacing: 0.0357143em;\n  color: #db4437;\n  margin-bottom: 12px;\n}\n\n.submitButton {\n  margin: 16px 0 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginForm": "styles_loginForm__1k-wZ",
	"title": "styles_title__1F3qT",
	"link": "styles_link__3hmZI",
	"error": "styles_error__BBo9P",
	"submitButton": "styles_submitButton__ivrmY"
};
export default ___CSS_LOADER_EXPORT___;
