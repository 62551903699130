import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import '../../../assets/styles/commonSettings.scss';

import AppContainer from 'app/components/AppContainer';
import AppRouter from 'app/components/AppRouter';
import * as React from 'react';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import Preloader from 'shared/Preloader';

import Login from '../Login';
import { actions } from './store/duck';
import { isLoginSelector } from './store/selectors';

interface IProps {
  isLogin: boolean;
  fetchMe: () => void;
}

const mapStateToProps = state => {
  return {
    isLogin: isLoginSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchMe: () => dispatch(actions.fetchMe())
  };
};

class App extends React.Component<IProps> {
  componentDidMount() {
    const { fetchMe } = this.props;
    fetchMe();
  }
  render() {
    const { isLogin } = this.props;
    return (
      <React.Fragment>
        {isLogin == null ? (
          <Preloader />
        ) : isLogin ? (
          <AppContainer>
            <AppRouter />
          </AppContainer>
        ) : (
          <Login />
        )}

        <ReduxToastr
          progressBar
          timeOut={3000}
          preventDuplicates
          newestOnTop={false}
          transitionIn="fadeIn"
          position="bottom-left"
          transitionOut="fadeOut"
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
