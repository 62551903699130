import { push } from 'connected-react-router';
//import { actions as actionsApp } from 'app/containers/App/store/duck';
import { api } from 'core/config/api';
import { toastr } from 'react-redux-toastr';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { catchError } from 'utils/sagaUtils';

import * as constants from './constants';
import { actions } from './duck';
import * as selectors from './selectors';

//customers
export function* fetchCustomersWorker(action) {
  const { sortBy, search, limit, offset, is_archived } = action.payload;

  const {
    search: prevSearch,
    sortBy: prevSortBy,
    limit: prevLimit,
    offset: prevOffet
  } = yield select(selectors.listCustomersSelector);

  let queryParams = {
    is_archived,
    orderBy: sortBy ?? prevSortBy,
    search: search ?? prevSearch,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0
  };

  try {
    const fetchGetCustomers = () =>
      api
        .get('/customers/', {
          params: { ...queryParams }
        })
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchGetCustomers);

    yield put(
      actions.getCustomersSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        sortBy: queryParams.orderBy,
        search: queryParams.search
      })
    );
  } catch (error) {
    yield put(actions.getCustomersFailure(error));
  }
}

function* fetchCustomerWorker(action) {
  const {
    payload: { id }
  } = action;

  try {
    const fetchCustomer = () =>
      api
        .get(`/customers/${id}/`)
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchCustomer);

    yield put(actions.getCustomerSuccess(result));
  } catch (error) {
    yield put(actions.getCustomerFailure(error));
  }
}

//change customer
export function* fetchChangeCustomerWorker(action) {
  const {
    payload: { url, id, logo, ...other },
    meta: { resolve, reject }
  } = action;

  try {
    //add  logo
    if (logo) {
      let formData = new FormData();
      if (typeof logo == 'object') {
        //if new file
        formData.append('file', logo);

        //get uploadLink
        const fetchGetLogo = () =>
          api
            .get(`/customers/${id}/logo/`)
            .then(response => response.data)
            .catch(catchError);

        const resultGetLogo = yield call(fetchGetLogo);

        const uploadLink = resultGetLogo.upload_link;

        const fetchUploadLogo = () =>
          api
            .post(uploadLink, formData)
            .then(response => response.data)
            .catch(catchError);

        const result = yield call(fetchUploadLogo);
        yield put(actions.uploadLogoSuccess(result));
      }
    } else if (logo === null) {
      const fetchDeleteLogo = () =>
        api
          .delete(`/customers/${id}/logo/`)
          .then(response => response.data)
          .catch(catchError);

      const result = yield call(fetchDeleteLogo);
      yield put(actions.deleteLogoSuccess(result));
    }

    //edit info
    const fetchChangeCustomer = () =>
      api
        .patch(`/customers/${id}/`, {
          ...other
        })
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchChangeCustomer);

    yield put(actions.changeCustomerSuccess(result));
    yield call(resolve);

    toastr.success('', 'Profile data has been changed');

    yield put(actions.getCustomer({ id }));
    yield put(actions.getCustomers({}));

    yield put(push(url));
  } catch (error) {
    yield put(actions.changeCustomerFailure(error));
    yield call(reject, error);
  }
}

//logo
export function* fetchGetLogoWorker(action) {
  const { payload: id } = action;

  try {
    const fetchGetLogo = () =>
      api
        .get(`/customers/${id}/logo/`)
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchGetLogo);
    yield put(actions.getLogoSuccess(result));
  } catch (error) {
    yield put(actions.getLogoFailure(error));
  }
}

export function* fetchUploadLogoWorker(action) {
  const { id, uploadLink, formData } = action.payload;

  try {
    const fetchUploadLogo = () =>
      api
        .post(uploadLink, formData)
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchUploadLogo);

    yield put(actions.uploadLogoSuccess(result));

    yield put(actions.getCustomer({ id }));
  } catch (error) {
    yield put(actions.uploadLogoFailure(error));
  }
}

export function* fetchDeleteLogoWorker(action) {
  const { payload: id } = action;

  try {
    const fetchDeleteLogo = () =>
      api
        .delete(`/customers/${id}/logo/`)
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchDeleteLogo);
    yield put(actions.deleteLogoSuccess(result));
  } catch (error) {
    yield put(actions.deleteLogoFailure(error));
  }
}

//add customer
export function* fetchAddCustomerWorker(action) {
  const {
    formData,
    name,
    address,
    email,
    phone,
    contact_person,
    users,
    platforms
  } = action.payload;

  const customerId = yield select(selectors.customerIdSelector);

  let id, result, resultUploadLogo;

  //get id
  try {
    if (!customerId) {
      const fetchAddCustomer = () =>
        api
          .post(`/customers/`, {
            name,
            address,
            email,
            phone,
            contact_person,
            users,
            platforms
          })
          .then(response => response.data)
          .catch(catchError);

      result = yield call(fetchAddCustomer);
      yield put(actions.addCustomerId(result?.id));
    }

    // if was logo's error, but user already exists
    id = customerId ? customerId : result?.id || null;

    //get uploadLink
    if (id) {
      const fetchGetLogo = () =>
        api
          .get(`/customers/${id}/logo/`)
          .then(response => response.data)
          .catch(catchError);

      const resultGetLogo = yield call(fetchGetLogo);

      //add  logo
      if (formData) {
        const uploadLink = resultGetLogo.upload_link;

        const fetchUploadLogo = () =>
          api
            .post(uploadLink, formData)
            .then(response => response.data)
            .catch(catchError);

        resultUploadLogo = yield call(fetchUploadLogo);
        yield put(actions.clearCustomerId());
      }
    }
    yield put(actions.addCustomerSuccess(result, resultUploadLogo));

    yield put(push('/customers/active'));

    yield put(actions.getCustomers({ is_archived: false }));
  } catch (error) {
    yield put(actions.addCustomerFailure(error));
  }
}

export default function* customersSaga() {
  yield takeLatest(constants.GET_CUSTOMERS, fetchCustomersWorker);
  yield takeLatest(constants.GET_CUSTOMER, fetchCustomerWorker);
  yield takeLatest(constants.CHANGE_CUSTOMER, fetchChangeCustomerWorker);
  yield takeLatest(constants.GET_LOGO, fetchGetLogoWorker);
  yield takeLatest(constants.UPLOAD_LOGO, fetchUploadLogoWorker);
  yield takeLatest(constants.DELETE_LOGO, fetchDeleteLogoWorker);
  yield takeLatest(constants.ADD_CUSTOMER, fetchAddCustomerWorker);
}
