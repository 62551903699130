import { api } from 'core/config/api';
import { identity, noop } from 'lodash';
import { createAction } from 'redux-actions';
import { call } from 'redux-saga/effects';

export function catchError(error) {
  console.log(error);

  if (error.message === 'Network Error') {
    const errors = {
      detail: 'No internet connection'
    };
    throw errors;
  }

  switch (error.response.status) {
    case 400:
    case 401:
    case 403:
    case 429:
      const data = error.response.data;
      if (Array.isArray(data))
        // eslint-disable-next-line no-throw-literal
        throw {
          detail: data.join(', ')
        };
      const { extra_data, ...errors } = data;

      throw Object.assign(
        {},
        ...Object.entries({
          ...extra_data,
          ...errors
        }).map(([key, value]) => ({
          [key === 'non_field_errors' ? 'detail' : key]: Array.isArray(value)
            ? value.join(', ')
            : value
        }))
      );
  }
  const errors = {
    detail: 'Server error'
  };

  throw errors;
}

export function* uploadFile(url, data, file): any {
  const getUploadLink = () =>
    api
      .post(url, data)
      .then(response => response.data)
      .catch(catchError);

  const { upload_link } = yield call(getUploadLink);

  const formData = new FormData();
  formData.append('file', file);

  const fetchUploadAvatar = () =>
    api
      .post(upload_link, formData)
      .then(response => response.data)
      .catch(catchError);

  yield call(fetchUploadAvatar);
}

const payloadCreator = identity;
const metaCreator = (_, resolve = noop, reject = noop) => ({ resolve, reject });

export const promiseAction = type =>
  createAction(type, payloadCreator, metaCreator);

export const bindActionToPromise = (dispatch, actionCreator) => payload => {
  return new Promise((resolve, reject) =>
    dispatch(actionCreator(payload, resolve, reject))
  );
};
