// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_recoveryForm__2-PYd {\n  height: 400px;\n  margin-top: 264px;\n  margin-left: 60px;\n}\n\n.styles_back__1LN24 {\n  display: flex;\n  margin-bottom: 22px;\n}\n\n.styles_backImg__2XxsZ {\n  width: 22px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.styles_backImg__2XxsZ img {\n  width: 9px;\n}\n\n.styles_title__7GVvf {\n  font-family: RobotoCondensed-Bold;\n  font-size: 36px;\n  line-height: 42px;\n  color: #3e3e3e;\n  margin-left: 20px;\n}", "",{"version":3,"sources":["webpack://src/app/containers/Login/containers/Recovery/styles.module.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,iBAAA;EACA,iBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;AAAF;;AAGA;EACE,WAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF;AAEE;EACE,UAAA;AAAJ;;AAIA;EACE,iCAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;AADF","sourcesContent":[".recoveryForm {\n  // width: 348px;\n  height: 400px;\n  margin-top: 264px;\n  margin-left: 60px;\n}\n\n.back {\n  display: flex;\n  margin-bottom: 22px;\n}\n\n.backImg {\n  width: 22px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  img {\n    width: 9px;\n  }\n}\n\n.title {\n  font-family: RobotoCondensed-Bold;\n  font-size: 36px;\n  line-height: 42px;\n  color: #3e3e3e;\n  margin-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recoveryForm": "styles_recoveryForm__2-PYd",
	"back": "styles_back__1LN24",
	"backImg": "styles_backImg__2XxsZ",
	"title": "styles_title__7GVvf"
};
export default ___CSS_LOADER_EXPORT___;
