import { actions } from 'app/containers/App/store/duck';
import { IUser } from 'app/containers/App/store/interface';
import { userInfoSelector } from 'app/containers/App/store/selectors';
import * as React from 'react';
import { connect } from 'react-redux';

import UserView from '../UserView';
import styles from './styles.module.scss';

import logo from './assets/img/logo.svg';

interface IProps {
  userInfo: IUser;
  logOut: () => void;
}

const mapStateToProps = state => {
  return {
    userInfo: userInfoSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actions.logOut())
  };
};

class Header extends React.Component<IProps> {
  render() {
    const { logOut, userInfo } = this.props;

    return (
      <header className={styles.header}>
        <img src={logo} alt="Gir Logo" className={styles.logo} />
        <UserView userInfo={userInfo} logOut={logOut}></UserView>
      </header>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
