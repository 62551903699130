import { actions } from 'campaigns/store/duck';
import * as React from 'react';
import { connect } from 'react-redux';

interface IProps {
  addCampaign: (value: any) => void;
  getCampaigns: (value: any) => void;
  title: string;
  start_date: string;
  end_date: string;
}

const mapDispatchToProps = dispatch => {
  return {
    addCampaign: value => dispatch(actions.addCampaign(value)),
    getCampaigns: value => dispatch(actions.getCampaigns(value))
  };
};

class CampaignUndo extends React.Component<IProps> {
  render() {
    const {
      addCampaign,
      getCampaigns,
      title,
      start_date,
      end_date
    } = this.props;
    return (
      <span
        onClick={() => {
          addCampaign({ title, start_date, end_date });
          getCampaigns({});
        }}
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
          fontWeight: 'bold',
          color: '#3E3E3E'
        }}
      >
        UNDO
      </span>
    );
  }
}

export default connect(null, mapDispatchToProps)(CampaignUndo);
