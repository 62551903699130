import { api } from 'core/config/api';
import { toastr } from 'react-redux-toastr';
import { call, put, takeLatest } from 'redux-saga/effects';
import { catchError } from 'utils/sagaUtils';

import * as constants from './constants';
import { actions } from './duck';

export function* fetchLogInWorker(action) {
  const {
    payload: { login, password }
  } = action;

  try {
    const fetchLogIn = () =>
      api
        .post('/auth/login/', {
          email: login,
          password
        })
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchLogIn);
    yield put(actions.logInSuccess(result));
  } catch (error) {
    yield put(actions.logInFailure(error));
  }
}

export function* fetchMeWorker(action) {
  try {
    const fetchMe = () =>
      api
        .get('/users/account/me/')
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchMe);
    yield put(actions.fetchMeSuccess(result));
  } catch (error) {
    yield put(actions.fetchMeFailure(error));
  }
}

export function* fetchGetCodeRecoveryWorker(action) {
  const {
    payload: { email }
  } = action;

  try {
    const fetchShowRecovery = () =>
      api
        .post('/users/account/recover_password/request/', {
          email: email
        })
        .then(response => response.data)
        .catch(catchError);
    const result = yield call(fetchShowRecovery);
    yield put(actions.getRecoveryCodeSuccess(result));
  } catch (error) {
    yield put(actions.getRecoveryCodeFailure(error));
  }
}

export function* fetchSendCodeRecoveryWorker(action) {
  const {
    payload: { code, email }
  } = action;

  try {
    const fetchSendCodeRecovery = () =>
      api
        .post('/users/account/recover_password/confirm_code/', {
          code,
          email
        })
        .then(response => response.data)
        .catch(catchError);
    const result = yield call(fetchSendCodeRecovery);
    yield put(actions.sendRecoveryCodeSuccess(result));
  } catch (error) {
    yield put(actions.sendRecoveryCodeFailure(error));
  }
}

export function* fetchFinishRecoveryWorker(action) {
  const {
    payload: { recovery_token, new_password_1, new_password_2 }
  } = action;

  try {
    const fetchFinishRecovery = () =>
      api
        .post('/users/account/recover_password/finish/', {
          recovery_token,
          new_password_1,
          new_password_2
        })
        .then(response => response.data)
        .catch(catchError);
    const result = yield call(fetchFinishRecovery);
    yield put(actions.finishRecoverySuccess(result));
    toastr.success('', 'Password changed successfully');
  } catch (error) {
    yield put(actions.finishRecoveryFailure(error));
  }
}

export default function* appSaga() {
  yield takeLatest(constants.LOG_IN, fetchLogInWorker);
  yield takeLatest(constants.GET_RECOVERY_CODE, fetchGetCodeRecoveryWorker);
  yield takeLatest(constants.SEND_RECOVERY_CODE, fetchSendCodeRecoveryWorker);
  yield takeLatest(constants.FINISH_RECOVERY, fetchFinishRecoveryWorker);
  yield takeLatest(constants.FETCH_ME, fetchMeWorker);
}
