import { withFormik } from 'formik';
import * as React from 'react';
import { Button } from 'shared/Button';
import { TextBox } from 'shared/Form';
import Preloader from 'shared/Preloader';

import styles from './styles.module.scss';

interface IProps {
  failure?: any;
  isLoading: boolean;
  recoveryStep: number;
  getRecoveryCode: (value: any) => void;
  values: {
    email: string;
  };
  errors: FormErrors;
  handleChange: () => void;
  handleSubmit: () => void;
  handleBlur: () => void;
  touched?: any;
}

interface FormValues {
  email: string;
}

interface FormErrors {
  email?: string;
}

const formikEnhancer = withFormik<any, any>({
  enableReinitialize: true,
  validate: (values: FormValues) => {
    let errors: FormErrors = {};
    const { email } = values;

    if (!email) {
      errors.email = 'Email is empty';
    }

    return errors;
  },
  mapPropsToValues: () => {
    return {
      email: ''
    };
  },
  handleSubmit: (values, props: any) => {
    const {
      props: { getRecoveryCode }
    } = props;

    getRecoveryCode(values);
  },
  displayName: 'RecoveryStep1'
});

class RecoveryStep1 extends React.Component<IProps> {
  render() {
    const {
      values,
      errors,
      failure,
      isLoading,
      handleBlur,
      handleSubmit,
      handleChange
    } = this.props;
    return (
      <form onSubmit={handleSubmit} className={styles.recoveryForm}>
        <p className={styles.text}>
          We will send a code to your Email account.
        </p>
        <div>
          <TextBox
            type="email"
            id="email"
            placeholder="Email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors?.email ?? failure?.email}
          />
        </div>

        {failure && failure.detail && (
          <p className={styles.error}>{failure.detail}</p>
        )}
        <div className={styles.submitButton}>
          <Button disabled={isLoading} fullWidth variant="solid" type="submit">
            {isLoading ? <Preloader size="button" /> : 'SEND'}
          </Button>
        </div>
      </form>
    );
  }
}
export default formikEnhancer(RecoveryStep1);
