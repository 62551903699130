// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Group_group__1BUD8 {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  background: #f7f7f7;\n  padding: 14px;\n  flex-shrink: 0;\n  border-radius: 4px;\n  position: relative;\n}\n.Group_group_column__eXU9H {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1;\n  padding: 0 10px;\n  width: 100%;\n}\n.Group_group_row__3--5f {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  flex-shrink: 0;\n}\n.Group_group_row__3--5f > * {\n  margin-left: 10px;\n  margin-right: 10px;\n}\n.Group_group_row__3--5f > *:first-child {\n  margin-left: 0;\n}\n.Group_group_row__3--5f > *:last-child {\n  margin-right: 0;\n}", "",{"version":3,"sources":["webpack://src/shared/Form/Group/Group.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;EACA,aAAA;EACA,cAAA;EAEA,kBAAA;EAEA,kBAAA;AADF;AAGE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EAEA,eAAA;EAEA,WAAA;AAHJ;AAME;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,cAAA;AAJJ;AAMI;EACE,iBAAA;EACA,kBAAA;AAJN;AAMM;EACE,cAAA;AAJR;AAOM;EACE,eAAA;AALR","sourcesContent":[".group {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  background: #f7f7f7;\n  padding: 14px;\n  flex-shrink: 0;\n\n  border-radius: 4px;\n\n  position: relative;\n\n  &_column {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n\n    padding: 0 10px;\n\n    width: 100%;\n  }\n\n  &_row {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    flex-shrink: 0;\n\n    & > * {\n      margin-left: 10px;\n      margin-right: 10px;\n\n      &:first-child {\n        margin-left: 0;\n      }\n\n      &:last-child {\n        margin-right: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "Group_group__1BUD8",
	"group_column": "Group_group_column__eXU9H",
	"group_row": "Group_group_row__3--5f"
};
export default ___CSS_LOADER_EXPORT___;
