import cx from 'classnames';
import * as React from 'react';

import styles from './Label.module.scss';

export interface LabelProps extends React.HTMLProps<HTMLLabelElement> {
  className: string;
}

export const Label: React.FC<Partial<LabelProps>> = ({
  className,
  ...other
}) => <label className={cx(styles.field_label, className)} {...other}></label>;

export default Label;
