// Connected React Router
import { routerMiddleware } from 'connected-react-router';
import { createReducers } from 'core/reducers/root';
import { applyMiddleware, compose, createStore } from 'redux';
// import serviceWorkerInit from 'utils/serviceWorker';
import { createLogger } from 'redux-logger';
import createSagaMiddleware, { END } from 'redux-saga';

const createHistory = require('history').createBrowserHistory;
export const history = createHistory();
// Connected React Router

const logger = createLogger({
  collapsed: true
});

const sagaMiddleware = createSagaMiddleware();

export function configureStore() {
  const store =
    process.env.NODE_ENV === 'development'
      ? createStore(
          createReducers(history),
          compose(
            applyMiddleware(routerMiddleware(history), sagaMiddleware, logger),
            (window as any)['__REDUX_DEVTOOLS_EXTENSION__']
              ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
              : (f: Function) => f
          )
        )
      : createStore(
          createReducers(history),
          compose(applyMiddleware(routerMiddleware(history), sagaMiddleware))
        );

  return {
    ...store,
    runSaga: sagaMiddleware.run,
    close: () => store.dispatch(END)
  };
}
