// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_error__1-Izr {\n  font-size: 12px;\n  line-height: 16px;\n  color: #f44336;\n  margin-bottom: 16px;\n  text-align: center;\n}\n\n.styles_errorBlock__3flAC {\n  display: flex;\n  justify-content: space-between;\n}\n\n.styles_errorText__3qL8Q {\n  font-family: Roboto;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 12px;\n  line-height: 14px;\n  /* identical to box height */\n  text-align: center;\n  color: #db4437;\n  margin: 12px;\n}\n\n.styles_errorImg__1ClJy {\n  width: 16px;\n  height: 18px;\n  margin: 12px;\n}", "",{"version":3,"sources":["webpack://src/shared/Form/Failure/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,4BAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,YAAA;AACF","sourcesContent":[".error {\n  font-size: 12px;\n  line-height: 16px;\n  color: #f44336;\n  margin-bottom: 16px;\n  text-align: center;\n}\n\n.errorBlock {\n  display: flex;\n  justify-content: space-between;\n}\n\n.errorText {\n  font-family: Roboto;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 12px;\n  line-height: 14px;\n  /* identical to box height */\n  text-align: center;\n  color: #db4437;\n  margin: 12px;\n}\n\n.errorImg {\n  width: 16px;\n  height: 18px;\n  margin: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "styles_error__1-Izr",
	"errorBlock": "styles_errorBlock__3flAC",
	"errorText": "styles_errorText__3qL8Q",
	"errorImg": "styles_errorImg__1ClJy"
};
export default ___CSS_LOADER_EXPORT___;
