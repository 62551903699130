import app from 'app/containers/App/store/duck';
import assignments from 'assignments/store/duck';
import campaigns from 'campaigns/store/duck';
import customers from 'customers/store/duck';
import directories from 'directories/store/duck';
import platforms from 'platforms/store/duck';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';
import survey from 'surveys/store/duck';
import users from 'users/store/duck';
import { connectRouter } from 'connected-react-router';

export const createReducers = history =>
  combineReducers({
    app,
    survey,
    users,
    customers,
    platforms,
    campaigns,
    assignments,
    directories,
    toastr: toastrReducer,
    router: connectRouter(history)
  });
