import * as React from 'react';
import Modal from 'react-modal';
import cx from 'classnames';
import styles from './styles.module.scss';

interface IProps {
  isOpen: boolean;
  variant?: string;
  togglePopup: (value: boolean) => void;
  children: any
}

class ModalPopUp extends React.Component<IProps> {
  render() {
    const { isOpen, togglePopup, variant } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        className={cx({
          [styles.modalContent]: true,
          [styles['modalContent_' + variant]]: variant
        })}
        overlayClassName={styles.modalOverlay}
        ariaHideApp={false}
        onRequestClose={() => togglePopup(false)}
      >
        {this.props.children}
      </Modal>
    );
  }
}

export default ModalPopUp;
