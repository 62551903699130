import * as React from 'react';
import Select, { SelectProps } from 'shared/Form/Select/Select';
import { Merge } from 'utils/typeMerge';

export interface BooleanSelectProps
  extends Merge<
    SelectProps,
    {
      value: boolean;
    }
  > {}

const data = [
  { value: '', title: 'No selection' } as any,
  { value: true, title: 'Yes' } as any,
  { value: false, title: 'No' } as any
];

export const BooleanSelect: React.FC<Partial<BooleanSelectProps>> = ({
  value,
  onChange,
  ...other
}) => (
  <Select
    options={data}
    value={data.find(x => value === x.value)}
    getOptionLabel={x => x?.title}
    getOptionValue={x => x.value}
    onChange={x => onChange(x.value)}
    {...other}
  />
);

export default BooleanSelect;
