// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Form_form__110YQ {\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}", "",{"version":3,"sources":["webpack://src/shared/Form/Form.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AACF","sourcesContent":[".form {\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "Form_form__110YQ"
};
export default ___CSS_LOADER_EXPORT___;
