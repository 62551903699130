import AssignmentUndo from 'assignments/components/AssignmentUndo';
import { push } from 'connected-react-router';
import { api } from 'core/config/api';
import * as React from 'react';
import { toastr } from 'react-redux-toastr';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { catchError } from 'utils/sagaUtils';

import * as constants from './constants';
import { actions } from './duck';
import * as selectors from './selectors';

//Assignments
export function* fetchAssignmentsWorker(action) {
  const { search, sortBy, limit, offset } = action.payload;

  const {
    search: prevSearch,
    sortBy: prevSortBy,
    limit: prevLimit,
    offset: prevOffet
  } = yield select(selectors.assignmentsDataSelector);

  let queryParams = {
    search: search ?? prevSearch,
    orderBy: sortBy ?? prevSortBy,
    limit: limit ?? prevLimit ?? 25,
    offset: offset ?? prevOffet ?? 0
  };

  try {
    const fetchGetAssignments = () =>
      api
        .get('/campaigns/assignments/', {
          params: { ...queryParams }
        })
        .then(response => response.data)
        .catch(catchError);
    const result = yield call(fetchGetAssignments);

    yield put(
      actions.getAssignmentsSuccess({
        ...result,
        limit: queryParams.limit,
        offset: queryParams.offset,
        sortBy: queryParams.orderBy,
        search: queryParams.search
      })
    );
  } catch (error) {
    yield put(actions.getAssignmentsFailure(error));
  }
}

//Assignment
function* fetchAssignmentWorker(action) {
  const {
    payload: { id }
  } = action;

  try {
    const fetchAssignment = () =>
      api
        .get(`/campaigns/assignments/${id}/`)
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchAssignment);

    yield put(actions.getAssignmentSuccess(result));
  } catch (error) {
    yield put(actions.getAssignmentFailure(error));
  }
}

//add Assignment
export function* fetchAddAssignmentWorker(action) {
  const {
    payload: { ...values }
  } = action;

  try {
    const fetchAddAssignment = () =>
      api
        .post(`/campaigns/assignments/`, {
          ...values
        })
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchAddAssignment);

    yield put(actions.addAssignmentSuccess(result));

    toastr.success('', 'New assingment has been created');

    yield put(push(`/assignments`));

    yield put(actions.getAssignments({}));
  } catch (error) {
    yield put(actions.addAssignmentFailure(error));
  }
}

//edit Assignment
export function* fetchEditAssignmentWorker(action) {
  const {
    payload: { id, ...other }
  } = action;

  try {
    const fetchEditAssignment = () =>
      api
        .patch(`/campaigns/assignments/${id}/`, {
          ...other
        })
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchEditAssignment);

    yield put(actions.editAssignmentSuccess(result));

    toastr.success('', 'Data has been changed');

    yield put(push(`/assignments`));

    yield put(actions.getAssignments({}));
  } catch (error) {
    yield put(actions.editAssignmentFailure(error));
  }
}

//delete Assignment
export function* fetchDeleteAssignmentWorker(action) {
  const {
    payload: { id, user, inspection_campaign, platforms }
  } = action;

  try {
    const fetchDeleteAssignment = () =>
      api
        .delete(`/campaigns/assignments/${id}/`)
        .then(response => response.data)
        .catch(catchError);

    const result = yield call(fetchDeleteAssignment);

    yield put(actions.deleteAssignmentSuccess(result));

    const toastrOptions = {
      component: (
        <AssignmentUndo
          user={user}
          inspection_campaign={inspection_campaign}
          platforms={platforms}
        />
      )
    };

    toastr.success(
      '',
      'The Inspection assignment has been deleted.',
      toastrOptions
    );

    yield put(actions.getAssignments({}));
  } catch (error) {
    yield put(actions.deleteAssignmentFailure(error));
  }
}

export default function* assignmentsSaga() {
  yield takeLatest(constants.GET_ASSIGNMENTS, fetchAssignmentsWorker);
  yield takeLatest(constants.GET_ASSIGNMENT, fetchAssignmentWorker);
  yield takeLatest(constants.ADD_ASSIGNMENT, fetchAddAssignmentWorker);
  yield takeLatest(constants.EDIT_ASSIGNMENT, fetchEditAssignmentWorker);
  yield takeLatest(constants.DELETE_ASSIGNMENT, fetchDeleteAssignmentWorker);
}
