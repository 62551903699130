import { actions } from 'app/containers/App/store/duck';
import {
    failureFinishRecoverySelector, failureGetRecoveryCodeSelector, failureSendRecoveryCodeSelector,
    isLoadingRecoverySelector, mailRecoverySelector, recoveryStepSelector, tokenRecoverySelector
} from 'app/containers/App/store/selectors';
import * as React from 'react';
import { connect } from 'react-redux';

import RecoveryStep1 from './components/RecoveryStep1';
import RecoveryStep2 from './components/RecoveryStep2';
import RecoveryStep3 from './components/RecoveryStep3';
import styles from './styles.module.scss';

import back from './assets/img/back.svg';

interface IProps {
  isLoading: boolean;
  recoveryStep: number;
  mailRecovery: string;
  tokenRecovery: string;
  failureGetRecoveryCode: string;
  failureSendRecoveryCode: string;
  failureFinishRecovery: string;
  getRecoveryCode: (value: any) => void;
  sendRecoveryCode: (value: any) => void;
  finishRecovery: (value: any) => void;
  backToLogin: () => void;
}

const mapStateToProp = state => {
  return {
    isLoading: isLoadingRecoverySelector(state),
    recoveryStep: recoveryStepSelector(state),
    mailRecovery: mailRecoverySelector(state),
    tokenRecovery: tokenRecoverySelector(state),
    failureGetRecoveryCode: failureGetRecoveryCodeSelector(state),
    failureSendRecoveryCode: failureSendRecoveryCodeSelector(state),
    failureFinishRecovery: failureFinishRecoverySelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRecoveryCode: values => dispatch(actions.getRecoveryCode(values)),
    sendRecoveryCode: values => dispatch(actions.sendRecoveryCode(values)),
    finishRecovery: values => dispatch(actions.finishRecovery(values)),
    backToLogin: () => dispatch(actions.backToLogin())
  };
};

class Recovery extends React.Component<IProps> {
  backToLoginForm = () => {
    const { backToLogin } = this.props;
    backToLogin();
  };

  renderStep = () => {
    const {
      recoveryStep,
      isLoading,
      mailRecovery,
      getRecoveryCode,
      sendRecoveryCode,
      tokenRecovery,
      finishRecovery,
      failureGetRecoveryCode,
      failureSendRecoveryCode,
      failureFinishRecovery
    } = this.props;
    switch (recoveryStep) {
      case 1:
        return (
          <RecoveryStep1
            isLoading={isLoading}
            recoveryStep={recoveryStep}
            getRecoveryCode={getRecoveryCode}
            failure={failureGetRecoveryCode}
          />
        );
      case 2:
        return (
          <RecoveryStep2
            isLoading={isLoading}
            recoveryStep={recoveryStep}
            sendRecoveryCode={sendRecoveryCode}
            mailRecovery={mailRecovery}
            failure={failureSendRecoveryCode}
          />
        );
      case 3:
        return (
          <RecoveryStep3
            isLoading={isLoading}
            recoveryStep={recoveryStep}
            finishRecovery={finishRecovery}
            tokenRecovery={tokenRecovery}
            failure={failureFinishRecovery}
          />
        );
      default:
        break;
    }
  };

  render() {
    return (
      <div className={styles.recoveryForm}>
        <div className={styles.back}>
          <div className={styles.backImg}>
            <img src={back} alt="back" onClick={this.backToLoginForm} />
          </div>
          <h1 className={styles.title}>PASSWORD RECOVERY</h1>
        </div>
        {this.renderStep()}
      </div>
    );
  }
}
export default connect(mapStateToProp, mapDispatchToProps)(Recovery);
