// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UploadButton_container__3n_R5 {\n  width: 108px;\n  height: 108px;\n  min-height: 108px;\n  background-position: center;\n  background-repeat: no-repeat;\n  border: 1px solid #e7e7e7;\n  border-radius: 3px;\n  box-sizing: border-box;\n  display: flex;\n  position: relative;\n}\n\n.UploadButton_pic__2Z3PW {\n  width: 100%;\n  height: 100%;\n  margin-bottom: 20px;\n}\n\n.UploadButton_addPhoto__32GK8 {\n  width: 100%;\n  height: 100%;\n  width: 0.1px;\n  height: 0.1px;\n  opacity: 0;\n  overflow: hidden;\n  position: absolute;\n  z-index: -1;\n}\n\n.UploadButton_avatarLabel__3rhe8 {\n  display: block;\n  cursor: pointer;\n  width: 100%;\n  height: 100%;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://src/shared/Form/Photo/UploadButton.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,iBAAA;EACA,2BAAA;EACA,4BAAA;EACA,yBAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,UAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AACF","sourcesContent":[".container {\n  width: 108px;\n  height: 108px;\n  min-height: 108px;\n  background-position: center;\n  background-repeat: no-repeat;\n  border: 1px solid #e7e7e7;\n  border-radius: 3px;\n  box-sizing: border-box;\n  display: flex;\n  position: relative;\n}\n\n.pic {\n  width: 100%;\n  height: 100%;\n  margin-bottom: 20px;\n}\n\n.addPhoto {\n  width: 100%;\n  height: 100%;\n  width: 0.1px;\n  height: 0.1px;\n  opacity: 0;\n  overflow: hidden;\n  position: absolute;\n  z-index: -1;\n}\n\n.avatarLabel {\n  display: block;\n  cursor: pointer;\n  width: 100%;\n  height: 100%;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "UploadButton_container__3n_R5",
	"pic": "UploadButton_pic__2Z3PW",
	"addPhoto": "UploadButton_addPhoto__32GK8",
	"avatarLabel": "UploadButton_avatarLabel__3rhe8"
};
export default ___CSS_LOADER_EXPORT___;
