const STORE_TYPE = 'directories';

export const CLEAR_DIRECTORIES = `${STORE_TYPE}/CLEAR_DIRECTORIES`;

export const GET_TYPES = `${STORE_TYPE}/GET_TYPES`;
export const GET_TYPES_SUCCESS = `${STORE_TYPE}/GET_TYPES_SUCCESS`;
export const GET_TYPES_FAILURE = `${STORE_TYPE}/GET_TYPES_FAILURE`;

export const ADD_TYPE = `${STORE_TYPE}/ADD_TYPE`;
export const ADD_TYPE_SUCCESS = `${STORE_TYPE}/ADD_TYPE_SUCCESS`;
export const ADD_TYPE_FAILURE = `${STORE_TYPE}/ADD_TYPE_FAILURE`;

export const EDIT_TYPE = `${STORE_TYPE}/EDIT_TYPE`;
export const EDIT_TYPE_SUCCESS = `${STORE_TYPE}/EDIT_TYPE_SUCCESS`;
export const EDIT_TYPE_FAILURE = `${STORE_TYPE}/EDIT_TYPE_FAILURE`;

export const DELETE_TYPE = `${STORE_TYPE}/DELETE_TYPE`;
export const DELETE_TYPE_SUCCESS = `${STORE_TYPE}/DELETE_TYPE_SUCCESS`;
export const DELETE_TYPE_FAILURE = `${STORE_TYPE}/DELETE_TYPE_FAILURE`;
