import { withFormik } from 'formik';
import * as React from 'react';
import { Button } from 'shared/Button';
import { TextBox } from 'shared/Form';
import Preloader from 'shared/Preloader';

import styles from './styles.module.scss';

const close = require('assets/icons/close.svg');

interface IProps {
  isLoading: boolean;
  failure?: any;
  values: {
    code: string;
  };
  errors: FormErrors;
  handleChange: () => void;
  handleSubmit: () => void;
  handleBlur: () => void;
  touched?: any;
  backToEditProfile: () => void;
  sendPasswordCode: (value: any) => void;
}

interface FormValues {
  code: string;
}

interface FormErrors {
  code?: string;
}

const formikEnhancer = withFormik<any, any>({
  enableReinitialize: true,
  validate: (values: FormValues) => {
    let errors: FormErrors = {};
    const { code } = values;

    if (!code) {
      errors.code = 'Code is empty';
    } else if (code.length < 4) {
      errors.code = 'Code must consist of 4 symbols';
    }
    return errors;
  },
  mapPropsToValues: () => {
    return {
      code: ''
    };
  },
  handleSubmit: (values, props: any) => {
    const {
      props: { sendPasswordCode }
    } = props;

    sendPasswordCode(values);
  },
  displayName: 'ChangePasswordStep1'
});

class ChangePasswordStep1 extends React.Component<IProps> {
  handleClick = () => {
    const { backToEditProfile } = this.props;
    backToEditProfile();
  };

  render() {
    const {
      values,
      errors,
      failure,
      isLoading,
      handleBlur,
      handleSubmit,
      handleChange
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className={styles.back}>
          <img
            src={close}
            alt="back"
            className={styles.backImg}
            onClick={this.handleClick}
          />
          <h1 className={styles.title}>Changing password</h1>
        </div>
        <div className={styles.profile}>
          <div className={styles.field}>
            <p className={styles.text}>
              Type the code we sent to your email in the form below.
            </p>
            <div className={styles.subtext}>
              <TextBox
                id="code"
                name="code"
                placeholder="Code"
                maxLength={4}
                value={values.code}
                onBlur={handleBlur}
                onChange={handleChange}
                error={errors?.code ?? failure?.code}
              />
            </div>
          </div>
          {failure && failure.detail && (
            <p className={styles.error}>{failure.detail}</p>
          )}
          <div className={styles.submitButton}>
            <Button
              fullWidth
              type="submit"
              disabled={isLoading}
              variant="solid"
            >
              {isLoading ? <Preloader size="button" /> : 'SEND'}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

export default formikEnhancer(ChangePasswordStep1);
