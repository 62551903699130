import appSaga from 'app/containers/App/store/saga';
import assignmentsSaga from 'assignments/store/saga';
import campaignsSaga from 'campaigns/store/saga';
import customersSaga from 'customers/store/saga';
import directoriesSaga from 'directories/store/saga';
import platformsSaga from 'platforms/store/saga';
import { all, fork } from 'redux-saga/effects';
import surveySaga from 'surveys/store/saga';
import usersSaga from 'users/store/saga';

export default function*() {
  yield all([
    fork(appSaga),
    fork(surveySaga),
    fork(usersSaga),
    fork(customersSaga),
    fork(platformsSaga),
    fork(campaignsSaga),
    fork(assignmentsSaga),
    fork(directoriesSaga)
  ]);
}
