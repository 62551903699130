import cx from 'classnames';
import * as React from 'react';
import ModalPopUp from 'shared/ModalPopUp';
import styles from 'users/containers/UsersPopUp/styles.module.scss';

export interface IProps {}

class PhotoAlert extends React.Component<IProps> {
  state = {
    open: false,
    resolve: null
  };
  show = async () => {
    const result = await new Promise<boolean>(resolve => {
      this.setState({ open: true, resolve });
    });
    this.setState({ open: false });
    return result;
  };
  handleSubmit = () => {
    this.state.resolve(true);
  };
  handleClose = () => {
    this.state.resolve(false);
  };

  render() {
    return (
      <ModalPopUp isOpen={this.state.open} togglePopup={this.handleClose}>
        <div className={styles.modalContent}>
          <div className={styles.title}>EXIT</div>
          <div className={styles.subTitle}>
            Are you sure you want to exit without apply?
          </div>

          <div className={styles.buttonsBlock}>
            <div className={styles.cancelButton} onClick={this.handleClose}>
              CANCEL
            </div>
            <div
              className={cx(styles.submitButton)}
              onClick={this.handleSubmit}
            >
              OK
            </div>
          </div>
        </div>
      </ModalPopUp>
    );
  }
}

export default PhotoAlert;
