import cx from 'classnames';
import * as React from 'react';

import { TextBox, TextBoxProps } from '../TextBox';
import styles from './styles.module.scss';

interface TextAreaProps extends TextBoxProps {}

export const TextArea: React.FC<Partial<TextAreaProps>> = ({
  inputProps: { className, ...otherInputProps } = {},
  ...other
}) => (
  <TextBox
    {...other}
    component="textarea"
    inputProps={{
      ...otherInputProps,
      className: cx(className, styles.field_textarea)
    }}
    type={null}
  />
);

export default TextArea;
